import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../Payments/payments.css";
import { GrSearch } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import ProductModel from "./ProductModel";
import { getCategory, GetProducts, useGetAllProducts } from "src/api/POS";
import { useSocket } from "src/context/SocketProvider";
import { addThousandSeparator } from "src/constants/SportSEparator";
import { Permission } from "src/api/CalendarBooking";

export interface category{
  category_name:string,
  id:number,
  sequence:number
}

export interface product  {
  "product_name": string,
  "category_id": number,
  "price": number,
  "status": string,
  "product_img": string
  "id":number,
  "club_id":number,
  "category":category
}


const Products = ({isAdd,setIsAdd}:{isAdd:boolean,setIsAdd:any}) => {
  const [showPR, setShowPR] = useState(false);
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [selData,setSelData] = useState<product|string>();
  const [allCategory,setAllCategory] = useState<category[]>([]);
  const {data,isSuccess,isError,refetch} = useGetAllProducts();
  const [searchValue,setSearchValue] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    getAllCategory();
  }, []);

  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const getAllCategory = async()=>{
    const response = await getCategory();
    setAllCategory(response);
  }

  const { t } = useTranslation();

  const handleClose =()=>{
    setSelData("");
    setIsAdd(false);
    setShowPR(false)
  }

  const handleClickPr =(data:product)=>{
    setSelData(data);
    setShowPR(true)
  }

  const handleClickMobile = (data:product)=>{
    if(isMobile){
      setSelData(data);
      setShowPR(true)
    }
  }

  useEffect(() => {
  if(isSuccess){
    setAllProducts(data)
  }else{
    setAllProducts([])
  }
  }, [isError,isSuccess,data])


    // function to convert it into excel format
    const exportToCSV = (data: any) => {
      let csvContent =
        "productImage,productName,Category,Price,Active/InActive\n";
  
      data.forEach((booking: any) => {
        const { product_name, price, status, product_img,category } = booking;
          const row = `${product_img},${product_name},${category?.category_name},${price},${status}\n`;
  
          csvContent += row;
      });

      const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const csvURL = URL.createObjectURL(csvBlob);
  
      const link = document.createElement("a");
      link.href = csvURL;
      link.download = "Products.csv";
      link.click();
    }
  
  
    // export to csv
    const handleExportButtonClick = () => {
      exportToCSV(allProducts);
    };
  

  return (
    <Box sx={{ marginTop: 3 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            lg: "row",
          },
          justifyContent: { xs: "none", lg: "space-between" },
          alignItems: { xs: "flex-start", lg: "center" },
          gap: { xs: 1, lg: "10px" },
        }}
      >
        <Paper
          component="form"
          sx={{
            background: "transparent !important",
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1) !important",
            borderRadius: "7px !important",
            width: "200px",
            height: "30px",
          }}
        >
          <InputBase
            name="search"
            onChange={(e: any) => setSearchValue( e.target.value)}
            value={searchValue}
            sx={{
              ml: 1,
              flex: 1,
              color: "rgba(34, 53, 109, 0.90)",
              fontFamily: "var(--font-regular)",
            }}
            placeholder="Search product"
            inputProps={{ "aria-label": "search" }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <GrSearch style={{ fontSize: "15px" }} />
          </IconButton>
        </Paper>
        <Button
          className="mobile-res"
          style={{
            width: "150px",
            color: "#22356D",
            height: "32px",
            fontFamily: "var(--font-regular)",
            borderRadius: "7px",
            padding: "0px",
            border: "#C6CBDA 1px solid",
            opacity: "0.7",
            background: "white",
            textTransform: "capitalize",
          }}
            onClick={handleExportButtonClick}
        >
          {t("common.Export To CSV")}
        </Button>
      </Box>

      <Box
        sx={{
          background: "white",
          borderRadius: "7px",
          marginTop: 2,
        }}
      >
        <table className="customersettingstable" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "10%", padding: "7px" }} className={""}>
                {t("POS.Product Picture")}
              </th>
              <th className="">{t("POS.Product Name")}</th>
              <th className="show-row">{t("POS.Category")}</th>
              <th className={"show-row"}>{t("common.Price")}</th>
              <th className="show-row">{t("common.Details")}</th>
              <th className="show-row">
                {t("common.Active")}/{t("common.Inactive")}
              </th>
            </tr>
          </thead>
          <tbody>
            {allProducts?.length>0 ? allProducts?.map((item: any) => (
              <tr>
                <td onClick={()=>handleClickMobile(item)} className="">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      margin: "10px 0",
                    }}
                  >
                    <img
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
                        borderRadius: "7px",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      src={item.product_img}
                    />
                  </div>
                </td>
                <td className="">{item.product_name}</td>
                <td className="show-row">{item.category?.category_name}</td>
                <td className="show-row">{addThousandSeparator(item.price,'IDR')}</td>
                <td className="show-row">
                  <Typography
                    onClick={()=>handleClickPr(item)}
                    sx={{
                      margin: "auto",
                      cursor: "pointer",
                      width: "70px",
                      borderRadius: "7px",
                      padding: "2px",
                      border: "1px solid #EEEFEE",
                      opacity: "0.9",
                      background: "var(--Main-Colors-White, #FFF)",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.07)",
                      fontFamily: "var(--font-regular)",
                      fontSize: "14px",
                      "&:hover": {
                        background: "#22356D !important",
                        color: "white !important",
                      },
                    }}
                  >
                    {t("common.Details")}
                  </Typography>
                </td>
                <td className="show-row">
                  {" "}
                  <label className="switch">
                    <input
                      checked={item.status==="Active"?true:false}
                      readOnly
                      //   onChange={handleCheckboxChange}
                      type="checkbox"
                    />
                    <span className="slider round"></span>
                  </label>
                </td>
              </tr>
            )):<tr>
            <td className="show-row"></td>
            <td className="show-row"></td>
            <td >{t('POS.No Products Found')}</td>
            <td className="show-row"></td>
            <td className="show-row"></td>
            <td></td></tr>}
          </tbody>
        </table>
      </Box>

      <Dialog
        sx={{
          ".MuiDialog-paper": {
            minWidth: {xs:'auto',md:"500px"},
          },
        }}
        onClose={() => {
          setShowPR(false);
          setIsAdd(false);
          setSelData("");
        }}
        open={showPR||isAdd}
      >
        <DialogContent>
          <ProductModel allCategory={allCategory} updateCat={getAllCategory} update={refetch} handleClose={handleClose} Data={selData} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Products;
