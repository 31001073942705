import { Box, Dialog, DialogContent, Stack, Typography } from '@mui/material'
import React, { useState,useEffect, useContext } from 'react'
import { activeColor } from 'src/constants/color';
import OTPInput from 'react-otp-input';
import { updateScore, useGetScore } from 'src/api/CalendarBooking';
import MainContext from 'src/context/MainContext';
import { useTranslation } from 'react-i18next';
import { GiPlainCircle } from 'react-icons/gi';
import CustomerInfo from './CustomerInfo';


const style = {
  fontFamily: "var(--font-semibold)",
  background: "white",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 20px",
  borderRadius: "7px",
};


const drawscore = {
  teamA: {
    score1: 1,
    score2: 1,
    score3: 1
  },
  teamB: {
    score1: 1,
    score2: 1,
    score3: 1
  }
}

function transformScores(data:any) {
  const transformTeam = (team:any) => ({
    1: team.score1,
    2: team.score2,
    3: team.score3
  });

  return {
    teamA: transformTeam(data.teamA),
    teamB: transformTeam(data.teamB)
  };
}



const ScoreEdit = ({index,user,setValue,scoredata,isDraw,setIsdraw,reset,ScoreData}:{ScoreData:any,reset:any,user:any,index:number,setValue:any,scoredata:any,isDraw:boolean,setIsdraw:any}) => {
  const {t} = useTranslation();
  const [cusShow,setCusShow] = useState<boolean>(false);
  const [isWarning,setIsWarning] =useState<boolean>(false);
  const [selectedCustomer,setSelectedCustomer] = useState();
  const [otp,setOtp] = useState<string>('');
  const {teamA,teamB} = scoredata;
  const {event,setNotification} = useContext(MainContext)
  const isValidA = teamA?.score1!==undefined ;
  const isValidB = teamB?.score1!==undefined ; 
  let isAWinner=0;
  let isBWinner=0;
  const AScore1 = teamA?.score1 > teamB?.score1 ? isAWinner++:teamA?.score1 < teamB?.score1 ? isBWinner++:'';
  const AScore2 = teamA?.score2 > teamB?.score2 ? isAWinner++:teamA?.score2 < teamB?.score2 ? isBWinner++:'';
  const AScore3 = teamA?.score3 > teamB?.score3 ? isAWinner++:teamA?.score3 < teamB?.score3 ? isBWinner++:'';
  const team = index===0?'teamA':'teamB';
  const isSubmit = isValidA && isValidB;
  const isWinner = isAWinner>isBWinner?'teamA':isAWinner<isBWinner?'teamB':'Draw';
  const {getScoreSuccess,getScoreData,getScoreError,getScoreRefetch} = ScoreData;
  const LevelData = getScoreData?.ServiceDetails?.players||[];

  useEffect(() => {
    const splll = otp.split('');

    if(splll.length==2) splll.push('');
    if(splll.length==1) splll.push('','')
       splll.map((item:any,index:number)=>setValue(`${team}.score${index+1}`,Number(item)||0))
    }, [otp,isDraw])
    

    useEffect(() => {
      if(isDraw){
        reset(drawscore)
      }
     }, [isDraw])
     

    useEffect(() => {
      if(getScoreSuccess){
        getScoreData.Scores.forEach((score:any) => {
          if (score.team === 'A' && index===0) {
            setOtp(`${score.score1}${score.score2}${score.score3}`);
          } else if (score.team === 'B'&& index===1) {
            setOtp(`${score.score1}${score.score2}${score.score3}`);
          }
        });
  
      }
     }, [getScoreSuccess,getScoreData,getScoreError])
         

    const HandleSubmitScore = async(e:any)=>{
      e.preventDefault()
      const apiData = transformScores({teamA,teamB})
      try {
        const response = await updateScore(event?.service_id,apiData);
        const resMessage = response.data.message;
        setIsWarning(false);
        setNotification({message:resMessage,isShow:true,subMessage:'',type:'success'})
        getScoreRefetch()
      } catch (error:any) {
        setNotification({message:error,isShow:true,subMessage:'',type:'error'})
      }
    }
  const SPORT_NAME = event?.courts[0].sport?.sport_name
    const GEtLevelOfPlayer = (id:number)=>{
         const Level = LevelData?.filter((item:any)=>item.id===id)[0]?.customer?.sportsLevel;
         const FilteredLevel = Level?.filter((item:any)=>item?.sport_name===SPORT_NAME)[0]?.level ||'0.0';
         return FilteredLevel;
    }

    const handleCustomerClick = (data:any)=>{
          setSelectedCustomer(data);
          setCusShow(true);
    }
    
  return (
  <Box sx={{position:'relative'}}>
    {
      index===0&& <Typography onClick={()=>setIsdraw(!isDraw)} sx={{position:'absolute',top:'8px',right:'10px',padding:'4px 15px',background:isDraw ?activeColor:'white',border:'1px solid #939393',borderRadius:'7px',fontFamily:"var(--font-regular)",color:isDraw ?'white':activeColor,fontSize:'14px',cursor:'pointer',}}>Draw</Typography>
    }
    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',"&:hover":{background:"#889AB64D !important"},padding:'5px'}}>
      <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:2}}>
      <Box sx={{ borderRadius:"7px", width:"26px", height:"26px", textAlign:"center", padding: '0px 10px', boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)', fontSize:{xs:"0.8rem", sm:"1rem"},color:activeColor }}>{index + 1}</Box>
      <Box sx={{display:'flex',flexDirection:'column'}}>
    {
      user.customer!==null && <Box key={user.id} padding={'8px 10px'}>
          <Box width={"100%"} color={activeColor}>
              <Box display={"flex"} alignItems={"center"} gap={1} sx={{">img":{display:{xs:"none", sm:"block"},">p:nth-child(1)":{display:{xs:"none", sm:"block"}}}}}>
                <img onClick={()=>handleCustomerClick(user.customer)} style={{borderRadius:'50%'}} src={user.customer?.profile_url?user?.customer?.profile_url:"/images/Reserved.svg"} alt="user" width='35px' height='35px' />
                <Typography sx={{fontFamily:"var(--font-regular)", fontSize:{xs:"0.8rem", sm:"1rem"}}}>{user?.customer?.first_name} {user?.customer?.last_name}</Typography>
                <GiPlainCircle style={{fontSize:'4px',opacity:'0.6'}} />
                <Typography sx={{fontFamily:"var(--font-regular)", fontSize:'14px',opacity:'0.7'}}>Level {GEtLevelOfPlayer(user.id)}</Typography>
            </Box>
           
          </Box>
      </Box>
    }
    {
      user.pairedData?.length!==0 && <Box key={user.pairedData[0].id} padding={'8px 10px'}>
          <Box width={"100%"} color={activeColor}>
              <Box display={"flex"} alignItems={"center"} gap={1} sx={{">img":{display:{xs:"none", sm:"block"},">p:nth-child(1)":{display:{xs:"none", sm:"block"}}}}}>
                <img onClick={()=>handleCustomerClick(user.pairedData.customer)} style={{borderRadius:'50%'}} src={user.pairedData[0].customer?.profile_url?user.pairedData[0]?.customer?.profile_url:"/images/Reserved.svg"} alt="user" width='35px' height='35px' />
                <Typography sx={{fontFamily:"var(--font-regular)", fontSize:{xs:"0.8rem", sm:"1rem"}}}>{user.pairedData[0]?.customer?.first_name} {user.pairedData[0]?.customer?.last_name}</Typography>
                <GiPlainCircle style={{fontSize:'4px',opacity:'0.6'}} />
                <Typography sx={{fontFamily:"var(--font-regular)", fontSize:'14px',opacity:'0.7'}}>Level {GEtLevelOfPlayer(user.id)}</Typography>
            </Box>
           
          </Box>
      </Box>
    }
    </Box>
    </Box>
     <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} paddingRight={1} sx={{flexDirection:{xs:"column", sm:"row"}, width:{xs:"106px", sm:"196px"}}}>
              <Box display={"flex"} alignItems={"center"} gap={2}>
{isDraw ?<Typography sx={{fontFamily:'var(--font-medium)'}}>Draw</Typography>:<Box sx={{background:isWinner===team?'#EAF956':'transparent',borderRadius:'15px',padding:'5px 12px'}}>
{isWinner===team && isSubmit && <Typography fontFamily={"var(--font-medium)"} sx={{fontSize:{xs:"0.8rem", sm:"1rem",textAlign:'center'}}}>Winners</Typography>}
  <OTPInput inputType='number' inputStyle={{ borderBottom:'1px solid #939393',width:'20px',
    height:'20px',borderRadius:'0px',padding:'0px',background:isWinner===team?'#EAF956':'transparent'}} value={otp}
                onChange={setOtp}
                numInputs={3}
                renderSeparator={<span style={{margin:'5px'}}>{' '}</span>}
                renderInput={(props) => <input {...props} /> }
              /></Box>}
              </Box>
            </Box>
            </Box>
    {index===1&&
      <Box  sx={{display:'flex',flexDirection:'row',justifyContent:'flex-end',marginBottom:'10px',}}>
        <Typography onClick={()=>setIsWarning(true)} sx={{fontFamily:"var(--font-regular)",color:isSubmit ?'white':activeColor,border:'1px solid #939393',padding:'4px 15px',borderRadius:'7px',marginRight:'10px',fontSize:'14px',cursor:'pointer',background:isSubmit ?activeColor:'white',pointerEvents:isSubmit?'auto':'none'}}>Submit Score</Typography>
      </Box>
    }

<Dialog onClose={() => setIsWarning(false)} open={isWarning}>
            <DialogContent>
              {/* ---- for delete user ---- */}
                <Box padding={2}>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-medium)",
                        color: "#22356D",
                        marginRight: "5rem",
                      }}
                    >
                     {t('common.Are you sure you want to submit score?')}
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      marginBottom={4}
                      marginRight={"5rem"}
                    >
                      <img src="/images/info.svg" alt="info" />
                      <Typography
                        sx={{
                          color: "#22356DB2",
                          fontFamily: "var(--font-regular)",
                        }}
                      >
                        {t('common.This will affect players ranking levels.')}
                      </Typography>
                    </Stack>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      marginTop={2}
                    >
                      <button
                        style={{ ...style, color: activeColor }}
                        onClick={() => setIsWarning(false)}
                      >
                        {t('common.Cancel')}
                      </button>
                      <button
                        onClick={HandleSubmitScore}
                        style={{
                          ...style,
                          color: "white",
                          background: activeColor,
                        }}
                      >
                         {t('common.Save')}
                      </button>
                    </Box>
                  </Box>
                </Box>
            </DialogContent>
          </Dialog>

          <CustomerInfo hidebackdrop={false} GetAllCustomer={(data:any)=>console.log(data)} customerdata={selectedCustomer} customfield={[]}  fromCustomer={false}
        show={cusShow} setShow={setCusShow}
        />
  </Box>
  )
}

export default ScoreEdit