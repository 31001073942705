import { useGetAllServices, useGetAllStats } from "src/api/CalendarBooking";
import React, { useEffect, useState } from "react";
import MainContext from "src/context/MainContext";
import CourtCalendar from "./CourtCalendar";
import BookingModel from "./BookingModel";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "./BookedRecurring.css";
import moment from "moment";
import { useSocket } from "src/context/SocketProvider";
import { QueryClient, useQueryClient } from "react-query";

const DailyTimetable = ({
  view,
  columnsArray,
  ActiveTab,
  tabLocation,
  fixLocation,
  courtList,
  setSelectView,
  selectedSport,
  allSports
}: {
  setSelectView: any;
  view: string;
  columnsArray: any;
  ActiveTab: any;
  tabLocation: any;
  fixLocation: any;
  courtList: any;
  selectedSport: string;
  allSports: any
}) => {
  const context = React.useContext(MainContext);
  const {
    data,
    isSuccess,
    isLoading,
    isError,
    refetch,
    setDate,
    setLocation_id,
  } = useGetAllServices();
  const {
    data: stats,
    isSuccess: success,
    isLoading: loading,
    isError: error,
    refetch: refe,
    setDate: getDate,
    setLocation_id: get_id,
    setSports:setSportsID
  } = useGetAllStats();
  const sport_id = context?.sport_id;
  const selectedDate = moment(context?.selectedDate).format("YYYY-MM-DD");
  const queryClient = useQueryClient();
  const setRatingDetails = context?.setRatingDetails;
  const [selectedIndexRange, setSelectedIndexRange] = useState(0);
  const [openBookingModel, setOpen] = React.useState(false);
  const [clickCourt, setClickCourt] = React.useState([]);
  const [clickStart, setClickStart] = React.useState("08:00");
  const [clickEnd, setClickEnd] = React.useState("08:30");
  const [clickEvent, setClickEvent] = React.useState({});
  const [allEvent, setAllEvent] = React.useState<any>([]);
  const {messages, sendMessage, connectionStatus,setUrl, listenToEvent  } = useSocket();
  const [update, setUpdates] = React.useState(false);
  const locationId =
    tabLocation.find(
      (location: any) => location.location_name === fixLocation
    ) || {};

    // obj?.end_time ==="24:00:00"?moment( `${dateStr} "23:59:00"`,"YYYY-MM-DD HH:mm:ss").toDate()

  useEffect(() => {
    if (isSuccess) {
      const dateStr = moment(selectedDate).format("YYYY-MM-DD");
      const myArray = data?.serviceBookings;

      const newArray = myArray?.flatMap((obj: any) => {
        return obj?.courts?.map((item: any) => ({
          ...obj,
          court_id: item?.id,
          service_id: obj?.id,
          court_name: item?.court_name,
          start_time: moment(
            `${dateStr} ${obj?.start_time}`,
            "YYYY-MM-DD HH:mm:ss"
          ).toDate(),
          end_time: obj?.end_time ==="24:00:00" || obj?.end_time ==="00:00:00"? moment( `${dateStr} "23:59:00"`,"YYYY-MM-DD HH:mm:ss").toDate() :moment(
            `${dateStr} ${obj?.end_time}`,
            "YYYY-MM-DD HH:mm:ss"
          ).toDate(),
        }));
      });
      const filteredData = newArray?.filter((item:any)=>!item?.is_hide);
      setAllEvent(filteredData);
    } else {
      setAllEvent([]);
    }
  }, [data, isSuccess, isError]);

  useEffect(() => {
    if (success) {
      setRatingDetails(stats);
    } 
  }, [stats, success, error]);

  const handleUpdate = () => {
    setUpdates(false);
  };

  useEffect(() => {
      const handleEventOne = (data: any) => {
        // console.log('Calendar Daiy:', data);
        queryClient.invalidateQueries(['calendar-daily-date']);
        queryClient.invalidateQueries(['calendar-daily-stats']);
        // Handle event one data
      };
  
      listenToEvent('calendars', handleEventOne);

      // Cleanup
      return () => {
        // Unregister event listeners if necessary
      };
    }, [listenToEvent]);
  
  useEffect(() => {
    if (Object.keys(locationId).length > 0) {
      setDate(selectedDate);
      setLocation_id(locationId?.id);
      getDate(selectedDate);
      get_id(locationId?.id);
      setSportsID(sport_id);
    }
  }, [clickStart, clickEnd, fixLocation, columnsArray, selectedDate,sport_id]);


  useEffect(() => {
    setSelectedIndexRange(0);
  }, [ActiveTab, clickStart, clickEnd, clickCourt, allEvent]);

  return (
    <Box position={"relative"}>
      {/* pop-up-model */}
      {openBookingModel && (
        <BookingModel
          fromWh={""}
          events={clickEvent}
          update={update}
          serviceList={() => {
            refetch();
            refe();
          }}
          columnsArray={fixLocation}
          openBookingModel={openBookingModel}
          setOpen={setOpen}
          clickStart={clickStart}
          clickEnd={clickEnd}
          clickCourt={clickCourt}
          courtList={courtList}
          view={"Courts"}
        />
      )}

      <Grid container spacing={1} alignItems={"stretch"}>
        {/* ---------- court calendar --------- */}
        <CourtCalendar
          EventLoading={isLoading}
          setSelectView={setSelectView}
          event={allEvent}
          handleUpdate={handleUpdate}
          setClickEvent={setClickEvent}
          setOpen={setOpen}
          setClickCourt={setClickCourt}
          setClickStart={setClickStart}
          setClickEnd={setClickEnd}
          courtList={courtList}
        />
      </Grid>
    </Box>
  );
};

export default DailyTimetable;
