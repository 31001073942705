import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsChevronDown } from "react-icons/bs";
import { activeColor } from "src/constants/color";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import MyDropdown from "../../components/Payment/LocationSelectCheckBox";
import {
  CreateNewcustomer,
  DeleteCustomerByID,
  GetCustomerLevel,
  GetCustomerToken,
  updateColumnSequence,
  updateCustomCustomer,
  updateCustomerById,
  updateCustomerSettingById,
  useGetCustomSettings,
} from "src/api/Customer";
import { useGetAllCustomersTypes } from "src/hooks/useGetAllCustomersTypes";
import MainContext from "src/context/MainContext";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { IoMenu } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { SingleDate } from "../../components/customTimeSeletor";
import moment from "moment";
import { Button } from "@mui/material";
import LevelModal from "./LevelModal";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";
import StaffModal from "src/Notification/StaffModal";
import { UseGetAllSportByClub } from "src/api/CourtSettings";


const hasSequenceChanged = (original: any[], updated: any[]): boolean => {
  if (original.length !== updated.length) return true;

  return !original.every((origItem, index) =>{
    return updated?.some((item:any)=>item?.id===origItem?._id && item?.sequence===origItem?.sequence )
  });
};

const commonStyle = {
  display: "flex",
  justifyContent: "space-between",
};

const style = {
  fontFamily: "var(--font-semibold)",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 30px",
  borderRadius: "7px",
};

interface sport{
  sport_name:string,
  id:number,
  create_level:boolean
}

const UserSetting = ({
  custField,
  updateCustomSettings,
  fromCustomer,
  setError,
  GetAllCustomer,
  watch,
  setShow,
  userdata,
  handleClose,
  getCustomerbyid,
  UserProfile,
  setFormvalue,
  columnRefetch
}: {
  custField?: any;
  setError?: any;
  userdata?: any;
  handleClose?: any;
  getCustomerbyid?: any;
  setShow?: any;
  watch?: any;
  GetAllCustomer?: any;
  fromCustomer?:any;
  updateCustomSettings?:any
  UserProfile:any,
  setFormvalue:any,
  columnRefetch:any
}) => {
  const fontD = {
    fontFamily: "var(--font-regular) !important",
    color: "#22356DB2",
  };
  const { t }= useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const {Notification,setNotification,isPermissionOn,setIsPermissionOn} = useContext(MainContext);
   const StaffPermission = useGetPermissions();
   const [selectedSport,setSelectedSport] = useState<any>();
  const [CustomField,setCustomField] =useState<any[]>(custField);
  const [ShowToken,setShowToken] = useState(false);
  const { register, handleSubmit, setValue, watch: settingWatch,reset } = useForm();
  const [customSettings,setCustomSettings] = useState<any[]>([]);
  const [LevelHistory,setLevelHistory] = useState<any[]>([]);
  const [AllLevel,setAllLevel] = useState<any[]>([]);
  const [dropdownOpen,SetDropdownOpen] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [deletePl, setDeletePl] = useState(false);
  const { first_name, last_name, email, phone_number ,profile_url,customerTypes} = watch();
  const [settingerror, setsettingError] = useState("");
  const {data, isSuccess, isError, setUser_Id, refetch} = useGetCustomSettings();
  const [token,setToken] = useState<string>('');
  const {isSuccess:sportSuccess,data:allSport,isError:sportError} = UseGetAllSportByClub();
  const [AllSports,setAllSports] = useState<sport[]>([]);
  const [isSameEmail,setIsSameEmail] =useState<boolean>(false);

  useEffect(() => {
  if(isSuccess){
    const filteredData = filterObjectByColumnName(data, custField);  
    setCustomSettings(filteredData);
  }else{
    setCustomSettings([])
  }
  }, [data,isSuccess,isError]);

useEffect(() => {
  if(sportSuccess){
    setAllSports(allSport);
  }else{
    setAllSports([]);
  }
}, [sportSuccess,allSport,sportError])
  

useEffect(() => {
  if(LevelHistory?.length>0){
    const mergedData = AllSports.map((staticItem:any) => {
      const dynamicItem = LevelHistory.find((d:any) => d.sport_name === staticItem.sport_name);
      if (dynamicItem) {
          return { ...staticItem, level: dynamicItem.level };
      }
      return { ...staticItem };
  });
  setAllLevel(mergedData);
  }else{
    setAllLevel(AllSports);
  }

  
}, [LevelHistory,AllSports]);

useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  window.addEventListener("resize", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);


  // handlecustomer
  const customerData = async (data: any) => {
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      return;
    }

    const isValidNumber = /^[0-9]*$/.test(phone_number);
    if (!first_name) {
      setError("first_name");
      setTimeout(() => setError(""), 2000);
    }else if(!isValidNumber){
      setError('phone_number');
      setTimeout(() => setError(""), 2000);
    } else {
      setError("");
      setsettingError("");
      if (userdata === "") {
        createCustomer();
      } else {
        const customer_types = customerTypes.map((item: any) => item.id);
        const profile_visible = true;
        const userId = userdata?.id;
    
        const filteredApiData = filterObjectByColumnName(settingWatch(), custField);
        const customApiData = filteredApiData 
            ? { ...filteredApiData, userId } 
            : { ...settingWatch(), userId };
    
        const apidata = { password, customer_types, profile_visible };
    
        const customPerData = CustomField?.map((item: any, index: number) => {
            const { _id } = item;
            return { id: _id, sequence: index + 1 };
        });

        const promises = [
          updateCustomerSettingById(userdata.id, apidata),
          
      ];

      if(Object.keys(customApiData)?.length>1){
        promises.push(updateCustomCustomer(customApiData));
    }
      
      if (hasSequenceChanged(custField, customPerData)) {
          promises.push(updateColumnSequence({ columns: customPerData }));
      }
      
        try {
          // Await all promises in parallel
          const [userSettings, customSettingsRes,customdmmfadatata] = await Promise.all(promises);
            
          const formData = new FormData();
          formData.append('first_name', first_name);
          formData.append('last_name', last_name);
          formData.append('phone_number', phone_number.replace("+", ""));
          formData.append('email', email);
          if (UserProfile) {
              formData.append('image', UserProfile);
          }
       
          const updateresponse = await updateCustomerById(userdata.id, formData);
      
          if (custField?.length ? customSettingsRes && updateresponse && userSettings : updateresponse && userSettings) {
              if (fromCustomer) {
                  GetAllCustomer();
                  updateCustomSettings();
              }
              setShow(false);
              refetch();
              handleClose();
          }
          columnRefetch();
      } catch (error) {
        if(error==="club_id must be unique"){
          setIsSameEmail(true);
        }
          console.error('Error updating customer settings:', error);
          // Handle specific errors if needed, e.g., show a notification to the user
      }
      }
    }
  };



  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    label: string
  ) => {
    setValue(label, e.target.value );
  };

  useEffect(() => {
    const result :any= {};

    CustomField.forEach((item:any) => {
    if (item.columnType === 'selectbox') {
      result[item.columnName] = [];
    } else {
      result[item.columnName] = "";
    }
  });

    reset(result);
    if (!!userdata) {
      setUser_Id(userdata?.id);
      getAllUserLEvel();
    }
  }, [userdata]);


  const getAllUserLEvel = async()=>{
    try {
      const response = await GetCustomerLevel(userdata?.id);
      setLevelHistory(response)
    } catch (error) {
      setLevelHistory([]);
      console.log(error,'Level Error')
    }
  }

  useEffect(() => {
    if(isSuccess){
      const {__v,_id,userId,...newdata }= data;
      reset({...newdata});
    }
}, [data,isSuccess,isError]);   


  const handleDelete = async () => {
   try {
    const response = await DeleteCustomerByID(userdata.id)
    if(response){
      setNotification({isShow:true,message:response,subMessage:'',type:'success'})
      GetAllCustomer();
      setShow(false);
    }
   } catch (error) {
    
   }
  };
 

  //create customer
  const createCustomer = async () => {

    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      return;
    }

    const formData = new FormData();
    const { email, first_name, phone_number, last_name } = watch();
    formData.append('last_name', last_name);
    formData.append('first_name', first_name);
    formData.append('phone_number', phone_number.replace("+", ""));
    formData.append('email', email);
    UserProfile && formData.append('image', UserProfile);
  
    // Create new customer
    let response;
    try {
      response = await CreateNewcustomer(formData);
    } catch (error:any) {
      setNotification({
        isShow: true,
        message: "Failed to create new customer",
        subMessage: error,
        type: 'error'
      });
      // Ensure modal is closed even if customer creation fails
      // setShow(false);
      // handleClose();
      // return;
    }
    
    // Update customer settings
    let responseofallcustomer;

    if(!!response){
      try {
        let customer_types = customerTypes && customerTypes.length > 0  ? customerTypes.map((item: any) => item.id) : [];
        let profile_visible = true;
        const apiData = { password, customer_types, profile_visible };
        if(!!response){
          responseofallcustomer = await updateCustomerSettingById(response.id, apiData);
        }
      
      } catch (error:any) {
        console.error('Error updating customer settings:', error);
        setNotification({
          isShow: true,
          message: "Failed to update customer settings",
          subMessage: error,
          type: 'error'
        });
        // Ensure modal is closed even if settings update fails
        return;
      }
    }
    // Update custom customer settings if applicable
    if(!!response && responseofallcustomer){
      try {
        let userId = response.id;
        const customApiData = { ...settingWatch(), userId };
    
        if (Object.keys(customApiData).length > 1 && !!response) {
          const customSettings = await updateCustomCustomer(customApiData);
          if (!customSettings) {
            throw new Error("Failed to update custom customer settings.");
          }
        }
  
      // Success notification and closing operations
      setNotification({
        isShow: true,
        message: "New Customer Created",
        subMessage: '',
        type: 'success'
      });
      setShow(false);
      GetAllCustomer();
      handleClose();
      getCustomerbyid();
      fromCustomer && updateCustomSettings();
    } catch (error:any) {
      console.error('Error updating custom customer settings:', error);
      setNotification({
        isShow: true,
        message: "Failed to update custom customer settings",
        subMessage: error,
        type: 'error'
      });
      // Ensure modal is closed even if custom settings update fail
    }
  }

    if(!!response && responseofallcustomer ){
      setNotification({
        isShow: true,
        message: "New Customer Created",
        subMessage: '',
        type: 'success'
      });
      setShow(false);
      GetAllCustomer();
      handleClose();
      getCustomerbyid();
      fromCustomer && updateCustomSettings();
    }
  };
  

  // useEffect(() => {
  //   setFormvalue('customerTypes',customerType)
  // }, [customerType])
  
  const hanldeClickckkck = ()=>{
   if(token){
    navigator.clipboard.writeText(token).then(() => {
      alert("Copied to clipboard");
    });
   }else{
    alert('some error have occured')
   }
  }
  
  
  // on drag updatedata
  const onDragEnd = (result:any) => {
    if (!result.destination) {
      return; // Dragged outside the list, do nothing
    }
    const { source, destination } = result;
    if (source.index === destination.index) {
      return; // Item dropped in the same position, do nothing
    }
    const updatedItems = Array.from(CustomField);
    const [removed] = updatedItems.splice(source.index, 1); // Remove the item from its original position
    updatedItems.splice(destination.index, 0, removed); // Insert the item into the new position
    setCustomField(updatedItems)
    // Update state or perform any necessary actions with the updatedItems array
  };

  const handleTokenClick = async()=>{
    setShowToken(true);
    try {
      const response = await GetCustomerToken(userdata?.id);
      setToken(response?.accessToken?.token);
    } catch (error) {
      setToken('');
    }
  }

  const handleLevelClick=(data:any)=>{
      SetDropdownOpen(true);
       setSelectedSport(data.sport_name)
  }

  return (
    <Box sx={{ fontFamily: "var(--font-regular)", color: "#22356D" }}>
      <form onSubmit={handleSubmit(customerData)}>
        <Box sx={{ ...commonStyle,alignItems:{xs:'baseline',sm:"center"}}}>
          <Typography sx={{fontFamily:"var(--font-regular)", color: "#22356D",fontSize:{xs:'14px',sm:'16px'}}}>{t('customers.New Password')}</Typography>
          <Box sx={{...commonStyle,gap:'10px',flexDirection:{xs:'column',sm:"row"},alignItems:'center'}}>
          <input
            value={password}
            type="password"
            placeholder={t('customers.Type new password')}
            onChange={(e:any)=>setPassword(e.target.value)}
            style={{
              height: "33px",
              width: "160px",
              border: "1px solid #EEEEFE",
              borderRadius: "7px",
            }}
          />
           <Button onClick={handleTokenClick} sx={{...style, color: activeColor,opacity:0.8,padding:'2px 8px',textTransform:'unset',fontFamily: "var(--font-regular)",border:'1px solid #DDDDDD'}}>{t('customers.Sign-in token')}</Button>
          </Box>
          
        </Box>
        <Box sx={{display:"flex",flexDirection:'column', marginTop: "15px",gap:'8px' }}>
          {
            AllLevel?.map((item:any)=>{
             return <Box sx={{display:'flex',flexDirection:'row',alignItems:{xs:'baseline',sm:"center"},justifyContent:'space-between',}}>
              <Typography sx={{fontFamily:"var(--font-regular)", color: "#22356D",fontSize:{xs:'14px',sm:'16px'}}}>{item?.sport_name} {t('common.Level')}</Typography>
             <Box sx={{...commonStyle,gap:'10px',flexDirection:{xs:'column',sm:"row"},alignItems:{xs:'baseline',sm:"center"}}}>
             <Typography
               style={{
                 width: "80px",
                 border: "1px solid #EEEEFE",
                 borderRadius: "7px",
                 background: "#889AB626",
                 textAlign:'center',
                 fontSize:'14px',
                 padding:'2px',
                 height:'29px'
               }}
             >{item?.level || '0'}</Typography> <Button onClick={()=>handleLevelClick(item)} sx={{...style,fontFamily: "var(--font-regular)", color: activeColor,opacity:0.8,padding:'2px 8px',textTransform:'unset',border:'1px solid #DDDDDD'}}>{t('common.Level Settings')}</Button>
          </Box>
          </Box>
            })
          }
         
          
        </Box>
        {/*Custom settings */}
        <Box
          marginBlock={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            marginTop: "30px",
          }}
        >
          <p style={{ fontFamily: "var(--font-medium)" }}>{t('customers.Custom Settings')}</p>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {CustomField.map((item:any, index:any) => (
                  <Draggable key={index} draggableId={index.toString()} index={index}>
                    {(provided) => (
                      <Box sx={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-between',alignItems:'center',marginBottom:'5px'}}  {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef} key={index}>
                                <Box sx={{display:'flex',flexDirection:'row',gap:'5px',alignItems:'center'}}>
                                  <Box sx={{display:{xs:'none',md:'block'}}}>
                              <IoMenu style={{ color: "#22356D", fontSize: "1.5rem" }} />
                              </Box>
                                <p>{item.columnName}</p>
                                </Box>
                                <Box>
                                {item.columnType === "string" && (
                                  <input
                                    type="text"
                                    value={settingWatch()[item.columnName]}
                                    onChange={(e:any)=>setValue(item.columnName,e.target.value)}
                                    // {...register(item.columnName,)}
                                    style={{
                                      height: "33px",
                                      width: "160px",
                                      border: "1px solid #EEEEFE",
                                      borderRadius: "7px",
                                      fontFamily: "var(--font-regular)",
                                    }}
                                    placeholder="Enter the value"
                                  />
                                )}
                                {item.columnType === "number" && (
                                  <input
                                    type="number"
                                    value={settingWatch()[item.columnName]}
                                    onChange={(e:any)=>setValue(item.columnName,e.target.value)}
                                    style={{
                                      height: "33px",
                                      width: "160px",
                                      border: "1px solid #EEEEFE",
                                      borderRadius: "7px",
                                      fontFamily: "var(--font-regular)",
                                    }}
                                    placeholder="Enter the value"
                                  />
                                )}
                                {item.columnType === "date" && (
                                  <SingleDate
                                  IcPosition={"left"}
                                  format={"DD - MM - yyyy"}
                                  type={"single"}
                                  containercss={{  height: "33px",
                                  width: isMobile?"120px":"160px",
                                  border: "1px solid #EEEEFE",
                                  borderRadius: "7px",
                                  fontFamily: "var(--font-regular)",padding:'5px'}}
                                  placeholder={"Select days"}
                                  selectedDay={settingWatch()[item.columnName]||"No"}
                                  setSelectedDays={(data: any) => {
                                    const okay = moment(data, "DD/MM/YYYY").toDate();
                                    setValue(item.columnName,okay);
                                  }}
                                  icon={false}
                                  style={{
                                    fontSize: "14px",
                                    color: "#22356D",
                                    background: "transparent",
                                    paddingLeft: "0px",
                                    minWidth: "10px",
                                    maxWidth: "180px",
                                  }}
                                />
                                )}
                                {item.columnType === "selectbox" && (
                                  <Box display={"flex"} gap={2} alignItems={"center"}>
                                    {item?.options?.map((option: string, idx: number) => (
                                      <label key={idx} className="container">
                                        <input
                                          type="checkbox"
                                          // checked={}
                                          value={option}
                                          // onChange={()=>setValue(item.columnName,option)}
                                          {...register(`${item.columnName}`)}
                                        />
                                        <span
                                          style={{
                                            position: "relative",
                                            top: "9px",
                                            fontSize: "1rem",
                                          }}
                                        >
                                          {option}
                                        </span>
                                        <span className="mark" style={{ left: "-9px" }}></span>
                                      </label>
                                    ))}
                                  </Box>
                                )}
                                {item.columnType === "radiobutton" && (
                                  <Box
                                    display={"flex"}
                                    gap={2}
                                    alignItems={"center"}
                                    height={"33px"}
                                  >
                                    {item.options.map((option: string, idx: number) => (
                                      <label key={idx} className="container">
                                        <input
                                          type="radio"
                                          value={option}
                                          {...register(item.columnName)}
                                        />
                                        <span
                                          style={{
                                            position: "relative",
                                            top: "19px",
                                            fontSize: "1rem",
                                          }}
                                        >
                                          {option}
                                        </span>
                                        <span
                                          className="checkmark"
                                          style={{
                                            left: "-12px",
                                            top: "-4px",
                                            borderRadius: "50% !important",
                                          }}
                                        ></span>
                                      </label>
                                    ))}
                                  </Box>
                                )}
                                {item.columnType === "dropdown" && (
                                  <Select
                                    MenuProps={{ sx: dopdowncustomcss }}
                                    // {...register(item.columnName)}
                                    value={settingWatch()[item.columnName] || ""}
                                    onChange={(e: any) => handleChange(e, item.columnName)}
                                    IconComponent={() => (
                                      <BsChevronDown
                                        style={{ fontSize: "20px", paddingTop: "2px" }}
                                      />
                                    )}
                                    sx={{
                                      width: "160px",
                                      minHeight: "3px !important",
                                      height: "33px !important",
                                      padding: "7px 14px 7px 0px !important",
                                      borderRadius: "7px",
                                      border: "1px solid #EEEEFE !important",
                                      fontFamily: "var(--font-regular) !important",
                                      color: "#22356D !important",
                                      ".MuiOutlinedInput-input": {
                                        paddingRight: "0px !important",
                                      },
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                    displayEmpty
                                  >
                                    <MenuItem value="" disabled>
                                      {t('common.Select')}
                                    </MenuItem>
                                    {item.options.map((option: string, idx: number) => (
                                      <MenuItem key={idx} value={option}>
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}
                                </Box>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
            </Droppable>
          </DragDropContext>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} marginTop={2}>
          <button
            onClick={() =>
              userdata !== "" ? setDeletePl(true) : setShow(false)
            }
            style={{ ...style, color: activeColor, zIndex: "12" }}
            type="button"
          >
            {userdata !== "" ? isMobile ?t("common.Delete"): t("customers.Delete Customer") : t("common.Cancel")}
          </button>
          <button
            style={{
              ...style,
              color: "white",
              background: activeColor,
              zIndex: "12",
            }}
            type="submit"
          >
            {userdata?.id ?t("common.Save"):t("customers.Add new customer")}
          </button>
        </Box>
      </form>
      <Dialog onClose={() => setIsSameEmail(false)} open={isSameEmail}>
        <DialogContent>
          <Box padding={2}>
              <Box>
                <Stack
                  direction={"row"}
                  spacing={1}
                  marginBottom={4}
                >
                  <img src="/images/info.svg" alt="info" />
                  <Typography
                    sx={{
                      color: "#22356DB2",
                      fontFamily: "var(--font-regular)",
                    }}
                  >
                    This email have already been used
                  </Typography>
                </Stack>
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  marginTop={2}
                >
                   <button
                    style={{ ...style, color: activeColor }}
                    onClick={() => setIsSameEmail(false)}
                  >
                    {t('common.Close')}
                  </button>
                </Box>
              </Box>
            </Box>
        </DialogContent>
      </Dialog>
      <Dialog onClose={() => setShowToken(false)} open={ShowToken}>
        <DialogContent>
          {/* ---- for updating user password ---- */}
          <Box padding={2}>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "var(--font-medium)",
                    color: "#22356D",
                  }}
                >
                  {t('customers.Generated Sign-in token')}
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={1}
                  marginBottom={4}
                >
                  <img src="/images/info.svg" alt="info" />
                  <Typography
                    sx={{
                      color: "#22356DB2",
                      fontFamily: "var(--font-regular)",
                    }}
                  >
                    {t('customers.This token alows a one time sig-in with the user email.')}{" "}
                  </Typography>
                </Stack>
                <Box sx={{display:'flex',alignItems:'center',flexDirection:'row',justifyContent:'space-around',marginBottom:'20px'}}>
                  <Typography sx={{ color: "#22356DB2",
                      fontFamily: "var(--font-regular)",}}>{token}</Typography>
                  <Button onClick={hanldeClickckkck} sx={{...style, color: activeColor,opacity:0.7,padding:'2px 8px',textTransform:'unset'}}>{(t('customers.Copy to clipboard'))}</Button>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  marginTop={2}
                >
                   <button
                    style={{ ...style, color: activeColor }}
                    onClick={() => setShowToken(false)}
                  >
                    {t('common.Close')}
                  </button>
                </Box>
              </Box>
            </Box>
        </DialogContent>
      </Dialog>
      <LevelModal sport_name={selectedSport} update={getAllUserLEvel} userData={userdata} open={dropdownOpen} setOpen={SetDropdownOpen}  />
      <Dialog onClose={() => setDeletePl(false)} open={deletePl}>
        <DialogContent>
          {/* ---- for delete user ---- */}
          {deletePl && (
            <Box padding={2}>
              {StaffPermission?.role!=="OTHER" ? <Box>
                <Typography sx={{fontFamily:"var(--font-medium)",color:"#22356D",marginBottom:'5px'}}>{t('common.You are not allowed to')} {t('common.delete this player')}</Typography>
                <Stack direction={'row'} spacing={1} marginBottom={4}>
                    <img src="/images/info.svg" alt="info" />
                    <Typography sx={{color:"#22356DB2",fontFamily:"var(--font-regular)",}}>{t('common.Only staff managers are allowed to')} {t('common.delete players.')}</Typography>
                </Stack>
                <button style={{ ...style, color: activeColor }}   onClick={() => setDeletePl(false)}>{t('common.Close')}</button>
            </Box>:
              <Box>
                <Typography
                  sx={{
                    fontFamily: "var(--font-medium)",
                    color: "#22356D",
                    marginRight: "5rem",
                  }}
                >
                  {t('customers.Are you sure you want to delete this player?')}
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={1}
                  marginBottom={4}
                  marginRight={"5rem"}
                >
                  <img src="/images/info.svg" alt="info" />
                  <Typography
                    sx={{
                      color: "#22356DB2",
                      fontFamily: "var(--font-regular)",
                    }}
                  >
                    {t('customers.This will erase all the player information.')}{" "} 
                  </Typography>
                </Stack>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  marginTop={2}
                >
                  <button
                    style={{ ...style, color: activeColor }}
                    onClick={() => setDeletePl(false)}
                  >
                    {t('customers.No_undo')}
                  </button>
                  <button
                    onClick={handleDelete}
                    style={{
                      ...style,
                      color: "white",
                      background: activeColor,
                    }}
                  >
                   {t('common.Yes')}, {t('common.Delete')}
                  </button>
                </Box>
              </Box>}
            </Box>
          )}
           {!StaffPermission.edit_permissions && <StaffModal />}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default UserSetting;


function filterObjectByColumnName(obj:any, arr:any) {
  const columnNames = arr?.map((item:any) => item.columnName);
  return Object?.keys(obj)
    .filter((key:any) => columnNames?.includes(key))
    .reduce((acc:any, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}
