import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BsChevronDown, BsChevronUp, BsPlus } from 'react-icons/bs';
import { insertClubAdmin, updateClubAdmin, updateClubData } from 'src/api/SuperAdmin';
import AdminPopUp from '../../components/Admin/AdminPopUp';
import "../../components/Admin/admin.css";
import { useTranslation } from 'react-i18next';

const common = { display:"flex", alignItems:"center" };

const SuperAdmin = ({ club, fetchData, selected, setSelected }:{ club:any, fetchData:any,selected:number, setSelected:any }) => {
    const [open, setOpen] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const { t, i18n } = useTranslation();
    const [clubAdmin, setClubAdmin] = useState({});
    const [show, setShow] = useState(false);
    const [text, setText] = useState("");

    const handleEdit = () =>{
      setClubAdmin({});
      setOpen(true);
      setShowInfo(false)
    }

    const handleClick = (data: any) => {
      setShow(false);
      if (data === 'addNew') {
        setText("Creating_user");
        setClubAdmin({});
      } else {
        setText("Updating_user");
        setClubAdmin(data);
      }
      setOpen(true);
      setShowInfo(true);
    }

    const handleEditClub = (id:number, data:any) => {
      updateClubData(id, data);
     if(selected===0){
      fetchData();
     }else{
      fetchData(data.club_name);
     }
      setOpen(false);
    }

    
    useEffect(()=>{},[setSelected])
 
    return (
        <Box sx={{background:"white", padding:'25px', borderRadius:"7px", fontFamily:"var(--font-regular)"}}>
          <Typography sx={{fontFamily:"var(--font-medium)", width:"max-content",borderBottom:"2px solid #22356D"}} onClick={()=>setOpen(true)}>{club.club_name}</Typography>
          <Box sx={{fontFamily:"var(--font-regular)", display:"flex", justifyContent:"space-between",marginBlock:"10px"}}>
            <Typography sx={{fontFamily:"var(--font-medium)",color:'#22356D'}}>Max Locations Allowed</Typography>
            <Typography sx={{fontFamily:"var(--font-medium)",color:'#22356D'}}>{club.maximum_allowed_location}</Typography>
          </Box> 
          <Box display={'flex'} justifyContent={'flex-end'}>
            <button style={{padding:'0px 20px',borderRadius:"7px",border:'1px solid #EEEEFE',boxShadow: "0px 4px 4px 0px #00000012",color:'#22356D'
            }} onClick={()=>handleEdit()}>Edit</button>
          </Box>
          <Typography sx={{fontFamily:"var(--font-medium)", width:"max-content",borderBottom:"2px solid #22356D"}}>Club Admin [{club.users?.length}]</Typography>
          <Box position={'relative'} width={"fit-content"}>
            <Box width={'184px'} marginTop={1.5} position={'relative'} onClick={() => setShow((prev)=>!prev)}>
              <input type="text" placeholder='1'style={{width:"100%", height:"37px", border:"1px solid #EEEEFE", borderRadius:"7px",fontFamily:"var(--font-regular)",color:'#22356D'}} value={t("common.Add new")} readOnly/>
              {!show ? <BsChevronDown style={{position:'absolute',top:"12.5px", right:'7.5px',fontSize:"0.8rem"}} /> :
              <BsChevronUp style={{position:'absolute',top:"12.5px", right:'7.5px',fontSize:"0.8rem"}} />}
            </Box>
            {show && 
            <Box position={'absolute'}  sx={{background:"white",color:"#22356D", cursor:"pointer",width:"-webkit-fill-available", border:"1px solid #EEEFEE", borderRadius:"7px"}}>
              <Box onClick={()=>handleClick("addNew")} sx={{background:"#889AB61A",display:"flex", gap:"5px", alignItems:"center", padding:"10px"}}><p>Create New</p> <BsPlus style={{fontSize:"1rem"}}/></Box>
              {club.users?.map((item:any,index:number)=>
              <Box sx={{background:"#889AB61A",padding:"10px", "&:hover":{background:"#22356D",color:"white",borderRadius:"7px"}}} key={index}><p>{item.full_name}</p></Box>
              )}
            </Box>}
          </Box>
          <Box>
            <Box id="table-person" sx={{height:"91px"}} className="background-dull" >
              {club.users?.map((item:any,index:number)=>
                <Box sx={{...common, fontFamily:"var(--font-regular)", color:"#22356D",padding:'8px 10px',height:'77px'}} justifyContent={'space-between'} key={item.email} onClick={() => handleClick(item)}>
                <Box sx={{...common}} gap={1.5}>
                  <Box sx={{padding:'2px 10px',borderRadius:'7px', background:"white"}}>{index+1}</Box>
                  <p>{item.full_name}</p>
                </Box>
                <p>{item.email}</p>
                </Box>
              )}
            </Box>
          </Box>
          <AdminPopUp fetchData={fetchData} open={open} setOpen={setOpen} clubAdmin={clubAdmin} data={club} showInfo={showInfo} setShowInfo={setShowInfo} handleClub={fetchData} text={text} setText={setText} />
        </Box>
  )
}

export default SuperAdmin;