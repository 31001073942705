import { apiCaller } from "./ApiCaller";
import { getClubId } from "./CalendarBooking";
import { useQuery } from "react-query";
import { useState } from "react";

export const GetAllCustomer = async () => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.get(`${club_id}/customers`);
      return response.data.data;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;    }
  };
  

  export const GetCustomerDetailsByID = async (customer_id:any) => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.get(`${club_id}/customers/settings/${customer_id}`);
      return response.data.data;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  interface playerSwap {
    player1Id:number;
    player2Id?:number;
    targetPositionId?:number;
  }

  export const UpdateCustomerPosition = async (service_id:number,apiData:playerSwap) => {
    let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(`${club_id}/calendars/swap-position/${service_id}`,apiData);
    return response.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};



  export const UpdateISCustomerBlocked = async (customer_id:any,ApiData:any) => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.patch(`${club_id}/customers/block/${customer_id}`,ApiData);
      return response;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  
  export const updateCustomerSettingById = async (customer_id:any,ApiData:any) => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.patch(`${club_id}/customers/settings/${customer_id}`,ApiData);
      return true;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };



  
  export const updateAllCustomerTypesList = async (customer_id:any,ApiData:any) => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.patch(`${club_id}/customers/customer-type/${customer_id}`,ApiData);
      return true;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  export const updateCustomCustomer = async (ApiData:any) => {
      let club_id = getClubId();
    try {
      const response: any = await apiCaller.put(`${club_id}/customer-fields/records`,ApiData);
      return true;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  export const GetCustomerToken = async (customer_id:any) => {
      let club_id = getClubId();
    try {
      const response :any= await apiCaller.post(`${club_id}/customers/accessToken/${customer_id}`);
      return response?.data
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  
  export const updateCustomerById = async (customer_id:any,Apidata:any) => {
      let club_id = getClubId();
    try {
      const response :any= await apiCaller.put(`${club_id}/customers/${customer_id}`,Apidata);
      return true;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  
  export const updateCustomerLevelByClub = async (Apidata:any) => {
      let club_id = getClubId();
    try {
      const response :any= await apiCaller.patch(`${club_id}/admins/level`,Apidata);
      return response;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  export const useGetLevelByClub = () => {
    let club_id = getClubId();
  return useQuery(
    ["GetLevelByClub"],
    async () => {
      const response :any= await apiCaller.get(`${club_id}/admins/club-level`);
     return response.data.data;
    },
    { staleTime: 1000 * 60 * 5, retry: false, enabled: true }
  );
};


  export const updateCustomerLevel = async (customer_id:any,Apidata:any) => {
      let club_id = getClubId();
    try {
      const response :any= await apiCaller.post(`${club_id}/customers/customer-level/${customer_id}`,Apidata);
      return response;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  
  export const GetCustomerLevel = async (customer_id:any) => {
      let club_id = getClubId();
    try {
      const response :any= await apiCaller.get(`${club_id}/customers/sports-level/${customer_id}`);
      return response?.data?.data;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  export const GetCustomerLevelBySport = async (customer_id:number,sport_name:string) => {
    let club_id = getClubId();
  try {
    const response :any= await apiCaller.get(`${club_id}/customers/customer-levels/${customer_id}?sport_name=${sport_name}`);
    return response?.data?.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};





  export const updateColumnSequence = async (Apidata:any) => {
      let club_id = getClubId();
    try {
      const response :any= await apiCaller.patch(`${club_id}/customer-fields/sequence`,Apidata);
      return true;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };



  
  export const GetBookingOfCustomer = async (customer_id:any) => {
      let club_id = getClubId();
    try {
      const response = await apiCaller.get(`${club_id}/customers/bookings/${customer_id}`,);
      return response.data.data;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  export const MakePaymentOfCustomer = async (apiData:any) => {
      let club_id = getClubId();
    try {
      const response = await apiCaller.post(`${club_id}/customers/pending-payments`,apiData);
      return response;
    } catch (error:any) {
      return error
    }
  };

  
  export const MakePaymentOfPendingPalyer = async (service_id:number,apiData:any) => {
      let club_id = getClubId();
    try {
      const response = await apiCaller.post(`${club_id}/payments/services/${service_id}`,apiData);
      return response?.data?.message;
    } catch (error:any) {
      throw error?.response?.data?.message
    }
  };



    //get custom settings all
    export const useGetStatsOfCustomer = () => {
        let club_id = getClubId();
      const [customer_id,setCustomer_id] =useState<number>();
      const query = useQuery(
        ["GetStatsOfCustomer",customer_id],
        async () => {
          const response :any= await apiCaller.get(`${club_id}/customers/stats/${customer_id}`);
         return response.data.data;
        },
        { staleTime: 1000 * 60 * 5, retry: false, enabled: !!customer_id }
      );
      return {setCustomer_id,...query}
    };

        //get ClubWallet settings all
        export const useGetClubWalletOfCustomer = () => {
            let club_id = getClubId();
          const [customer_id,setCustomer_id] =useState<number>();
          const query = useQuery(
            ["GetClubWalletOfCustomer",customer_id],
            async () => {
              const response :any= await apiCaller.get(`${club_id}/customers/wallets/${customer_id}`);
             return response.data.data;
            },
            { staleTime: 1000* 5, retry: false, enabled: !!customer_id }
          );
          return {setCustomer_id,...query}
        };
    

  export const DeleteCustomerByID = async (customer_id:any) => {
      let club_id = getClubId();
    try {
      const response :any= await apiCaller.delete(`${club_id}/customers/${customer_id}`);
      const data = response.data.message
      return data;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  
  export const CreateNewcustomer = async (Apidata:any) => {
      let club_id = getClubId();
    try {
      const response :any= await apiCaller.post(`${club_id}/customers`,Apidata);
      return response.data.data;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  export const EditCustomerWallet = async (customer_id:number,Apidata:any) => {
      let club_id = getClubId();
    try {
      const response :any= await apiCaller.patch(`${club_id}/customers/wallets/${customer_id}`,Apidata);
      return response.data.data;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };


   
  export const AddCustomerType = async (Apidata:any) => {
      let club_id = getClubId();
    try {
      const response :any= await apiCaller.post(`${club_id}/customers/customer-type`, Apidata);
      return response;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };
  

  export const AddCustomSetting = async (Apidata:any) => {
      let club_id = getClubId();
    try {
      const response :any= await apiCaller.post(`${club_id}/customer-fields`,Apidata);
      return response;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  
  export const UpdateCustomSetting = async (apidata:any) => {
      let club_id = getClubId();
    try {
      const response :any= await apiCaller.put(`${club_id}/customer-fields`,apidata);
      return response;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  
  export const DeleteCustomSetting = async (column_name:string) => {
      let club_id = getClubId();
    try {
      const response :any= await apiCaller.delete(`${club_id}/customer-fields/${column_name}`);
      return response;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  };

  //get custom settings by user
  export const useGetCustomSettings = () => {
      let club_id = getClubId();
    const [user_id,setUser_Id] = useState<number>();

    const query =  useQuery(
      ["getuserCustomSettings",user_id],
      async () => {
        const response :any= await apiCaller.get(`${club_id}/customer-fields/records/${user_id}`);
       return response.data.data;
      },
      { staleTime: 1000 * 60 * 5, retry: false, enabled: !!user_id }
    );
    return {setUser_Id,...query}
  };

  //get custom settings all
export const useGetAllCustomSettings = () => {
    let club_id = getClubId();
  return useQuery(
    ["AllCustomSettings"],
    async () => {
      const response :any= await apiCaller.get(`${club_id}/customer-fields`);
     return response.data.data;
    },
    { staleTime: 1000 * 60 * 5, retry: false, enabled: true }
  );
};

  //get custom settings all
  export const useGetAllCustomSettingsData = () => {
      let club_id = getClubId();
    return useQuery(
      ["GetAllCustomSettingsData"],
      async () => {
        const response :any= await apiCaller.get(`${club_id}/customer-fields/records`);
       return response.data;
      },
      { staleTime: 1000 * 60 * 5, retry: false, enabled: true }
    );
  };


  // add manual credits
  export const AddManualCredits = async(customer_id:number,apiData:any)=>{
   let club_id=getClubId();
    try {
      const response :any= await apiCaller.post(`${club_id}/customers/manual-credits/${customer_id}`,apiData);
      return response;
    } catch (error: any) {
      let err = error.response?.data?.message;
      throw err;
    }
  }