import Box from "@mui/material/Box";
import React, { useContext, useEffect, useState } from 'react';
import { formatMinutes } from 'src/constants/formateMinutes';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoAddSharp } from "react-icons/io5";
import MainContext from 'src/context/MainContext';
import { t } from "i18next";

const inputStyle = {
    border:'1.5px solid #EEEEFE',
    width:'100%',
    height:'37px',
    marginBottom:'7px',
    color:"#22356D"
}

interface Service {
    id: number,
    duration:number,
    maximum_capacity: number,
    minimum_capacity: number,
    price: number,
    is_open_match: boolean,
    open_match_price: number,
}

const EventSelector = ({ customService,customEvent,view, setCustomEvent,selectedOpenRegular, customPrice, addService, addPrice, text, value, data, handleEvent,selectedCourts,selectedCoaches }: { selectedOpenRegular?:string, customService?:any, customPrice?: any,addService?:any, addPrice?:any, text:string, value:any, data:any, handleEvent: any,selectedCourts:any,customEvent:any,setCustomEvent:any,view:string,selectedCoaches:any }) => {
    const [show, setShow] = useState(false);
  
    const [list, setList] = useState<any>([]);
    const [filteredList,setFileteredList] =useState<any[]>([]);
    const [inputValue,setInputValue] = useState<string>('');
    const context = useContext(MainContext);
    const event = context?.event;    
    const selectLocation = context?.SelectedLocation;

   const isMorethanOne = Object.keys(event)?.length>0;
    const handleClick = (event: any) =>{
        if(isMorethanOne){
        }else{
            event.stopPropagation();
            setShow((prev)=>!prev);
        }
    }


    const selectEvent = (event:any, text:string, data: any) => {
        event.stopPropagation();
        setInputValue('');
        if(text==="Event"){
            handleEvent(data)
        }else if(text==="Lesson"){
            handleEvent(data);
        }else{
            handleEvent(data);
        }
        setShow(false);
    }

    useEffect(() => {
        if(inputValue){
              const filteredData = list?.filter((ser:any)=>ser?.Service_name?.toLowerCase()?.includes(inputValue?.toLowerCase())); 
              setFileteredList(filteredData);
        }else{
            setFileteredList(list);
        }
     
    }, [inputValue,list])
    
    
    // const updateDAta = eventList?.filter((item:any)=>{
    //     return selectedCoach?.some((sel:any)=>{
    //       return item.caochesteachings?.some((coach:any)=>coach?.coach_id===sel)
    //      })
    //    })

    useEffect(()=>{
        if(view==="Courts" && selectedCoaches?.length===0){
            if(selectedOpenRegular==="Regular" && selectedCourts && !!selectedCourts.length){
            const sorted = data.filter((item:any) => {
                const courtIds = item.courts.map((court:any) => court.id);
                return selectedCourts?.every((id:number) => courtIds.includes(id));
            })?.sort((a:Service,b:Service)=>a.duration-b.duration)?.map((serv:any)=>{
                const {duration,lesson_name,event_name} =serv;
                const Service_name = text==="Booking" ?`Booking ${formatMinutes(duration)}`:text==="Lesson"?`${lesson_name} ${formatMinutes(duration)}`:text==="Event"?`${event_name} ${formatMinutes(duration)}`:''
                return {Service_name,...serv}
            })
            setList(sorted);
            setFileteredList(sorted);
        }else if(selectedOpenRegular=="Open Match" && !!selectedCourts?.length){
            const arr = data.filter((item:any) => {
                const courtIds = item.courts.map((court:any) => court.id);
                return selectedCourts?.every((id:number) => courtIds.includes(id));
            })?.filter((event:any)=>
               event.is_open_match
            ).sort((a:Service,b:Service)=>a.duration-b.duration)?.map((serv:any)=>{
                const {duration,lesson_name,event_name,...newserv} =serv;
                const Service_name = text==="Booking" ?`Booking ${formatMinutes(duration)}`:text==="Lesson"?`${lesson_name} ${formatMinutes(duration)}`:text==="Event"?`${event_name} ${formatMinutes(duration)}`:''
                return {Service_name,...serv}
            })
            setList(arr);
            setFileteredList(arr);
        }else{
            const sorted = data?.filter((item:any) => {
                const coachIds = item?.courts?.map((court:any) => court?.id);
                return selectedCourts?.every((id:number) => coachIds.includes(id));
            })?.map((serv:any)=>{
                const {duration,lesson_name,event_name,...newserv} =serv;
                const Service_name = text==="Booking" ?`Booking ${formatMinutes(duration)}`:text==="Lesson"?`${lesson_name} ${formatMinutes(duration)}`:text==="Event"?`${event_name} ${formatMinutes(duration)}`:''
                return {Service_name,...serv}
            });
        setList(sorted);
        setFileteredList(sorted);
        }
    }else{
            const sorted = data?.filter((item:any) => {
                const coachIds = item?.caochesteachings?.map((coach:any) => coach.coach_id);
                return selectedCoaches?.every((id:number) => coachIds?.includes(id));
            })?.map((serv:any)=>{
                const {duration,lesson_name,event_name,...newserv} =serv;
                const Service_name = text==="Booking" ?`Booking ${formatMinutes(duration)}`:text==="Lesson"?`${lesson_name} ${formatMinutes(duration)}`:text==="Event"?`${event_name} ${formatMinutes(duration)}`:''
                return {Service_name,...serv}
            });
        setList(sorted);
        setFileteredList(sorted);
        }
    },[data, selectedOpenRegular,selectedCourts,view,selectedCoaches]);

    useEffect(()=>{
        document.addEventListener("click", ()=>{
            setShow(false);
        })
    },[]);

    useEffect(()=>{
        if(Object.keys(event).length > 0 && value!==undefined){
            setCustomEvent(false);
        }else if(Object.keys(event).length > 0 && value===undefined){
            setCustomEvent(true);
            addPrice(event?.service?.price);
            addService(event?.service?.additional_service);
        }else{
            setCustomEvent(false);
        }

    },[value,event])

    useEffect(()=>{
        if(customEvent){
            setCustomEvent(false);
        }
    },[text]);
  


  return (
    <Box position={'relative'} sx={{width:{xs:"100%", sm:"300px"}}}>
        {/* showing list */}
        {!customEvent && <Box > 
            <input onClick={handleClick} type='text' placeholder={t(`Calendar.${text} Service`)}
                value={  value && Object.keys(value)?.length !== 0 ? 
                    value?.event_name ? `${value.event_name} - (${formatMinutes(value.duration)})` :
                    value?.lesson_name ? `${value.lesson_name} - (${formatMinutes(value.duration)})` :
                    `Booking - (${formatMinutes(value.duration)})` : inputValue}
                onChange={(e:React.FormEvent<HTMLInputElement>)=>setInputValue(e.currentTarget.value)}
                style={{cursor:isMorethanOne ? "not-allowed":"pointer",width:"100%", height:"40px", border:"1px solid #EEEEFE", borderRadius:"7px",fontFamily:"var(--font-medium)",color:"#22356D"}} 
            />
            {!show ? <BsChevronDown style={{position:'absolute',top:"12.5px", right:'8px',fontSize:"0.8rem"}} />
            : <BsChevronUp style={{position:"absolute",top:"9.5px", right:"3px", fontSize:'1rem', paddingRight:"4px"}}/>}
        </Box>}
        {show && <Box position={'absolute'} top={"40.5px"} left={0} zIndex={9} width={"inherit"} sx={{fontFamily:"var(--font-regular)",background:"white"}}>
            <Box id="table-person" sx={{background:"#f3f5f8",boxShadow:'rgba(0, 0, 0, 0.16) 0px 1px 4px !important',marginTop:"0 !important", maxHeight:'160px',color:"#22356D",">div:hover":{background:"#22356D", color:"white"}}}>
                {/* create the custom service */}
                <Box sx={{padding:"6px 0px 6px 12px", cursor:"pointer", display:'flex', alignItems:'center'}}onClick={()=>{setCustomEvent(true);setShow(false)}}>{t('Calendar.Custom Service')} &nbsp;<IoAddSharp /></Box>
                
                {/* showing event list */}
                {text==="Event" && 
                    filteredList.map((item:any)=>
                        <Box sx={{padding:"6px 0px 6px 12px",cursor:"pointer"}} onClick={(event)=>selectEvent(event, "Event", item)}>{item.event_name} - ({formatMinutes(item.duration)})</Box>
                    )
                }
                {/* showing the lesson list */}
                {text==="Lesson" &&
                    filteredList.map((item:any)=>
                        <Box sx={{padding:"6px 0px 6px 12px",cursor:"pointer"}} onClick={(event)=>selectEvent(event,"Event", item)}>{item.lesson_name} - ({formatMinutes(item.duration)})</Box>
                    )
                }
                {/* showing the booking list */}
                {text==="Booking" && 
                    filteredList.map((item:any)=>
                        <Box sx={{padding:"6px 0px 6px 12px",cursor:"pointer"}} onClick={(event)=>selectEvent(event,"Event", item)}>{t('Calendar.Booking')} - ({formatMinutes(item.duration)})</Box>
                    )
                }
                {list.length===0 && <Box sx={{padding:"6px 0px 6px 12px",cursor:"pointer"}}>{t('Calendar.No Service')}</Box>}
            </Box>
        </Box>}

        {/* adding the custom service */}
        {customEvent && <Box>
            <Box sx={{...inputStyle,borderRadius:'7px',position:'relative'}}>
                <input type="text" placeholder={t('Calendar.Enter service name')} value={customService} style={{...inputStyle,border:'none',height:'35px'}} onChange={(event)=>addService(event.target.value)} />
                <BsChevronDown onClick={()=>setCustomEvent(false)} style={{position:'absolute',top:"12.5px", right:'8px',fontSize:"0.8rem",cursor:'pointer'}} />
            </Box>
            <Box sx={{display:"grid", gridTemplateColumns:"repeat(2, 1fr)", gap:"5px"}}>
                <input type="text" placeholder='Min players' style={{...inputStyle,height:'35px'}} />
                <input type="text" placeholder='Max players' style={{...inputStyle,height:'35px'}} />
            </Box>
            <Box sx={{display:"grid", gridTemplateColumns:"repeat(2, 1fr)", gap:"10px", alignItems:'center'}}>
                <input type='number' placeholder={t('Calendar.Enter service price')} value={customPrice} style={{...inputStyle, marginBottom:"0px", fontFamily:'var(--font-regular)'}} onChange={(event)=>addPrice(event.target.value)} />
                <p style={{fontFamily:"var(--font-regular)", color:"#22356D"}} >{selectLocation?.currency}</p>
            </Box>
        </Box> }      
    </Box>
  )
}

export default EventSelector