import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import AddFieldModel from "src/features/Customers/AddFieldModel";
import { GrSearch } from "react-icons/gr";
import { IoMdAdd } from "react-icons/io";
import EditFieldModel from "src/features/Customers/EditFieldModel";
import CustomerInfo from "../components/CustomerInfo";
import MyDropdown from "../components/Payment/LocationSelectCheckBox";
import CustomModel from ".././components/CustomModel";
import { RxHamburgerMenu } from "react-icons/rx";
import MainContext from "src/context/MainContext";
import { useGetAllCustomers } from "src/hooks/useGetAllCustomers";
import { useGetAllCustomersTypes } from "src/hooks/useGetAllCustomersTypes";
import { useGetAllCustomSettings, useGetAllCustomSettingsData } from "src/api/Customer";
import NotificationToast from "src/Notification/NotificationToast";
import { useTranslation } from "react-i18next";
import { addThousandSeparator } from "src/constants/SportSEparator";
import moment from "moment";
import TopUserInfo from "../components/TopUserInfo";
import Pagination from '@mui/material/Pagination';
import { useGetAllMemeberShip } from "src/api/membership";

const commonStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: "var(--font-regular)",
  color: "#22356D",
};
const fontD = {
  fontFamily: "var(--font-regular) !important",
  color: "#22356DB2",
};

export interface customSettingsObj{
  _id: number,
  columnName: string,
  columnType: string,
  options:Array<any>,
}

const Customers = () => {
  const {t} = useTranslation();
  const [selectedHead, setselectedHead] = useState<string>("");
  const {MobileSideBar, setMobileSideBar} = useContext(MainContext);
  const [sortedData,setSortedData] = useState<any[]>([])
  const [customerType, setCustomerType] = useState<any>([]);
  const [showCus, setShowCus] = useState<boolean>(false);
  const [custField, setCustField] = useState<any>([]);
  const [editField, setEditField] = useState({});
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    customerName: "",
    customerId: "",
    email: "",
    ranking: "",
  });
  const [originalData, setOriginalData] = useState<any[]>([]);
  // const [filteredData, setFilteredData] = useState([]);
  const [showCustomertype,SetshowCustomertype] = useState(false);
  const [customerid,setCustomerID] = useState<any>('');
  const [customertypearr,setCustomTypeArr] = useState<any>([]);
  const {data,isSuccess,isError,refetch, page, setPage,setEmail,setSearch,setMemberships} = useGetAllCustomers();
  const [selected,setSelected] = useState();
  const {data:cuTdata,isError:cuTError,isSuccess:cuTSuccess,refetch:cuTrefetch} =useGetAllMemeberShip();
  const {data:customSettings,isSuccess:customSuccess,isError:customError,refetch:customReftech} = useGetAllCustomSettings();
  const {data:customData,isSuccess:customDataSuccess,isError:customDataError,refetch:RefetchCustomData} = useGetAllCustomSettingsData();



  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleClick = (item:any) => {
    setOpen(true);
    if(item !== "addnew"){
      setCustomerID(item)
    }else if(item ==="addnew"){
      setCustomerID('')
    }
  };

  const handleEditCustomerType = (name: string) => {
    SetshowCustomertype(true);
  };

  const openMobileSidebar=(event:any)=>{
    event.stopPropagation();
    setMobileSideBar(true)
  }
  
  
  const handleEditField = (item:any) => {
    setSelected(item)
    setShow(true);
  };


  // Function to filter data based on filter criteria
  const filterData = () => {

    if (filters.search) {
      setSearch(filters.search);
      setEmail('');
    } else {
      // Apply individual filters
      if (filters.customerName) {
        setSearch(filters.customerName);
        setEmail('');
      }else{
        setSearch('');
      }
     
      if (filters.email) {
        setSearch('');
        setEmail(filters.email);
      }else{
        setEmail(''); 
      }

      if (customerType && customerType.length > 0) {
        setSearch('');
        setEmail('');
        setMemberships(customerType)
      }else{
        setMemberships([]) 
      }
    }  
  };

  useEffect(() => {
    filterData();
  }, [filters, originalData,customerType]);

  const handleFilterChange = (event: any) => {
    const { name, value } = event.target;
    setFilters((prevFilters:any) =>({
      ...prevFilters,
      [name]: value,
    }));
  };


  const handleSelectedHeadChange = (name:string) => {
    setselectedHead((prev:any)=>{
      if(prev===name){
        return "";
      }else{
        return name;
      } 
    })
  };

  useEffect(()=>{
    if(isSuccess && customDataSuccess && customSuccess){
      getallcustomer(data)
    }else if(isSuccess){
      setCustField([]);
      setOriginalData(data?.customers);
    }else if(!isSuccess){
      setCustField([]);
      setOriginalData([]);
    }
  
  },[data,isSuccess,isError,customDataSuccess,customData,customDataError,customSettings,customSuccess,customError]);

  const getallcustomer = async(response:any)=>{
    const filtered = customSettings?.filter((item:customSettingsObj)=>item.columnName!=="userId")?.sort((a:any,b:any)=>b.sequence-a.sequence);
    setCustField(filtered);

    const final  = response?.customers?.map((item:any)=>{
    let {first_name,last_name,...newItem} = item;
    return {first_name,last_name,...newItem};
    })

  const newadsfasdf = !!customData?.records ? mergeArraysById(final,customData?.records,filtered):final;
    setOriginalData(newadsfasdf)
  }

  function mergeArraysById(usersArray:any, customersArray:any,custField:any) {
    return usersArray.map((user:any) => {
      const customer = customersArray.find((customer:any) => customer.userId === user.id);
      let result :any= {};
      custField?.map((item:any)=>item.columnName).forEach((key:string) => {
        if (customer?.hasOwnProperty(key)) {
          result[key] = customer[key];
        }
      });
    
      return !!customer ? { ...result, ...user } : user;
    });
  }


  useEffect(()=>{
    const updatedData = Array.isArray(originalData) ? [...originalData] : [];
    const sorted = updatedData?.sort((a:any, b:any) => {
      // Sorting logic based on the current sorted column
      if (selectedHead) {
        if (typeof a[selectedHead] === 'number' && typeof b[selectedHead] === 'number') {
          return a[selectedHead] - b[selectedHead];
      } else {
          const columnA = String(a[selectedHead]).toLowerCase();
          const columnB = String(b[selectedHead]).toLowerCase();
          return columnA.localeCompare(columnB);
      }
      }
      return 0;
  });
  setSortedData(sorted)

  },[selectedHead,originalData])


  useEffect(()=>{
    if(cuTSuccess){
      const filtered = cuTdata?.map((item:any)=>{
        const {membership_name,id} = item;
        return {name:membership_name,id}
      })
      setCustomTypeArr(filtered)
    }else{
      setCustomTypeArr([])
    }
    
  },[cuTdata,cuTError,cuTSuccess])


  const handlePlusClick=()=>{
    setSelected(undefined);
    setShow(true);
  }

  const exportToCSV = (sortedData:any, custField: any) => {
    let arr:string[] = [];
    custField?.map((item:any)=>{
      arr.push(item.columnName)
    })
    const result = arr.map((item:any) => `${item}`).join(', ');
  
      let csvContent = `ID, First_Name, Last_Name, Email, Phone, Memberships,${result}\n`;

      sortedData.forEach((booking: any) => {
        let val:any = [];
        arr.map((item)=>{
          if(booking[item]===undefined || booking[item]===''){
            val.push('null');
          }else{
            val.push(booking[item]);
          }
        })
        let customData = val.map((item:any) => `${item}`).join(', ');
        const row = `${booking.id},${booking.first_name},${booking.last_name},${booking.email},${booking.phone_number},${booking.customerMemberships.map((item:any) => `${item.membership.membership_name}`).join(' ')}, ${customData}\n`;

        csvContent += row;
      
    });


    const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const csvURL = URL.createObjectURL(csvBlob);

    const link = document.createElement("a");
    link.href = csvURL;
    link.download = "Customers.csv";
    link.click();
  };


  // export to csv
  const handleExportButtonClick = () => {
    exportToCSV(sortedData,custField);
  };

  return (
    <Box> <TopUserInfo/>
      <Box sx={{minHeight:'100vh',marginTop:{xs:"0px", sm:"20px", md:"0px"}, padding:{xs:"20px", sm:"20px", md:"1.8rem 2rem 2rem 3.9rem"}}}>
       
       <NotificationToast/>
      <Box>
        <Box sx={{display:"flex", justifyContent:"space-between"}}>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Box sx={{display:{xs:'block',sm:"block", md:"none"},cursor:"pointer"}} onClick={openMobileSidebar}>
              <RxHamburgerMenu className='MobileHamburger'/>
            </Box>
            <Typography
              sx={{ fontFamily: "var(--font-medium)", fontSize: "1.4rem" }}
            >
              {t('title.Customers')}
            </Typography>
            <Button
              sx={{
                color: "rgba(34, 53, 109, 0.70)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                gap: "10px",
                textTransform: "unset",
              }}
              onClick={()=>handleClick("addnew")}
            >
             {t('customers.Add new customer')}  <IoMdAdd style={{ cursor: "pointer" }} />
            </Button>
          </Stack>
          <Box sx={{display:{ xs:'block', sm:"block", md:"none" }, background:"white", width:"27px", height:"27px", borderRadius:"7px", padding:"5px"}} onClick={()=>setShowCus(true)}>
            <img src='/images/spreadsheet.png' />
          </Box>
        </Box>
        <Box gap={1} sx={{ ...commonStyle }}>
          <Box>
            <Box gap={1} alignItems={"center"} flexWrap={'wrap'}
              sx={{display:{xs:"grid", sm:"flex"}, gridTemplateColumns:"repeat(2, 1fr)"}}
            >
              {/* <input
                name="customerId"
                onChange={handleFilterChange}
                value={filters.customerId}
                type="number"
                placeholder="ID"
                style={{
                  height: "33px",
                  width: "120px",
                  fontFamily: "var(--font-regular)",
                }}
              /> */}

              <input
                name="customerName"
                onChange={handleFilterChange}
                value={filters.customerName}
                type="text"
                placeholder={t('common.Name or Last Name')}
                style={{
                  height: "33px",
                  width: "190px",
                  fontFamily: "var(--font-regular)",
                }}
              />

              <input
                name="email"
                onChange={handleFilterChange}
                value={filters.email}
                type="text"
                placeholder={t('customers.Email')}
                style={{
                  height: "33px",
                  width: "120px",
                  fontFamily: "var(--font-regular)",
                }}
              />
              {/* --- customer type --- */}
             <MyDropdown getOpeon={()=>console.log('')} border={false}  text="Memberships" arr={customertypearr}  selected={customerType} setSelected={setCustomerType}/>
              <Button className="mobile-res" style={{
                  width: '150px',
                  color: "#22356D",
                  height: "32px",
                  fontFamily: "var(--font-regular)",
                  borderRadius:'7px',
                  padding:'0px',
                  border:'#C6CBDA 1px solid',
                  opacity:'0.7',
                  background:'white',
                  textTransform:'capitalize'
                }}
                onClick={handleExportButtonClick}
                >
              {t("common.Export To CSV")}
            </Button>
            </Box>
            <Paper
              component="form"
              sx={{
                background: "transparent !important",
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1) !important",
                borderRadius: "7px !important",
                marginTop: "10px",
              }}
            >
              <InputBase
                name="search"
                onChange={handleFilterChange}
                value={filters.search}
                sx={{ ml: 1, flex: 1, color: "#22356D" }}
                placeholder={t("common.Search")}
                inputProps={{ "aria-label": "search" }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <GrSearch style={{ fontSize: "15px" }} />
              </IconButton>
            </Paper>
          </Box>
          <Box sx={{display:{xs:"none", sm:"none", md:"block"}}}>
            <Box
              sx={{
                boxShadow:
                  "4px 4px 4px 0px #0000000D inset, -4px 0px 4px 0px #0000000D inset",
                padding: "10px 15px",
                borderRadius: "7px",
                border: "0.5px solid #EEEFEE",
                ...commonStyle,
                gap: "6rem",
              }}
            >
              <Box>
                <p>{t('customers.Total Customers')}</p>
              </Box>
              <Box>
                <p>{data?.totalRecords}</p>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box marginTop={2} id="customer-table" sx={{position:"relative !important"}}>
          <table style={{ width: "100%" }}>
            <thead style={{ background: "white" }}>
              <tr>
                {/* <td onClick={()=>handleSelectedHeadChange("id")} className={selectedHead==="id"?"static-width underline show-row":"static-width show-row"}>ID</td> */}
                <td  onClick={()=>handleSelectedHeadChange("first_name")} className={selectedHead==="first_name"?"different-width underline ":"different-width"}>{t('customers.First Name')}</td>
                <td  onClick={()=>handleSelectedHeadChange("last_name")} className={selectedHead==="last_name"?"different-width underline":"different-width"}>{t('customers.Last Name')}</td>
                <td  onClick={()=>handleSelectedHeadChange("email")} className={selectedHead==="email"?"different-width underline show-row":"different-width show-row"}>{t('customers.Email')}</td>
                <td  className={"different-width show-row"}>{t('customers.Phone')}</td>
                <td
                  className="different-width show-row"
                  // onClick={() => handleEditCustomerType("Customer Type")}
                >
                  {t('customers.Memberships')}
                </td>
                <td
                  className="different-width show-row"
                >
                  {t('customers.Wallet')}
                </td>

                {custField?.slice(0,3)?.map((item: any) => (
                  <td
                    className="different-width show-row"
                    key={item.label}
                    onClick={() => handleEditField(item)}
                  >
                    {item.columnName}
                  </td>
                ))}
              </tr>
              <Box
                sx={{
                  position: "absolute !important",
                  top: "4px !important",
                  right: "-26px !important",
                  padding: "10px 5px 10px 5px !important",
                  borderTopRightRadius: "7px !important",
                  borderBottomRightRadius: "7px !important",
                  background: "white !important",
                  cursor: "pointer !important",
                  zIndex:1
                }}

                onClick={handlePlusClick}
              >
                <IoMdAdd />
              </Box>
            </thead>
            <tbody>
           
              {
             sortedData?.length>0  ? sortedData?.map((item:any)=>
                 { 
                  return<tr onClick={()=>handleClick(item)} key={item.id}>
                    {/* <td className="show-row">{item.id}</td> */}
                    <td>{item.first_name}</td>
                    <td>{item.last_name}</td>
                    <td className="show-row">{item.email}</td>
                    <td className="show-row">{item.phone_number}</td>
                    <td className="show-row">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                        >
                      <Box
                          sx={{
                            background: "#889AB626",
                            width: "fit-content",
                            color: "#22356DB2",
                            borderRadius: "7px",
                            padding: "3px 8px 0px 8px",
                          }}
                        >
                        {item?.customerMemberships?.length>1 ? `${item?.customerMemberships[0]?.membership?.membership_name} + ${item?.customerMemberships?.length-1}` :item?.customerMemberships[0]?.membership?.membership_name}
                        </Box>
                      </Box>
                    </td>
                    <td className="show-row">{item?.CustomersWallet?.length===0?'-':item?.CustomersWallet?.length>1 ?`${addThousandSeparator(item?.CustomersWallet[0]?.balance,item?.CustomersWallet[0]?.currency)} ${item?.CustomersWallet[0]?.currency} + ${item?.CustomersWallet?.length-1}`:`${addThousandSeparator(item?.CustomersWallet[0]?.balance,item?.CustomersWallet[0]?.currency)} ${item?.CustomersWallet[0]?.currency}`}</td>
                    {custField?.sort((a:any,b:any)=>a.sequence-b.sequence)?.slice(0,3)?.map((cust: any) =>{
                      return <td className="different-widthCustom show-row" key={cust.label}>{cust.columnType ==="date" && item[cust.columnName] ?moment(item[cust.columnName]).format('DD/MM/YYYY'):item[cust.columnName]}</td>
                     
                    })}
                    
                  </tr>}           
                ):<tr>
                  <td className="show-row"></td>
                  <td className="show-row"></td>
                  <td >{t('customers.No customers Found')}</td>
                  <td></td>
                  <td className="show-row"></td>
                  <td className="show-row"></td>
                </tr>
              }
            </tbody>
          </table>
          <Box sx={{display:"flex", justifyContent:'center', width:"100%", paddingTop:"20px"}}>
            <Pagination count={data?.totalPages} page={page} onChange={handleChange} variant="outlined" />
          </Box>
        </Box>
        {/* ------ for adding the customer ------ */}
        <CustomerInfo columnRefetch={customReftech}  updateCustomSettings={RefetchCustomData} hidebackdrop={false} GetAllCustomer={refetch} customerdata={customerid} customfield={custField?.sort((a:any,b:any)=>a.sequence-b.sequence)}  fromCustomer={true}
        show={open} setShow={setOpen}
        />

        {/* -------- for adding field in table and form ------- */}
        <AddFieldModel
          custFieldLength={custField?.length}
          update={customReftech}
          selected={selected}
          setSelected={setSelected}
          show={show}
          setShow={setShow}
        />

        {/* ------- for editing the data of table header ------- */}
        <EditFieldModel text="Customer Type" getAllcustomertype={cuTrefetch} arr={customertypearr} editField={editField} open={showCustomertype} setOpen={SetshowCustomertype} />

        {/* Adding modal to all customer */}
        <CustomModel open={showCus} setOpen={setShowCus} >
          <Box
              sx={{
                boxShadow:
                  "4px 4px 4px 0px #0000000D inset, -4px 0px 4px 0px #0000000D inset",
                padding: "10px 15px",
                borderRadius: "7px",
                border: "0.5px solid #EEEFEE",
                marginTop:"30px",
                marginBottom:"10px"
              }}
            >
              <Box
                sx={{...commonStyle,
                  gap: "6rem",}}
              >
                <Box>
                  <p>{t('common.Total')} {t('title.Customers')}</p>
                </Box>
                <Box>
                  <p>{data?.totalRecords}</p>
                </Box>
              </Box>
              {/* <Box
                sx={{...commonStyle,
                  gap: "6rem",}}
              >
                <Box>
                  <p>App Customers</p>
                </Box>
                <Box>
                  <p>{originalData?.length}</p>
                </Box>
              </Box> */}
            </Box>
        </CustomModel>
      </Box>
    </Box>
    </Box>
  );
};

export default Customers;


