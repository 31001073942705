import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  CreateLessonBySportID,
  DeleteLessonByID,
  GetLessonByID,
  UpdateLessonByID,
} from "src/api/ServiceSettings";
import ConditionalDaysRow from "../../components/CourtSetting/ConditionalDaysRow";
import CustomDropdownBookings from "../../components/CustomDropdownBookings";
import LessonsServicesRow from "./LessonsServicesRow";
import { useFieldArray, useForm } from "react-hook-form";
import Cleave from "cleave.js/react";
import MainContext from "src/context/MainContext";
import { useGetAllCoachesServices } from "src/hooks/useGetAllCoachesServices";
import { useTranslation } from "react-i18next";
import { useGetAllMemeberShip } from "src/api/membership";
import { FormControl, MenuItem, Select } from "@mui/material";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import { FaAngleDown } from "react-icons/fa";
import EventLevelRestrictions from "./EventLevelRestrictions";
import { useGetPermissions } from "src/api/CalendarBooking";
import DynamicPrice from "./DynamicPrice";
import RefundConditionRow from "./RefundConditionRow";
import { hours } from "src/constants/variables";

const flexStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

type FormInputs = {
  price: number;
  Duration: number;
  minimum_capacity: number;
  maximum_capacity: number;
  eventInfo: string;
  LessonName: string;
  dynamicPrice: boolean;
  isCancellationPolicy: boolean;
  cancelhour: string;
  available_as_bookable_lesson: boolean;
  refundPolicy: refundpolicy[];
  delete_conditions: [];
};

interface refundpolicy {
  refund_percentage: number | null;
  method_id: string | null;
  cancellation_period_sec: number | null;
  type: string;
  id?: number | null;
}

const underlineCss = {
  marginBottom: "10px",
  color: "#22356D",
  fontFamily: "var(--font-semibold)",
  fontSize: "16px",
  borderBottom: "1px solid #22356D",
};
const borderInput = {
  padding: "5px 8px",
  fontSize: "14px",
  border: "1px solid #EEEFEE",
  borderRadius: "7px",
  color: "#22356D",
  height: "24px",
};
const righttext = {
  color: "#22356D",
  fontFamily: "var(--font-regular)",
  fontSize: "14px",
};

interface pricediff {
  price: number;
  name: string;
}

const LessonsServices = ({
  sport_id,
  Lesson_id,
  selectedlocation,
  handleClose,
  GetLessonData,
}: {
  sport_id: any;
  Lesson_id: any;
  selectedlocation: { id: number; location_name: string; currency: string };
  handleClose: any;
  GetLessonData: any;
}) => {
  const { t } = useTranslation();
  const StaffPermission = useGetPermissions();
  const { register, watch, setValue } = useForm<FormInputs>();
  const { Notification, setNotification, setIsPermissionOn } =
    useContext(MainContext);
  const { control, setValue: setCondiiton, watch: conditionWatch } = useForm();
  const [allCustomerType, setAllCustomerType] = useState<any[]>([]);
  const [selectedcustomerType, setselectedcustomerType] = useState<any[]>([]);
  const [DiffPrice, setDiffPrice] = useState<pricediff[]>([]);
  const {
    data: customType,
    isSuccess: customerTypeSuccess,
    isError: customerTypeError,
  } = useGetAllMemeberShip();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "Rows",
  });
  const conditionData = conditionWatch("Rows");
  const {
    control: SpecialControl,
    setValue: setSpecial,
    watch: specialWatch,
  } = useForm<any>({});
  const {
    fields: FieldsSpecial,
    remove: removeSpecial,
    append: specailAppend,
    insert: specialInsert,
  } = useFieldArray<any>({
    control: SpecialControl,
    name: "Special",
  });
  const [isAppAvail, setIsAppAvail] = useState(false);
  const specialData = specialWatch("Special");
  const {
    Duration,
    minimum_capacity,
    maximum_capacity,
    price,
    eventInfo,
    LessonName,
    dynamicPrice,
    cancelhour,
    isCancellationPolicy,
    refundPolicy,
    delete_conditions,
    available_as_bookable_lesson,
  } = watch();
  const [coachlist, setCoachlist] = useState([]);
  const {
    data: CoachData,
    isSuccess: CoachSuccess,
    isError: CoachError,
    refetch,
    setlocation_id,
    setSportId,
  } = useGetAllCoachesServices();
  const [selectedCoaches, setSelectedCoaches] = useState([]);
  const [error, setError] = useState("");
  const [servicesopen, setServicesOpen] = useState(false);
  const [isWaitingOn, setIsWaitingOn] = useState("Off");
  const [levelRestriction, setLevelRestriction] = useState({
    minimum_range: null,
    maximum_range: null,
  });

  useEffect(() => {
    GetSelectedLesson();
  }, [Lesson_id]);

  function UpdateCancel(regular: any) {
    const added =
      regular?.map((item: any) => {
        let { method_id, cancellation_period_sec, ...newitem } = item;
        method_id === null ? (method_id = "Club Wallet") : (method_id = "");
        cancellation_period_sec = cancellation_period_sec / 3600;

        return { cancellation_period_sec, method_id, ...newitem };
      }) || [];

    return added;
  }

  function GetCancel(regular: any) {
    const updated =
      regular
        ?.filter((item: any) => item.id !== null)
        ?.map((item: any) => {
          let { method_id, cancellation_period_sec, ...newitem } = item;
          method_id === "Club Wallet" ? (method_id = null) : (method_id = "");
          cancellation_period_sec = cancellation_period_sec * 3600;

          return { cancellation_period_sec, method_id, ...newitem };
        }) || [];
    const added =
      regular
        ?.filter((item: any) => item.id === null)
        ?.map((item: any) => {
          let { id, method_id, cancellation_period_sec, ...newitem } = item;
          method_id === "Club Wallet" ? (method_id = null) : (method_id = "");
          cancellation_period_sec = cancellation_period_sec * 3600;
          return { cancellation_period_sec, method_id, ...newitem };
        }) || [];

    return {
      update_conditions: updated,
      add_conditions: added,
      delete_conditions:
        delete_conditions?.length > 0 ? delete_conditions : undefined,
    };
  }

  useEffect(() => {}, [selectedCoaches]);

  const GetSelectedLesson = async () => {
    remove();
    setselectedcustomerType([]);
    removeSpecial();
    if (Lesson_id !== undefined) {
      try {
        const response = await GetLessonByID(Lesson_id);
        const levelRest = {
          minimum_range: response?.min_level_restrication,
          maximum_range: response?.max_level_restrication,
        };
        setValue("minimum_capacity", response?.minimum_capacity);
        setValue("maximum_capacity", response?.maximum_capacity);
        setIsAppAvail(response?.app_available);
        setLevelRestriction(levelRest);
        setIsWaitingOn(response?.waiting_list ? "On" : "Off");
        setValue("Duration", response?.duration);
        setValue("price", response?.price);
        setValue("LessonName", response?.lesson_name);
        setValue("eventInfo", response?.event_info);
        setValue("dynamicPrice", false);
        setValue(
          "available_as_bookable_lesson",
          response?.available_as_bookable_lesson
        );
        const dataCancel = UpdateCancel(response?.refundConditions);
        setValue("refundPolicy", dataCancel);
        setValue(
          "cancelhour",
          String(response?.lesson_cancellation_sec / 3600)
        );
        setValue("isCancellationPolicy", response?.lesson_cancellation_enabled);
        setIsAppAvail(response?.app_available);
        setIsWaitingOn(response?.waiting_list ? "On" : "Off");
        const finalSelCoaches = response?.coaches?.map((item: any) => {
          let { coach, id, ...newitem } = item;
          let full_name = coach?.full_name;
          let profile_url = coach?.profile_url;
          id = coach?.id;
          return { full_name, id, profile_url, ...newitem };
        });
        const finalCondition = response?.conditional_days?.map(
          (item: any, index: number) => {
            let { days, all_user, memberships, ...newitem } = item;
            const id = index;
            days !== null ? (days = [days]) : (days = undefined);
            const filterMember = memberships?.map((mem: any) => {
              const { id, membership_name } = mem;
              return { id, name: membership_name };
            });
            setselectedcustomerType((prev: any) => [...prev, filterMember]);
            append({ days, all_user, ...newitem });
            return { id, days, ...newitem };
          }
        );
        // const finalSpecial = response?.special_days?.map(
        //   (item: any, index: number) => {
        //     let { days,...newitem } = item;
        //     const id = index;
        //     days !== null ? (days = [days]) : (days = undefined);
        //     specialInsert(index,{days,...newitem})
        //     return { id,days, ...newitem };
        //   }
        // );
        setSelectedCoaches(finalSelCoaches);
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (dynamicPrice) {
      const updateData = Array.from(
        { length: maximum_capacity },
        (_, index) => ({ price: 0, name: `player ${index + 1}` })
      );
      setDiffPrice(updateData);
    }
  }, [dynamicPrice, maximum_capacity]);

  useEffect(() => {
    selectedcustomerType?.map((item: any, index) => {
      const isAllUser = conditionData[index].all_user;
      const AllMemebrship = isAllUser
        ? [{ id: 0, name: "All User" }, ...item]
        : item;
      setCondiiton(`Rows.${index}.memberships`, AllMemebrship);
    });
  }, [selectedcustomerType]);

  useEffect(() => {
    if (customerTypeSuccess) {
      const filtered = customType?.map((item: any) => {
        const { id, membership_name } = item;
        return { id, name: membership_name };
      });
      setAllCustomerType(filtered);
    } else {
      setAllCustomerType([]);
    }
  }, [customType, customerTypeSuccess, customerTypeError]);

  const APIData = {
    duration: Duration,
    available_as_bookable_lesson: available_as_bookable_lesson,
    lesson_name: LessonName,
    minimum_capacity: Number(minimum_capacity),
    maximum_capacity: Number(maximum_capacity),
    price: price,
    app_available: isAppAvail,
    event_info: eventInfo,
    min_level_restrication: levelRestriction?.minimum_range,
    max_level_restrication: levelRestriction?.maximum_range,
    waiting_list: isWaitingOn === "On" ? true : false,
    cancellation_policys: {
      lesson_cancellation_enabled: isCancellationPolicy,
      lesson_cancellation_sec: Number(cancelhour) * 3600,
    },
    // special_days:specialData?.map((item: any) => ({
    //   start_time: item.start_time,
    //   end_time: item.end_time,
    //   days: item.days?.length !== 0 ? item.days : undefined,
    //   date_start:
    //     item.date_start !== undefined && item.date_start !== null
    //       ? item.date_start
    //       : undefined,
    //   date_end:
    //     item.date_end !== undefined && item.date_end !== null
    //       ? item.date_end
    //       : undefined,
    // })),
    conditional_days: conditionData?.map((item: any) => {
      let { days, date_start, date_end, memberships, ...newitem } = item;
      days = days?.length !== 0 ? days : undefined;
      date_start =
        date_start !== undefined && date_start !== null
          ? date_start
          : undefined;
      date_end =
        date_end !== undefined && date_end !== null ? date_end : undefined;
      const all_user = item.memberships?.some(
        (mem: any) => mem.name === "All User"
      );
      memberships = item.memberships
        ?.filter((mem: any) => mem?.name !== "All User")
        ?.map((item: any) => item.id);
      return {
        date_start,
        date_end,
        days,
        membership: memberships,
        all_user,
        ...newitem,
      };
    }),
    coaches: selectedCoaches?.map((item: any) => {
      let { id, price } = item;
      let coach_id = id;
      return { coach_id, price };
    }),
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();

    if (!StaffPermission.edit_permissions) {
      setIsPermissionOn(true);
      handleClose();
      return;
    }

    if (!LessonName) {
      setError("lesson name");
      setTimeout(() => setError(""), 2000);
    } else if (!Duration) {
      setError("duration");
      setTimeout(() => setError(""), 2000);
    } else if (!minimum_capacity) {
      setError("minimum capacity");
      setTimeout(() => setError(""), 2000);
    } else if (!maximum_capacity) {
      setError("maximum capacity");
      setTimeout(() => setError(""), 2000);
    } else if (!price) {
      setError("price");
      setTimeout(() => setError(""), 2000);
    } else if (Number(minimum_capacity) > Number(maximum_capacity)) {
      setError("capacity");
      setTimeout(() => setError(""), 2000);
    } else {
      setError("");
      Lesson_id !== undefined ? updateLesson() : createLEsson();
    }
  };

  const createLEsson = async () => {
    if (
      APIData.min_level_restrication == null &&
      APIData.max_level_restrication == null
    ) {
      setNotification({
        message: "Select the level range",
        type: "error",
        subMessage: "",
        isShow: true,
      });
      return;
    }
    const reffundCond = GetCancel(refundPolicy);
    const updatedData = { ...APIData, refund_conditions: reffundCond };
    try {
      const response = await CreateLessonBySportID(sport_id, updatedData);
      if (response) {
        setNotification({
          message: response,
          type: "success",
          subMessage: "",
          isShow: true,
        });
        GetLessonData(0);
        handleClose();
      }
    } catch (error) {
      // setNotification({message:response,type:'success',subMessage:'',isShow:true})
    }
  };

  const updateLesson = async () => {
    if (
      APIData.min_level_restrication == null &&
      APIData.max_level_restrication == null
    ) {
      setNotification({
        message: "Select the level range",
        type: "error",
        subMessage: "",
        isShow: true,
      });
    } else {
      const reffundCond = GetCancel(refundPolicy);
      const updatedData = { ...APIData, refund_conditions: reffundCond };
      try {
        const response = await UpdateLessonByID(Lesson_id, updatedData);
        if (response) {
          setNotification({
            message: response,
            type: "success",
            subMessage: "",
            isShow: true,
          });
          GetLessonData(0);
          handleClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteLesson = async () => {
    if (!StaffPermission.edit_permissions) {
      setIsPermissionOn(true);
      handleClose();
      return;
    }

    try {
      const response = await DeleteLessonByID(Lesson_id);
      if (response) {
        setNotification({
          message: response,
          type: "success",
          subMessage: "",
          isShow: true,
        });
        GetLessonData(0);
        handleClose();
      }
    } catch (error) {}
  };

  useEffect(() => {
    setlocation_id(selectedlocation?.id);
    setSportId(0);
  }, [selectedlocation]);

  useEffect(() => {
    if (CoachSuccess) {
      const filtered = CoachData?.filter(
        (item: any) => item?.sport_id === sport_id
      );
      setCoachlist(filtered);
    } else {
      setCoachlist([]);
    }
  }, [CoachError, CoachSuccess, CoachData]);

  const handleAddCondition = () => {
    const final = {
      start_time: "08:00:00",
      end_time: "22:00:00",
      memberships: [],
      // start_date: "",
      // end_date: "",
      // days: [],
      price: "",
    };
    append(final);
  };

  const handleAddSpecial = () => {
    const final = {
      start_time: "08:00:00",
      end_time: "22:00:00",
      break_times: [],
    };
    specailAppend(final);
  };

  const handleUpdateForm = (data: any, index: number) => {
    setCondiiton(`Rows.${index}.start_time`, data[0].start_time);
    setCondiiton(`Rows.${index}.end_time`, data[0].end_time);
    const updatedObj = conditionData[index];
    if (
      data[0].days !== undefined &&
      data[0].date_start == undefined &&
      data[0].date_end == undefined
    ) {
      delete updatedObj["date_end"];
      delete updatedObj["date_start"];
      setCondiiton(`Rows.${index}.days`, data[0].days);
    } else if (
      data[0]?.date_end !== undefined &&
      data[0]?.date_start !== undefined &&
      data[0]?.days === undefined
    ) {
      delete updatedObj["days"];
      setCondiiton(`Rows.${index}.date_start`, data[0].date_start);
      setCondiiton(`Rows.${index}.date_end`, data[0].date_end);
    }
  };

  const handleUpdateSpecial = (data: any, index: number) => {
    setSpecial(`Special.${index}.start_time`, data[0].start_time);
    setSpecial(`Special.${index}.end_time`, data[0].end_time);
    if (
      data[0].days !== undefined &&
      data[0].date_start == undefined &&
      data[0].date_end == undefined
    ) {
      setSpecial(`Special.${index}.days`, data[0].days);
    } else if (
      data[0]?.date_end !== undefined &&
      data[0]?.date_start !== undefined &&
      data[0]?.days === undefined
    ) {
      setSpecial(`Special.${index}.date_start`, data[0].date_start);
      setSpecial(`Special.${index}.date_end`, data[0].date_end);
    }
  };

  function updateField(array: any, index: number, newValue: string) {
    if (index >= 0 && index < array.length) {
      array[index].price = newValue;
    }
  }

  const addrefundField = () => {
    const currentFields = watch("refundPolicy") || [];
    const updatedFields = [
      ...currentFields,
      {
        refund_percentage: null,
        method_id: "",
        cancellation_period_sec: null,
        type: "lesson",
        id: null,
      },
    ];
    setValue("refundPolicy", updatedFields);
  };

  return (
    <Box
      sx={{
        background: "white",
        padding: { xs: "0px", sm: "20px 16px 0px 16px" },
        borderRadius: "7px",
      }}
    >
      <form onSubmit={handleFormSubmit}>
        <Grid>
          <span style={underlineCss}>Lessons Settings</span>
          <Box display={"flex"} flexDirection={"column"} gap={1.5}>
            <Box sx={{ ...flexStyle }}>
              <Typography sx={{ ...righttext }}>
                {t("services.lesson.Lesson Name")}
                <span style={{ color: "red" }}> *</span>
              </Typography>
              <Box width={"150px"}>
                <input
                  id={error == "lesson name" ? "customerror" : ""}
                  {...register("LessonName", { required: true })}
                  type="text"
                  placeholder={t("common.Beginner Lesson")}
                  style={{ ...borderInput, width: "inherit" }}
                />
              </Box>
            </Box>
            <Box sx={{ ...flexStyle }}>
              <Typography sx={{ ...righttext }}>
                {t("services.bookings.Duration")}
                <span style={{ color: "red" }}> *</span>
              </Typography>
              <Box>
                <Box width={"150px"} sx={{ ...flexStyle }}>
                  <input
                    id={error === "duration" ? "customerror" : ""}
                    {...register("Duration", { required: true })}
                    type="number"
                    placeholder="60"
                    style={{ ...borderInput, width: "51px" }}
                  />
                  <Typography sx={{ ...righttext }}>
                    {t("common.minutes")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ ...flexStyle }}>
              <Typography sx={{ ...righttext }}>
                {t("services.lesson.Lesson Capacity")}
                <span style={{ color: "red" }}> *</span>
              </Typography>
              <Box>
                <Box width={"150px"} sx={{ ...flexStyle }}>
                  <label style={{ ...righttext }}>
                    {t("common.min")}&nbsp;{" "}
                    <input
                      id={error === "minimum capacity" ? "customerror" : ""}
                      {...register("minimum_capacity", { required: true })}
                      type="text"
                      placeholder="2"
                      style={{ ...borderInput, width: "40px" }}
                    />
                  </label>
                  <label style={{ ...righttext }}>
                    {t("common.max")}&nbsp;{" "}
                    <input
                      id={error === "maximum capacity" ? "customerror" : ""}
                      {...register("maximum_capacity", { required: true })}
                      type="text"
                      placeholder="4"
                      style={{ ...borderInput, width: "40px" }}
                    />
                  </label>
                </Box>
              </Box>
            </Box>
            {error === "capacity" && (
              <span
                style={{
                  fontFamily: "var(--font-regular)",
                  color: "red",
                  fontSize: "13px",
                  textAlign: "right",
                }}
              >
                {t(
                  "services.bookings.minimum capacity must be less than maximum capacity"
                )}
              </span>
            )}
            <Box
              sx={{
                ...flexStyle,
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-start", md: "center" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Typography sx={{ ...righttext }}>
                  {t("common.Price")}
                  <span style={{ color: "red" }}> *</span>
                </Typography>
              </Box>

              {!dynamicPrice && (
                <Box width={"150px"} sx={{ ...flexStyle }}>
                  <Cleave
                    id={error === "price" ? "customerror" : ""}
                    style={{
                      width: "100px",
                      color: "rgba(34, 53, 109)",
                      fontFamily: "var(--font-regular)",
                      fontSize: "14px",
                      lineHeight: "17px",
                      borderRadius: "7px",
                      padding: "5px 8px",
                      border: "1px solid #EEEFEE",
                    }}
                    onChange={(event: any) =>
                      setValue(`price`, event.target.rawValue)
                    }
                    value={price}
                    placeholder="100"
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: "thousand",
                      delimiter:
                        selectedlocation.currency === "IDR" ? "\u2024" : ",",
                    }}
                  />
                  <p style={{ ...righttext }}>{selectedlocation.currency}</p>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        <Grid marginTop={"25px"}>
          <div>
            <span style={underlineCss}>
              {t("services.lesson.Coaches teaching this lesson")}{" "}
              {selectedCoaches?.length}/{coachlist?.length}
            </span>
          </div>
          <CustomDropdownBookings
            getTime={setSelectedCoaches}
            preTime={selectedCoaches}
            listData={coachlist}
          />
          <Box>
            <Box
              style={{
                marginTop: "10px",
                borderRadius: "7px",
                boxShadow: "inset 2px 2px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                id="table-person"
                style={{
                  width: "-webkit-fill-available",
                  maxHeight: "270px",
                  overflowY: "auto",
                  marginTop: "0px",
                }}
              >
                {selectedCoaches?.map((item: any, index) => (
                  <LessonsServicesRow
                    defaultPrice={price}
                    id={item?.id}
                    selectedlocation={selectedlocation}
                    setSelectedCoaches={setSelectedCoaches}
                    key={index}
                    index={index}
                    data={item}
                  />
                ))}
              </div>
            </Box>
          </Box>
        </Grid>
        {/* <Grid marginTop={"25px"}>
          <Grid container alignItems={"center"} gap={"15px"}>
            <h3
              style={{
                color: "#22356D",
                fontFamily: "var(--font-regular)",
                fontSize: "16px",
              }}
            >
             {t('courts.Special Days')}
            </h3>
            <Button
              onClick={handleAddSpecial}
              style={{
                color: "rgba(34, 53, 109, 0.70)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                gap: "10px",
                textTransform: "none",
              }}
            >
              {t('courts.Add special day')}
              <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
            </Button>
          </Grid>
          <div className="flex flex-col my-1">
          {FieldsSpecial?.length !== 0 &&
          FieldsSpecial?.map((item: any, index: any) => (
                  <ServicesSpecialRow
                  remove={removeSpecial}
                  handleUpdateSpecial={handleUpdateSpecial}
                    preSpecialDays={specialData}
                    key={index}
                    index={index}
                    setSpecial={setSpecial}
                  />
                ))}
          </div>
        </Grid> */}
        <Grid style={{ margin: "25px 0" }}>
          <Grid container>
            <Grid container alignItems={"center"} gap={"15px"}>
              <h3
                style={{
                  color: "#22356D",
                  fontFamily: "var(--font-regular)",
                  fontSize: "16px",
                }}
              >
                {t("services.events.Discount Conditions")}
              </h3>
              <Button
                onClick={handleAddCondition}
                style={{
                  color: "rgba(34, 53, 109, 0.70)",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  gap: "10px",
                  textTransform: "none",
                }}
              >
                {t("services.bookings.Add Condition")}
                <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
              </Button>
            </Grid>
            <Box
              sx={{ margin: { xs: "0px", sm: "0 5px 0 20px" }, width: "100%" }}
            >
              {fields?.length !== 0 &&
                fields?.map((item: any, index: any) => (
                  <ConditionalDaysRow
                    setServicesOpen={setServicesOpen}
                    customer_type={allCustomerType}
                    conditionData={conditionData}
                    handleUpdateForm={handleUpdateForm}
                    selectedlocation={selectedlocation}
                    setValue={setCondiiton}
                    index={index}
                    remove={remove}
                  />
                ))}
            </Box>
          </Grid>
        </Grid>
        <Grid>
          <Box
            sx={{
              width: "max-content",
              margin: "10px 0",
              color: "#22356D",
              fontFamily: "var(--font-semibold)",
              fontSize: "16px",
              borderBottom: "1px solid #22356D",
            }}
          >
            {t("courts.App Settings")}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                color: "#22356D",
                fontFamily: "var(--font-regular)",
                fontSize: "16px",
              }}
            >
              {t("common.Available in App")}
            </Typography>
            <label className="switch">
              <input
                checked={isAppAvail}
                onChange={() => setIsAppAvail(!isAppAvail)}
                type="checkbox"
              />
              <span className="slider round"></span>
            </label>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                color: "#22356D",
                fontFamily: "var(--font-regular)",
                fontSize: "16px",
              }}
            >
              {t("common.Available as bookable lesson")}
            </Typography>
            <label className="switch">
              <input
                checked={available_as_bookable_lesson}
                onChange={() =>
                  setValue(
                    "available_as_bookable_lesson",
                    !available_as_bookable_lesson
                  )
                }
                type="checkbox"
              />
              <span className="slider round"></span>
            </label>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              margin: "5px 0",
            }}
          >
            <Typography
              sx={{
                width: "160px",
                color: "rgba(34, 53, 109)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                lineHeight: "17px",
                borderRadius: "7px",
                padding: "5px 8px",
                border: "1px solid #EEEFEE",
              }}
            >
              {t("common.Waiting List")}
            </Typography>
            <FormControl>
              <Select
                value={isWaitingOn}
                renderValue={(selected) => {
                  if (selected) {
                    return selected;
                  }
                  return `Select`;
                }}
                onChange={(e: any) => setIsWaitingOn(e.target.value)}
                id="customdropdown"
                style={{
                  height: "27px",
                  border: "1px solid #EEEEFE",
                  borderRadius: "7px",
                  fontSize: "14px",
                  opacity: "1",
                  fontFamily: "var(--font-regular)",
                  color: "#22356D",
                  width: "150px",
                }}
                MenuProps={{
                  sx: {
                    ...dopdowncustomcss,
                    "& .Mui-selected::after": { content: "none" },
                  },
                }}
                IconComponent={FaAngleDown}
              >
                {["On", "Off"].map((day: string) => (
                  <MenuItem
                    style={{
                      fontSize: "14px",
                      opacity: "1",
                      fontFamily: "var(--font-regular) !important",
                      color: "#22356D",
                    }}
                    key={day}
                    value={day}
                  >
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              margin: "5px 0",
            }}
          >
            <Typography
              sx={{
                width: "160px",
                color: "rgba(34, 53, 109)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                lineHeight: "17px",
                borderRadius: "7px",
                padding: "5px 8px",
                border: "1px solid #EEEFEE",
              }}
            >
              {t("common.Level Restriction")}
            </Typography>
            <EventLevelRestrictions
              key={1}
              setDiscountConditions={setLevelRestriction}
              preData={levelRestriction}
              index={1}
            />
          </Box>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <Typography
            sx={{
              color: "#22356D",
              fontFamily: "var(--font-regular)",
              fontSize: "16px",
            }}
          >
            {t("common.Cancellation Policy")}
          </Typography>
          <label className="switch">
            <input {...register("isCancellationPolicy")} type="checkbox" />
            <span className="slider round"></span>
          </label>
        </Box>
        <Box>
          {isCancellationPolicy ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "start", md: "center" },
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  color: "#22356D",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  opacity: "0.8",
                }}
              >
                {t("common.Users can cancel up to")}
              </Typography>
              <FormControl sx={{ width: { xs: "auto", md: "auto" } }}>
                <Select
                  value={cancelhour ? cancelhour : t("common.Select")}
                  onChange={(e: any) => setValue("cancelhour", e.target.value)}
                  IconComponent={KeyboardArrowDownIcon}
                  placeholder="Customer type"
                  id="customdropdown"
                  renderValue={(value) => {
                    if (value) {
                      return `${value} hours`;
                    }
                    return <em>{t("common.Select")}</em>;
                  }}
                  sx={{
                    width: { xs: "100%", md: "120px" },
                    height: "35px",
                    border: "1px solid #EEEEFE",
                    borderRadius: "7px",
                    fontSize: "14px",
                    opacity: "1",
                    fontFamily: "var(--font-regular)",
                    color: "#22356D",
                  }}
                  MenuProps={{ sx: dopdowncustomcss }}
                >
                  {hours?.map((location: any) => (
                    <MenuItem key={location} value={location}>
                      {location} hours
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography
                sx={{
                  color: "#22356D",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  opacity: "0.8",
                }}
              >
                {t("common.Before booking starts")}
              </Typography>
            </Box>
          ) : (
            <Typography
              sx={{
                color: "#22356D",
                fontFamily: "var(--font-regular)",
                opacity: "0.8",
                fontSize: "14px",
                marginY: "5px",
              }}
            >
              {t("common.Users can cancel anytime before the booking starts.")}
            </Typography>
          )}
        </Box>
        <Grid container mt={"10px"}>
          <Grid container alignItems={"center"} gap={"15px"}>
            <h3
              style={{
                color: "#22356D",
                fontFamily: "var(--font-regular)",
                fontSize: "16px",
              }}
              className="fontSize"
            >
              {t("common.Refund Conditions")}
            </h3>
            <Button
              onClick={addrefundField}
              style={{
                color: "rgba(34, 53, 109, 0.70)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                gap: "10px",
                textTransform: "none",
              }}
            >
              {t("services.bookings.Add Condition")}
              <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
            </Button>
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            {refundPolicy?.length > 0 ? (
              refundPolicy?.map((_, index) => (
                <RefundConditionRow
                  type={"refundPolicy"}
                  delete_conditions={delete_conditions}
                  data={refundPolicy}
                  setValue={setValue}
                  index={index}
                />
              ))
            ) : (
              <Typography
                sx={{
                  color: "#22356D",
                  fontFamily: "var(--font-regular)",
                  opacity: "0.8",
                  fontSize: "14px",
                  marginY: "5px",
                }}
              >
                {t("common.There is no refund upon cancellation.")}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid marginTop={"25px"}>
          <div>
            <span style={underlineCss}>
              {t("services.lesson.Service info")}
            </span>
          </div>
          <textarea
            rows={4}
            cols={5}
            {...register("eventInfo")}
            style={{
              width: "100%",
              opacity: "1",
              padding: "10px",
              fontSize: "14px",
              border: "1px solid #EEEFEE",
              borderRadius: "7px",
              color: "#22356D",
              marginTop: "10px",
            }}
            placeholder={t("common.Service Information")}
          ></textarea>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          // marginTop={servicesopen ?"200px":"20px"}
        >
          <Button
            onClick={Lesson_id !== undefined ? deleteLesson : handleClose}
            style={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              width: "104px",
              height: "37px",
              color: "#22356D",
              backgroundColor: "white",
              fontSize: "16px",
              padding: "10px 30px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
            }}
          >
            {Lesson_id ? t("common.Delete") : t("common.Cancel")}
          </Button>
          <Button
            type="submit"
            style={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              color: "white",
              backgroundColor: "#22356D",
              width: "92px",
              height: "37px",
              fontSize: "16px",
            }}
          >
            {t("common.Save")}
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default LessonsServices;
