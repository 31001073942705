import {
  getCoachlist,
  getMonthStatus,
  useGetCoachesDailyStats,
} from "src/api/CoachCalendar";
import CoachMonthCalendar from "src/features/calendarMonthlyCoach/CoachMonthCalendar";
import MonthlyCalendar from "src/features/calendarMonthly/MonthlyCalendar";
import WeekCalendar from "src/features/calendarWeeklyCoach/WeekCalendar";
import { FaAngleDown } from "react-icons/fa6";
import CourtPlayerGraph from "../components/Calendar/CourtPlayerGraph";
import DailyTimetable from "../features/calenderDaily/DailyTimetable";
import WeekSlider from "src/features/calendarWeeklyCoach/WeekSlider";
import CoachCalendar from "src/features/CoachCalendar/CoachCalendar";
import { SingleDate } from "../components/customTimeSeletor";
import VLdropdown from "../components/Calendar/VLdropdown";
import { useGetLocation } from "src/api/CourtSettings";
import { Dialog, DialogContent, Tab, useMediaQuery } from "@mui/material";
import DateCarousel from "../components/DateCarousel";
import CustomTabs from "../components/TabComponent";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import MainContext, { monthlyRate } from "../context/MainContext";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import InputBase from "@mui/material/InputBase";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "air-datepicker/air-datepicker.css";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import moment from "moment";

import "./style.css";
import CourtWeekCalendar from "src/features/calendarsWeekly/WeekCalendar";
import NotificationToast from "src/Notification/NotificationToast";
import { useGetCourtList } from "src/hooks/Calendars/useGetCourtList";
import TabSlider from "../components/TabSlider";
import { Select, SelectItem } from "@nextui-org/react";
import { useSportQuery } from "src/hooks/useSportQuery";
import theme from "src/theme";
import { useTranslation } from "react-i18next";
import { useSocket } from "src/context/SocketProvider";
import { useQueryClient } from "react-query";
import {
  getClubId,
  Permission,
  useGetPermissions,
} from "src/api/CalendarBooking";
import TopUserInfo from "../components/TopUserInfo";
import StaffModal from "src/Notification/StaffModal";
import MobileWeekCalendar from "src/features/calendarsWeekly/MobileWeekCalendar";

const tab = ["Courts", "Coaches"];
const courtView = ["Daily", "Monthly"];
const coachView = ["Daily", "Monthly"];
const MobileCoachView = ["Daily", "Weekly", "Monthly"];

const CalenderPage = () => {
  const { t } = useTranslation();
  const context = React.useContext(MainContext);
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const currentDate = moment();
  const courts = context?.courts;
  const coaches = context?.coaches;
  const ActiveTab = context?.ActiveTab;
  const { data, isSuccess, isError, refetch } = useGetLocation();
  const {
    data: CoachStats,
    isError: CoachStatsEror,
    isSuccess: CoachStatsSuccess,
    refetch: CoachStatsRefetch,
    isLoading,
    setLocation_id,
    setSelectedDate,
    setSports: setSportsID,
  } = useGetCoachesDailyStats();
  const { messages, sendMessage, connectionStatus, setUrl, listenToEvent } =
    useSocket();
  const setMonthlyGraph = context?.setMonthlyGraph;
  const setWeeklyGraph = context?.setWeeklyGraph;
  const selectedMonth = context?.selectedMonth;
  const selectedDate = context?.selectedDate;
  const setSelectedMonth = context?.setSelectedMonth;
  const [tabValue, setTabValue] = React.useState(tab[0]);
  const [tabLocation, setTabLocation] = useState<any>([]);
  const [courtList, setCourtList] = useState<any>([]);
  const [showModel, setShowModel] = useState(false);
  const [MobileSelectedCoach, SetMobileSelectedCoach] = useState([]);
  const [allClubStats, setAllClubStats] = useState<monthlyRate[]>();
  const {
    MobileSideBar,
    setMobileSideBar,
    setSelectedLocation,
    SelectedLocation,
  } = React.useContext(MainContext);
  const [selectedMonthArray, setSelectedMonthArray] = useState<any>(null);
  const {
    data: CourtsList,
    isSuccess: CourtsSuccess,
    isError: CourtError,
    refetch: CourtsListRefetch,
    setLocation_id: SetLocationID,
  } = useGetCourtList();
  const sport_id = context?.sport_id;
  const StaffPermission = useGetPermissions();
  const setSport_id = context?.setSport_id;
  const queryClient = useQueryClient();
  const [selectLocation, setSelectLocation] = useState("");
  const [locationId, setLocationId] = useState(0);
  const [allsports, setAllsports] = useState<any[]>([]);
  const [selectView, setSelectView] = useState(courtView[0]);
  const [coachList, setCoachList] = useState<any>([]);
  const [selectedCoach, setCoach] = useState<any>({});
  const [selectedCourt, setselectedCourt] = useState<any>({});
  const [selectedWeek, setSelectedWeek] = useState<number>(0);
  const {
    data: allSports,
    isSuccess: sportSuccess,
    isError: sportError,
    setlocation_id,
  } = useSportQuery();
  const [weeksGraph, setWeeksGraph] = useState<any>({
    noShowCounts: 0,
    pendingPayments: 0,
    days: {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    },
  });
  const [CalTabValue, setCalTabValue] = useState("payments");
  const [searchValue, setSearchValue] = useState("");
  const [selectedSport, SetSelectedSport] = useState<string>("");
  const [dates, setDates] = useState({
    weekNo: moment().isoWeek(),
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const navigate = useNavigate();

  const Club_Id = getClubId();
  setUrl(`${process.env.REACT_APP_BACKEND_URL}/calendars/${Club_Id}/updates`);

  // search user
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  // function to get all weeks of selected month
  const getAllWeeks = () => {
    const parsedMonth = moment(selectedMonth, "MMM YYYY");
    const year = parsedMonth.year();
    const month = parsedMonth.month();
    setSelectedMonthArray(moment([year, month]));
  };

  const areDatesEqual = moment(selectedDate).isSame(currentDate, "day");

  // transition to booking list
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue) {
        navigate(
          `/${CalTabValue}?inputValue=${encodeURIComponent(searchValue)}`
        );
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  // get month stats
  const getMonthlyStatus = async () => {
    try {
      let res = await getMonthStatus(
        moment(selectedMonth).format("MM-YYYY"),
        locationId
      );
      const hell = res.data?.data;
      const dataArray = Object.keys(hell).map((key: string) => {
        const sport = key?.split(" - ")[1];
        return {
          sport: sport,
          ...hell[key],
        };
      });
      setAllClubStats(dataArray);
      // setMonthlyGraph(res.data?.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleEventOne = (data: any) => {
      queryClient.invalidateQueries(["Coaches-daily-view-stats"]);
      queryClient.invalidateQueries(["Courts-wekly-view"]);
      queryClient.invalidateQueries(["Coachess-wekly-view"]);

      // Handle event one data
    };

    listenToEvent("calendars", handleEventOne);

    // Cleanup
    return () => {
      // Unregister event listeners if necessary
    };
  }, [listenToEvent]);

  const getAllCoachData = async (locationId: number) => {
    try {
      let res: any = await getCoachlist(locationId);
      setCoachList(res.data?.data);
    } catch (error: any) {
      let err = error.response?.data?.message;
      setCoachList([]);
    }
  };

  useEffect(() => {
    SetMobileSelectedCoach(coachList[0] || {});
  }, [coachList]);

  const changeView = (data: any) => {
    if (data === "MobileWeek") {
      setCoach(coachList[0]);
    } else {
      setCoach(data);
      setSelectView("Weekly");
    }
  };

  const changeViewCourt = (data: any) => {
    setselectedCourt(data);
    setSelectView("Weekly");
  };

  const openMobileSidebar = (event: any) => {
    event.stopPropagation();
    setMobileSideBar(true);
  };

  const handleWeek = (data: any) => {
    let month_year = moment(selectedMonth).format("YYYY-MM").split("-");
    let startDates: string | null = null; // Initialize with null
    let endDates: string | null = null; // Initialize with null
    if (data.startDay > data.endDay && data.weekNo === 1) {
      startDates = `${month_year[0]}-${Number(month_year[1]) - 1}-${
        data.startDay
      }`;
      endDates = `${month_year[0]}-${month_year[1]}-${data.endDay}`;
    } else if (data.startDay > data.endDay && data.weekNo > 4) {
      startDates = `${month_year[0]}-${month_year[1]}-${data.startDay}`;
      endDates = `${month_year[0]}-${Number(month_year[1]) + 1}-${data.endDay}`;
    } else {
      startDates = `${month_year[0]}-${month_year[1]}-${data.startDay}`;
      endDates = `${month_year[0]}-${month_year[1]}-${data.endDay}`;
    }
    setDates({
      weekNo: data.weekNo,
      startDate: moment(startDates).format("YYYY-MM-DD"),
      endDate: moment(endDates).format("YYYY-MM-DD"),
    });
  };

  useEffect(() => {
    getAllWeeks();
  }, [selectedMonth]);

  useEffect(() => {
    if (isSuccess) {
      setTabLocation(data);
      if (SelectedLocation?.id === 0) {
        setSelectLocation(data[0].location_name);
        setLocationId(data[0].id);
        const matchedLocationObject = data.find(
          (location: any) => location.location_name === data[0].location_name
        );
        SetLocationID(matchedLocationObject?.id);
        return;
      }
      setLocationId(SelectedLocation.id);
      SetLocationID(SelectedLocation?.id);
    }
  }, [data, isSuccess, isError]);

  useEffect(() => {
    if (selectedSport === "All Sports") {
      const totals: monthlyRate = allClubStats?.reduce(
        (acc, curr) => {
          acc.bookingPercentage += curr.bookingPercentage;
          acc.lessonPercentage += curr.lessonPercentage;
          acc.eventPercentage += curr.eventPercentage;
          acc.openMatchCounts += curr.openMatchCounts;
          acc.totalPendingPayments += curr.totalPendingPayments;
          return {
            bookingPercentage: acc?.bookingPercentage,
            lessonPercentage: acc?.lessonPercentage,
            eventPercentage: acc?.eventPercentage,
            openMatchCounts: acc.openMatchCounts,
            totalPendingPayments: acc.totalPendingPayments,
          };
        },
        {
          bookingPercentage: 0,
          lessonPercentage: 0,
          eventPercentage: 0,
          openMatchCounts: 0,
          totalPendingPayments: 0,
        }
      ) || {
        bookingPercentage: 0,
        lessonPercentage: 0,
        eventPercentage: 0,
        openMatchCounts: 0,
        totalPendingPayments: 0,
      };

      const allsportslen: number = allClubStats?.length || 1;

      const updateddata = {
        bookingPercentage: totals?.bookingPercentage / allsportslen,
        lessonPercentage: totals?.lessonPercentage / allsportslen,
        eventPercentage: totals?.eventPercentage / allsportslen,
        openMatchCounts: totals?.openMatchCounts,
        totalPendingPayments: totals?.totalPendingPayments,
      };

      setMonthlyGraph(updateddata);
    } else {
      const updated = allClubStats?.filter(
        (item: any) => item?.sport === selectedSport
      )[0] || {
        bookingPercentage: 0,
        lessonPercentage: 0,
        eventPercentage: 0,
        openMatchCounts: 0,
        totalPendingPayments: 0,
      };
      setMonthlyGraph(updated);
    }
  }, [selectedSport, allClubStats]);

  useEffect(() => {
    if (CoachStatsSuccess) {
      setWeeklyGraph(CoachStats);
    } else {
      const data = {
        coachesDetails: [],
        noShowCounts: 0,
        pendingPayments: 0,
      };
      setWeeklyGraph(data);
    }
  }, [CoachStats, CoachStatsEror, CoachStatsSuccess, isLoading]);

  useEffect(() => {
    if (CourtsSuccess) {
      const sortedCourts = CourtsList?.sort((a: any, b: any) => {
        if (a.sport.sport_name < b.sport.sport_name) return -1;
        if (a.sport.sport_name > b.sport.sport_name) return 1;
        if (a.court_name < b.court_name) return -1;
        if (a.court_name > b.court_name) return 1;
        return 0;
      });
      const filteredArray = sortedCourts.filter((item: any) =>
        sport_id.includes(item?.sport?.id)
      );
      setCourtList(filteredArray);
    } else {
      setCourtList([]);
    }
  }, [CourtsSuccess, CourtError, CourtsList, sport_id]);

  useEffect(() => {
    getAllWeeks();
  }, [selectedMonth]);

  useEffect(() => {
    if (selectView === "Daily" && tabValue === "Coaches") {
      let date = moment(selectedDate).format("YYYY-MM-DD");
      setLocation_id(locationId);
      setSelectedDate(date);
      setSportsID(sport_id);
    }
  }, [
    selectedDate,
    selectView,
    tabValue,
    dates,
    tabLocation,
    selectLocation,
    locationId,
    sport_id,
  ]);

  useEffect(() => {
    if (selectView === "Monthly") {
      getMonthlyStatus();
    }
  }, [selectedMonth, selectView, locationId]);

  useEffect(() => {
    if (tabLocation.length > 0) {
      let id = tabLocation.filter((item: any) => {
        return item.location_name === selectLocation;
      })[0]?.id;
      setlocation_id(id);
      setLocationId(id);
      SetLocationID(id);
      getAllCoachData(id);
    }
  }, [selectLocation]);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (searchValue) {
        navigate(
          `/${CalTabValue}?inputValue=${encodeURIComponent(searchValue)}`
        );
      }
    }
  };

  useEffect(() => {
    if (sportSuccess) {
      setAllsports([{ sport_name: "All Sports", id: 0 }, ...allSports]);
      if (selectView === "Daily") {
        SetSelectedSport(allSports[0]?.sport_name);
      }
    } else {
      setAllsports([]);
    }
  }, [allSports, sportSuccess, sportError]);

  useEffect(() => {
    const notAvail = sport_id?.some((id: number) =>
      allsports.some((sport) => sport.id === id)
    );
    if (!!allsports?.length && !notAvail) {
      setSport_id(allSports?.map((sport: any) => sport.id));
    }
  }, [SelectedLocation, allsports]);

  const handleCalSelectTab = (newValue: string) => {
    let val = newValue.split(" ")[1];
    setCalTabValue(val);
  };

  const handleCalSelectMonthTab = (newValue: string) => {
    SetSelectedSport(newValue);
  };

  return (
    <Box sx={{ paddingTop: { xs: "29px", sm: "0px" } }}>
      <TopUserInfo />
      <NotificationToast />
      {/* ----- Daily View ----- */}
      {selectView === "Daily" && tabValue == "Courts" && (
        <Grid
          container
          sx={{
            width: "100%",
            height: "100%",
            paddingTop: "20px",
            paddingLeft: "20px",
            fontFamily: "var(--font-regular) !important",
          }}
          spacing={1}
        >
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid container flexDirection={"column"}>
                  <Grid item>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        flexDirection: { xs: "column", md: "row" },
                        marginLeft: { xs: "0%", md: "5.5%" },
                      }}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        sx={{
                          width: { xs: "100%", md: "auto" },
                          justifyContent: { xs: "space-between", md: "unset" },
                          marginBottom: { xs: "10px", md: "0px" },
                        }}
                      >
                        <Box
                          sx={{
                            width: "126px",
                            display: { xs: "none", sm: "block", md: "none" },
                          }}
                        ></Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: { xs: "block", md: "none" },
                              cursor: "pointer",
                            }}
                          >
                            <RxHamburgerMenu
                              onClick={openMobileSidebar}
                              className="MobileHamburger"
                            />
                          </Box>
                          <Box position={"relative"}>
                            <SingleDate
                              IcPosition={"left"}
                              format={"MMMM, yyyy"}
                              type={"single"}
                              containercss={{ marginRight: "30px" }}
                              placeholder={"Select days"}
                              selectedDay={selectedMonth}
                              setSelectedDays={(data: any) => {
                                const okay = moment(
                                  data,
                                  "DD/MM/YYYY"
                                ).toDate();
                                setSelectedMonth(okay);
                              }}
                              icon={true}
                              style={{
                                fontSize: "22px",
                                color: "#22356D",
                                background: "transparent",
                                paddingLeft: "0px",
                                minWidth: "10px",
                                maxWidth: "150px",
                              }}
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            onClick={() => setSelectedMonth(currentDate)}
                            sx={{
                              fontFamily: "var(--font-medium)",
                              cursor: "pointer",
                              borderRadius: "7px",
                              width: "max-content",
                              border: `${
                                !areDatesEqual ? "" : "2px solid #DBDEE7"
                              }`,
                              boxShadow: `${
                                !areDatesEqual
                                  ? "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"
                                  : "4px 4px 4px 0px #0000000D inset ,-4px 0px 4px 0px #0000000D inset"
                              }`,
                              height: "28px",
                              color: `${
                                !areDatesEqual ? "#22356D" : "#22356D99"
                              }`,
                              padding: "3px 20px",
                              background: `${
                                !areDatesEqual ? "white" : "#FFFFFF80"
                              }`,
                            }}
                          >
                            {t("common.Today")}
                          </Box>
                          <Box
                            sx={{
                              display: { xs: "block", md: "none" },
                              background: "white",
                              borderRadius: "7px",
                              padding: "5px",
                              width: "24px",
                              height: "24px",
                            }}
                            onClick={() => setShowModel(true)}
                          >
                            <img
                              src="/images/spreadsheet.png"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <DateCarousel />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    marginBottom={3}
                    sx={{ marginTop: { xs: "0px", sm: "0px", md: "-24px" } }}
                  >
                    <Box
                      display={"flex"}
                      paddingLeft={"5.5%"}
                      sx={{
                        position: "relative",
                        flexDirection: {
                          xs: "column",
                          md: "row",
                          lg: "column",
                          xl: "row",
                        },
                        alignItems: {
                          xs: "baseline",
                          md: "center",
                          lg: "baseline",
                          xl: "center",
                        },
                        paddingLeft: { xs: "0%", md: "5.5%" },
                        gap: { xs: "15px", md: "8px" },
                      }}
                    >
                      <VLdropdown
                        tabView={courtView}
                        tabLocation={tabLocation}
                        selectView={selectView}
                        setSelectView={setSelectView}
                        selectLocation={selectLocation}
                        setSelectLocation={setSelectLocation}
                        changeCourtList={CourtsListRefetch}
                        sportsData={allSports}
                        updateSport={handleCalSelectMonthTab}
                        selectedSport={selectedSport}
                      />
                      <Box
                        display={"flex"}
                        gap={1}
                        sx={{
                          alignItems: { xs: "unset", md: "center" },
                          justifyContent: { xs: "space-between", md: "unset" },
                          flexDirection: { xs: "row", sm: "row", md: "row" },
                          width: { xs: "auto", md: "auto" },
                        }}
                      >
                        <Box
                          sx={{
                            // position: { xs: "absolute", md: "relative" },
                            top: { xs: "-9px", md: "0px" },
                            right: { xs: "0px", md: "0px" },
                          }}
                        >
                          <CustomTabs
                            selectView={selectView}
                            setSelectView={setSelectView}
                            tabValue={tabValue}
                            setTabValue={setTabValue}
                            tabTextArray={tab}
                          />
                        </Box>
                        <Paper
                          component="form"
                          sx={{
                            position: "relative",
                            background: "transparent !important",
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            boxShadow: "0px 4px 4px 0px #0000000D inset ",
                            borderRadius: "7px !important",
                            width: { xs: "150px", md: "180px" },
                            height: "33px",
                            marginLeft: "10px",
                          }}
                        >
                          <InputBase
                            onKeyDown={handleKeyDown}
                            sx={{
                              ml: 1,
                              flex: 1,
                              color: "#22356D99",
                              fontFamily: "var(--font-regular)",
                            }}
                            className="placeholder"
                            placeholder={t("common.Search")}
                            inputProps={{ "aria-label": "search" }}
                            value={searchValue}
                            onChange={handleInputChange}
                          />
                          <IoSearch style={{ color: "#22356D99" }} />
                          {/* <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              boxShadow: "0px 4px 4px 0px #0000000D inset",
                              borderRadius: "7px",
                              margin: "2px",
                              ">div>div": {
                                background: "transparent !important",
                                ">button": { width: "95px" },
                              },
                            }}
                          > */}
                          {/* <TabSlider
                              fullWidth={true}
                              text={"search"}
                              tabOptions={["in payments", "in bookings"]}
                              callback={(tab: string) => {
                                handleCalSelectTab(tab);
                              }}
                            /> */}
                          {/* </Box> */}
                        </Paper>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                xs={0}
                md={6}
                spacing={1}
                justifyContent={"right"}
                alignItems={"center"}
                sx={{ fontSize: "0.875rem !important" }}
              >
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <CourtPlayerGraph
                    viewSelection={selectView}
                    tabValue={tabValue}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ margin: { xs: "auto", sm: "0" } }}>
            {ActiveTab === "Courts"
              ? courts && (
                  <DailyTimetable
                    setSelectView={changeViewCourt}
                    view={tabValue}
                    columnsArray={courts["Pererene"]}
                    ActiveTab={ActiveTab}
                    tabLocation={tabLocation}
                    fixLocation={selectLocation}
                    courtList={courtList}
                    selectedSport={selectedSport}
                    allSports={allSports}
                  />
                )
              : coaches && (
                  <DailyTimetable
                    setSelectView={changeViewCourt}
                    view={tabValue}
                    columnsArray={coaches["Pererene"]}
                    ActiveTab={ActiveTab}
                    tabLocation={tabLocation}
                    fixLocation={selectLocation}
                    courtList={courtList}
                    selectedSport={selectedSport}
                    allSports={allSports}
                  />
                )}
          </Grid>
        </Grid>
      )}

      {/* ------ Monthly View ------- */}
      {selectView === "Monthly" && tabValue == "Courts" && (
        <Box>
          <Grid
            container
            alignItems={"center"}
            sx={{ padding: "3% 2% 0% 4.5%" }}
          >
            <Grid item xs={12} md={4}>
              <Box
                display={"flex"}
                alignItems={"center"}
                sx={{
                  width: { xs: "100%", md: "auto" },
                  justifyContent: { xs: "space-between", md: "unset" },
                  marginBottom: { xs: "10px", md: "0px" },
                }}
              >
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Box
                    sx={{
                      display: { xs: "block", md: "none" },
                      cursor: "pointer",
                    }}
                  >
                    <RxHamburgerMenu
                      onClick={openMobileSidebar}
                      className="MobileHamburger"
                    />
                  </Box>
                  <Box position={"relative"} width={"fit-content"}>
                    <SingleDate
                      IcPosition={"left"}
                      format={"MMMM, yyyy"}
                      type={"single"}
                      containercss={{}}
                      placeholder={"Select days"}
                      selectedDay={selectedMonth}
                      setSelectedDays={(data: any) => {
                        const okay = moment(data, "DD/MM/YYYY").toDate();
                        setSelectedMonth(okay);
                      }}
                      icon={true}
                      style={{
                        fontSize: "22px",
                        color: "#22356D",
                        background: "transparent",
                        paddingLeft: "0px",
                        minWidth: "10px",
                        maxWidth: "180px",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                    background: "white",
                    borderRadius: "7px",
                    padding: "5px",
                    width: "24px",
                    height: "24px",
                  }}
                  onClick={() => setShowModel(true)}
                >
                  <img
                    src="/images/spreadsheet.png"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
              </Box>
            </Grid>
            {/* --- occupancy rate --- */}
            <Grid item xs={12} md={4}>
              <Box display={"flex"} justifyContent={"center"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "5px",
                    textAlign: "center",
                    padding: "10px 15px",
                    borderRadius: "7px",
                    boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1) !important",
                    width: { xs: "100%", md: "auto" },
                  }}
                >
                  <Typography sx={{ fontFamily: "var(--font-medium)" }}>
                    {t("Calendar.Daily Occupancy Rate")}
                  </Typography>
                  <Box
                    sx={{
                      backgroundImage:
                        "linear-gradient(to right, #FFFFFF , #38497C)",
                      height: "10px",
                      width: "209px",
                      borderRadius: "7px",
                    }}
                  ></Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{
                      fontFamily: "var(--font-medium)",
                      width: "209px",
                      fontSize: "12px",
                    }}
                    color={"#22356DB2"}
                  >
                    <p>0%</p>
                    <p>25%</p>
                    <p>50%</p>
                    <p>75%</p>
                    <p>100%</p>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* --- end --- */}
            <Grid item xs={12} md={4}>
              <Grid container justifyContent={"right"} alignItems={"center"}>
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <CourtPlayerGraph
                    viewSelection={selectView}
                    tabValue={tabValue}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Box
            position={"relative"}
            display={"flex"}
            gap={1}
            sx={{
              width: { xs: "190px", sm: "230px", md: "auto" },
              flexDirection: { xs: "column", md: "row" },
              paddingLeft: { xs: "4%", md: "4.5%" },
              marginBottom: "15px",
              marginTop: { xs: "15px", sm: "0px" },
            }}
          >
            <VLdropdown
              tabView={courtView}
              tabLocation={tabLocation}
              selectView={selectView}
              setSelectView={setSelectView}
              selectLocation={selectLocation}
              setSelectLocation={setSelectLocation}
              changeCourtList={CourtsListRefetch}
              sportsData={allSports}
              updateSport={handleCalSelectMonthTab}
              selectedSport={selectedSport}
            />
            <CustomTabs
              selectView={selectView}
              setSelectView={setSelectView}
              tabValue={tabValue}
              setTabValue={setTabValue}
              tabTextArray={tab}
            />
            {/* <Paper
                    component="form"
                    sx={{
                      position:'relative',
                      background: "transparent !important",
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      boxShadow: "0px 4px 4px 0px #0000000D inset ",
                      borderRadius: "7px !important",
                      width: { xs: "300px", md: "320px" },
                      height: "33px",
                      marginLeft: "10px",
                    }}
                  > */}
            {/* <Box sx={{position:"absolute", top:0,right:0, boxShadow:"0px 4px 4px 0px #0000000D inset",borderRadius:"7px",margin:"2px", ">div>div":{background:"transparent !important",">button":{width:"95px"}}}}> */}
            <TabSlider
              style={{
                boxShadow: "0px 4px 4px 0px #0000000D inset",
                borderRadius: "7px",
                backgroundColor: "#FFF",
              }}
              dynam={true}
              fullWidth={false}
              text={"sports"}
              tabOptions={allsports?.map((item: any) => item?.sport_name)}
              callback={(tab: string) => {
                handleCalSelectMonthTab(tab);
              }}
            />
            {/* </Box> */}
            {/* </Paper> */}
          </Box>
          {/* -------- monthly view court -------- */}
          <MonthlyCalendar
            selectedSport={selectedSport}
            selectLocation={selectLocation}
            selectedMonth={selectedMonth}
          />
        </Box>
      )}

      {/* ------- weekly view courts ------- */}
      {selectView === "Weekly" && tabValue == "Courts" && (
        <Box>
          <Grid
            xs={11.9}
            sx={{ padding: "3% 2% 1% 3%" }}
            display={"flex"}
            alignItems={"center"}
            gap={3}
          >
            <Grid xs={12} sm={5.2}>
              <Box
                display={"flex"}
                alignItems={"center"}
                marginBottom={2}
                sx={{
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: "15px", sm: "0px" },
                }}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{
                    justifyContent: { xs: "space-between", sm: "unset" },
                    width: { xs: "100%", sm: "auto" },
                  }}
                >
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <Box
                      sx={{
                        display: { xs: "block", sm: "none" },
                        cursor: "pointer",
                      }}
                    >
                      <RxHamburgerMenu
                        onClick={openMobileSidebar}
                        className="MobileHamburger"
                      />
                    </Box>
                    <Box position={"relative"} width={"fit-content"}>
                      <SingleDate
                        IcPosition={"left"}
                        format={"MMMM, yyyy"}
                        type={"single"}
                        containercss={{}}
                        placeholder={"Select days"}
                        selectedDay={selectedMonth}
                        setSelectedDays={(data: any) => {
                          const okay = moment(data, "DD/MM/YYYY").toDate();
                          setSelectedMonth(okay);
                        }}
                        icon={true}
                        style={{
                          fontSize: "22px",
                          color: "#22356D",
                          background: "transparent",
                          paddingLeft: "0px",
                          minWidth: "10px",
                          maxWidth: "160px",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <Box
                      sx={{
                        fontFamily: "var(--font-medium)",
                        borderRadius: "7px",
                        border: `${!areDatesEqual ? "" : "2px solid #DBDEE7"}`,
                        boxShadow: `${
                          !areDatesEqual
                            ? "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"
                            : "4px 4px 4px 0px #0000000D inset ,-4px 0px 4px 0px #0000000D inset"
                        }`,
                        width: "max-content",
                        height: "28px",
                        color: `${!areDatesEqual ? "#22356D" : "#22356D99"}`,
                        padding: "3px 20px",
                        background: `${!areDatesEqual ? "white" : "#FFFFFF80"}`,
                      }}
                    >
                      <p>{t("common.Today")}</p>
                    </Box>
                    <Box
                      sx={{
                        display: { xs: "block", sm: "none" },
                        background: "white",
                        borderRadius: "7px",
                        padding: "5px",
                      }}
                      onClick={() => setShowModel(true)}
                    >
                      <img
                        src="/images/spreadsheet.png"
                        style={{ width: "20px", height: "18px" }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: "max-content" }}>
                  <WeekSlider
                    handleWeek={handleWeek}
                    selectedWeek={selectedWeek}
                    setSelectedWeek={setSelectedWeek}
                  />
                </Box>
              </Box>
              <Box
                display={"flex"}
                width={"fit-content"}
                gap={1}
                sx={{
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "baseline", sm: "center" },
                }}
              >
                <VLdropdown
                  tabView={coachView}
                  tabLocation={tabLocation}
                  selectView={selectView}
                  setSelectView={setSelectView}
                  selectLocation={selectLocation}
                  setSelectLocation={setSelectLocation}
                  changeCourtList={CourtsListRefetch}
                  sportsData={allSports}
                  updateSport={handleCalSelectMonthTab}
                  selectedSport={selectedSport}
                />
                <CustomTabs
                  selectView={selectView}
                  setSelectView={setSelectView}
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                  tabTextArray={tab}
                />
              </Box>
            </Grid>
            <Grid xs={6.5}>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <CourtPlayerGraph
                  viewSelection={selectView}
                  tabValue={tabValue}
                  dates={dates}
                  weekGraph={weeksGraph}
                />
              </Box>
            </Grid>
          </Grid>
          <CourtWeekCalendar
            setWeeksGraph={setWeeksGraph}
            columnsArray={selectLocation}
            setSelectView={setSelectView}
            resource={selectedCourt}
            courtList={courtList}
            removeResource={setselectedCourt}
            selectedWeek={selectedWeek}
            dates={dates}
            view={tabValue}
          />
        </Box>
      )}

      {/* ------- Daily view coaches ------- */}
      {selectView === "Daily" && tabValue == "Coaches" && (
        <Box>
          <Grid
            xs={12}
            md={11.9}
            marginLeft={"10px"}
            container
            sx={{
              padding: "3% 2% 1% 3%",
              marginLeft: { xs: "0px", sm: "10px" },
            }}
          >
            <Grid xs={12} md={5.5}>
              <Box
                display={"flex"}
                alignItems={"center"}
                marginBottom={2}
                sx={{ flexDirection: { xs: "column", md: "row" }, gap: "5px" }}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{
                    justifyContent: { xs: "space-between", md: "unset" },
                    width: { xs: "100%", md: "auto" },
                  }}
                >
                  <Box
                    sx={{
                      width: "126px",
                      display: { xs: "none", sm: "block", md: "none" },
                    }}
                  ></Box>
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <Box
                      sx={{
                        display: { xs: "block", md: "none" },
                        cursor: "pointer",
                      }}
                    >
                      <RxHamburgerMenu
                        onClick={openMobileSidebar}
                        className="MobileHamburger"
                      />
                    </Box>
                    <Box position={"relative"}>
                      <SingleDate
                        IcPosition={"left"}
                        format={"MMMM, yyyy"}
                        type={"single"}
                        containercss={{}}
                        placeholder={"Select days"}
                        selectedDay={selectedMonth}
                        setSelectedDays={(data: any) => {
                          const okay = moment(data, "DD/MM/YYYY").toDate();
                          setSelectedMonth(okay);
                        }}
                        icon={true}
                        style={{
                          fontSize: "22px",
                          color: "#22356D",
                          background: "transparent",
                          paddingLeft: "0px",
                          minWidth: "10px",
                          maxWidth: "150px",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <Box
                      sx={{
                        fontFamily: "var(--font-medium)",
                        cursor: "pointer",
                        borderRadius: "7px",
                        border: `${!areDatesEqual ? "" : "2px solid #DBDEE7"}`,
                        boxShadow: `${
                          !areDatesEqual
                            ? "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"
                            : "4px 4px 4px 0px #0000000D inset ,-4px 0px 4px 0px #0000000D inset"
                        }`,
                        width: "max-content",
                        height: "28px",
                        color: `${!areDatesEqual ? "#22356D" : "#22356D99"}`,
                        padding: "3px 20px",
                        background: `${!areDatesEqual ? "white" : "#FFFFFF80"}`,
                        marginLeft: "20px",
                      }}
                    >
                      <p onClick={() => setSelectedMonth(currentDate)}>
                        {t("common.Today")}
                      </p>
                    </Box>
                    <Box
                      sx={{
                        display: { xs: "block", md: "none" },
                        background: "white",
                        borderRadius: "7px",
                        padding: "5px",
                        width: "24px",
                        height: "24px",
                      }}
                      onClick={() => setShowModel(true)}
                    >
                      <img
                        src="/images/spreadsheet.png"
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Box>
                  </Box>
                </Box>

                <Grid
                  xs={12}
                  md={8}
                  sx={{ width: { xs: "100%", md: "max-content" } }}
                >
                  <DateCarousel />
                </Grid>
              </Box>
              <Box
                display={"flex"}
                paddingLeft={"5.5%"}
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "baseline", md: "center" },
                  paddingLeft: { xs: "0%" },
                  gap: { xs: "15px", md: "8px" },
                }}
              >
                <VLdropdown
                  changeView={changeView}
                  tabView={isMobile ? MobileCoachView : courtView}
                  tabLocation={tabLocation}
                  selectView={selectView}
                  setSelectView={setSelectView}
                  selectLocation={selectLocation}
                  setSelectLocation={setSelectLocation}
                  changeCourtList={CourtsListRefetch}
                  sportsData={allSports}
                  updateSport={handleCalSelectMonthTab}
                  selectedSport={selectedSport}
                />
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  sx={{
                    justifyContent: { xs: "space-between", md: "unset" },
                    width: { xs: "100%", md: "auto" },
                  }}
                >
                  <CustomTabs
                    selectView={selectView}
                    setSelectView={setSelectView}
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    tabTextArray={tab}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid xs={6.5}>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <CourtPlayerGraph
                  viewSelection={selectView}
                  tabValue={tabValue}
                />
              </Box>
            </Grid>
          </Grid>
          {/* -------- daily view coach calendar ------- */}
          <CoachCalendar
            coachStatsRefecth={CoachStatsRefetch}
            columnsArray={selectLocation}
            setSelectView={changeView}
            courtList={courtList}
            coachList={coachList}
            locationId={locationId}
            view={tabValue}
          />
        </Box>
      )}
      {/* ------- weekly view coaches ------- */}
      {selectView === "Weekly" && tabValue == "Coaches" && (
        <Box>
          {isMobile ? (
            <Box>
              <Grid
                xs={11.9}
                sx={{ padding: "3% 2% 1% 3%" }}
                display={"flex"}
                alignItems={"center"}
                gap={3}
              >
                <Grid xs={12} sm={5.2}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    marginBottom={2}
                    sx={{
                      flexDirection: { xs: "column", sm: "row" },
                      gap: { xs: "15px", sm: "0px" },
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        justifyContent: { xs: "space-between", sm: "unset" },
                        width: { xs: "100%", sm: "auto" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: { xs: "block", sm: "none" },
                            cursor: "pointer",
                          }}
                        >
                          <RxHamburgerMenu
                            onClick={openMobileSidebar}
                            className="MobileHamburger"
                          />
                        </Box>
                        <Box position={"relative"} width={"fit-content"}>
                          <SingleDate
                            IcPosition={"left"}
                            format={"MMMM, yyyy"}
                            type={"single"}
                            containercss={{}}
                            placeholder={"Select days"}
                            selectedDay={selectedMonth}
                            setSelectedDays={(data: any) => {
                              const okay = moment(data, "DD/MM/YYYY").toDate();
                              setSelectedMonth(okay);
                            }}
                            icon={true}
                            style={{
                              fontSize: "22px",
                              color: "#22356D",
                              background: "transparent",
                              paddingLeft: "0px",
                              minWidth: "10px",
                              maxWidth: "160px",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            fontFamily: "var(--font-medium)",
                            borderRadius: "7px",
                            border: `${
                              !areDatesEqual ? "" : "2px solid #DBDEE7"
                            }`,
                            boxShadow: `${
                              !areDatesEqual
                                ? "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"
                                : "4px 4px 4px 0px #0000000D inset ,-4px 0px 4px 0px #0000000D inset"
                            }`,
                            width: "max-content",
                            height: "28px",
                            color: `${
                              !areDatesEqual ? "#22356D" : "#22356D99"
                            }`,
                            padding: "3px 20px",
                            background: `${
                              !areDatesEqual ? "white" : "#FFFFFF80"
                            }`,
                          }}
                        >
                          <p>{t("common.Today")}</p>
                        </Box>
                        <Box
                          sx={{
                            display: { xs: "block", sm: "none" },
                            background: "white",
                            borderRadius: "7px",
                            padding: "5px",
                            width: "24px",
                            height: "24px",
                          }}
                          onClick={() => setShowModel(true)}
                        >
                          <img
                            src="/images/spreadsheet.png"
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ width: "max-content" }}>
                      <WeekSlider
                        handleWeek={handleWeek}
                        selectedWeek={selectedWeek}
                        setSelectedWeek={setSelectedWeek}
                      />
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    width={"fit-content"}
                    gap={1}
                    sx={{
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: { xs: "baseline", sm: "center" },
                    }}
                  >
                    <VLdropdown
                      tabView={coachView}
                      tabLocation={tabLocation}
                      selectView={selectView}
                      setSelectView={setSelectView}
                      selectLocation={selectLocation}
                      setSelectLocation={setSelectLocation}
                      changeCourtList={CourtsListRefetch}
                      sportsData={allSports}
                      updateSport={handleCalSelectMonthTab}
                      selectedSport={selectedSport}
                    />
                    <CustomTabs
                      selectView={selectView}
                      setSelectView={setSelectView}
                      tabValue={tabValue}
                      setTabValue={setTabValue}
                      tabTextArray={tab}
                    />
                  </Box>
                </Grid>
                <Grid xs={6.5}>
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <CourtPlayerGraph
                      viewSelection={selectView}
                      tabValue={tabValue}
                      dates={dates}
                      weekGraph={weeksGraph}
                    />
                  </Box>
                </Grid>
              </Grid>
              <MobileWeekCalendar
                setWeeksGraph={setWeeksGraph}
                columnsArray={selectLocation}
                setSelectView={setSelectView}
                resource={MobileSelectedCoach}
                coachList={coachList}
                removeResource={SetMobileSelectedCoach}
                selectedWeek={selectedWeek}
                dates={dates}
                view={tabValue}
              />
            </Box>
          ) : (
            <Box>
              <Grid
                xs={11.9}
                sx={{ padding: "3% 2% 1% 3%" }}
                display={"flex"}
                alignItems={"center"}
                gap={3}
              >
                <Grid xs={12} sm={5.2}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    marginBottom={2}
                    sx={{
                      flexDirection: { xs: "column", sm: "row" },
                      gap: { xs: "15px", sm: "0px" },
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        justifyContent: { xs: "space-between", sm: "unset" },
                        width: { xs: "100%", sm: "auto" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: { xs: "block", sm: "none" },
                            cursor: "pointer",
                          }}
                        >
                          <RxHamburgerMenu
                            onClick={openMobileSidebar}
                            className="MobileHamburger"
                          />
                        </Box>
                        <Box position={"relative"} width={"fit-content"}>
                          <SingleDate
                            IcPosition={"left"}
                            format={"MMMM, yyyy"}
                            type={"single"}
                            containercss={{}}
                            placeholder={"Select days"}
                            selectedDay={selectedMonth}
                            setSelectedDays={(data: any) => {
                              const okay = moment(data, "DD/MM/YYYY").toDate();
                              setSelectedMonth(okay);
                            }}
                            icon={true}
                            style={{
                              fontSize: "22px",
                              color: "#22356D",
                              background: "transparent",
                              paddingLeft: "0px",
                              minWidth: "10px",
                              maxWidth: "160px",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            fontFamily: "var(--font-medium)",
                            borderRadius: "7px",
                            border: `${
                              !areDatesEqual ? "" : "2px solid #DBDEE7"
                            }`,
                            boxShadow: `${
                              !areDatesEqual
                                ? "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px"
                                : "4px 4px 4px 0px #0000000D inset ,-4px 0px 4px 0px #0000000D inset"
                            }`,
                            width: "max-content",
                            height: "28px",
                            color: `${
                              !areDatesEqual ? "#22356D" : "#22356D99"
                            }`,
                            padding: "3px 20px",
                            background: `${
                              !areDatesEqual ? "white" : "#FFFFFF80"
                            }`,
                          }}
                        >
                          <p>{t("common.Today")}</p>
                        </Box>
                        <Box
                          sx={{
                            display: { xs: "block", sm: "none" },
                            background: "white",
                            borderRadius: "7px",
                            padding: "5px",
                            width: "24px",
                            height: "24px",
                          }}
                          onClick={() => setShowModel(true)}
                        >
                          <img
                            src="/images/spreadsheet.png"
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ width: "max-content" }}>
                      <WeekSlider
                        handleWeek={handleWeek}
                        selectedWeek={selectedWeek}
                        setSelectedWeek={setSelectedWeek}
                      />
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    width={"fit-content"}
                    gap={1}
                    sx={{
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: { xs: "baseline", sm: "center" },
                    }}
                  >
                    <VLdropdown
                      tabView={coachView}
                      tabLocation={tabLocation}
                      selectView={selectView}
                      setSelectView={setSelectView}
                      selectLocation={selectLocation}
                      setSelectLocation={setSelectLocation}
                      changeCourtList={CourtsListRefetch}
                      sportsData={allSports}
                      updateSport={handleCalSelectMonthTab}
                      selectedSport={selectedSport}
                    />
                    <CustomTabs
                      selectView={selectView}
                      setSelectView={setSelectView}
                      tabValue={tabValue}
                      setTabValue={setTabValue}
                      tabTextArray={tab}
                    />
                  </Box>
                </Grid>
                <Grid xs={6.5}>
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <CourtPlayerGraph
                      viewSelection={selectView}
                      tabValue={tabValue}
                      dates={dates}
                      weekGraph={weeksGraph}
                    />
                  </Box>
                </Grid>
              </Grid>
              <WeekCalendar
                setWeeksGraph={setWeeksGraph}
                columnsArray={selectLocation}
                setSelectView={setSelectView}
                resource={selectedCoach}
                coachList={coachList}
                removeResource={setCoach}
                selectedWeek={selectedWeek}
                dates={dates}
                view={tabValue}
              />
            </Box>
          )}
        </Box>
      )}
      {/* monthly view coaches */}
      {selectView === "Monthly" && tabValue == "Coaches" && (
        <Box>
          <Grid container alignItems={"center"} sx={{ padding: "3% 2% 1% 4%" }}>
            <Grid item xs={12} md={4}>
              <Box
                display={"flex"}
                alignItems={"center"}
                sx={{
                  width: { xs: "100%", md: "auto" },
                  justifyContent: { xs: "space-between", md: "unset" },
                  marginBottom: { xs: "10px", md: "0px" },
                }}
              >
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Box
                    sx={{
                      display: { xs: "block", md: "none" },
                      cursor: "pointer",
                    }}
                  >
                    <RxHamburgerMenu
                      onClick={openMobileSidebar}
                      className="MobileHamburger"
                    />
                  </Box>
                  <Box position={"relative"} width={"fit-content"}>
                    <SingleDate
                      IcPosition={"left"}
                      format={"MMMM, yyyy"}
                      type={"single"}
                      containercss={{}}
                      placeholder={"Select days"}
                      selectedDay={selectedMonth}
                      setSelectedDays={(data: any) => {
                        const okay = moment(data, "DD/MM/YYYY").toDate();
                        setSelectedMonth(okay);
                      }}
                      icon={true}
                      style={{
                        fontSize: "22px",
                        color: "#22356D",
                        background: "transparent",
                        paddingLeft: "0px",
                        minWidth: "10px",
                        maxWidth: "180px",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                    background: "white",
                    borderRadius: "7px",
                    padding: "5px",
                    width: "24px",
                    height: "24px",
                  }}
                  onClick={() => setShowModel(true)}
                >
                  <img
                    src="/images/spreadsheet.png"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
              </Box>
            </Grid>
            {/* --- occupancy rate --- */}
            <Grid item xs={12} md={4}>
              <Box display={"flex"} justifyContent={"center"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "5px",
                    textAlign: "center",
                    padding: "10px 15px",
                    borderRadius: "7px",
                    boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1) !important",
                    width: { xs: "100%", md: "auto" },
                  }}
                >
                  <Typography sx={{ fontFamily: "var(--font-medium)" }}>
                    {t("Calendar.Daily Occupancy Rate")}
                  </Typography>
                  <Box
                    sx={{
                      backgroundImage:
                        "linear-gradient(to right, #FFFFFF , #38497C)",
                      height: "10px",
                      width: "209px",
                      borderRadius: "7px",
                    }}
                  ></Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{
                      fontFamily: "var(--font-medium)",
                      width: "209px",
                      fontSize: "12px",
                    }}
                    color={"#22356DB2"}
                  >
                    <p>0%</p>
                    <p>25%</p>
                    <p>50%</p>
                    <p>75%</p>
                    <p>100%</p>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* --- end --- */}
            <Grid item xs={12} md={4}>
              <Grid container justifyContent={"right"} alignItems={"center"}>
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <CourtPlayerGraph
                    viewSelection={selectView}
                    tabValue={tabValue}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Box
            display={"flex"}
            gap={1}
            sx={{
              width: { xs: "190px", sm: "230px", md: "auto" },
              flexDirection: { xs: "column", md: "row" },
              paddingLeft: { xs: "4%", md: "4.5%" },
              marginBottom: "15px",
              marginTop: { xs: "15px", md: "0px" },
            }}
          >
            <VLdropdown
              tabView={coachView}
              tabLocation={tabLocation}
              selectView={selectView}
              setSelectView={setSelectView}
              selectLocation={selectLocation}
              setSelectLocation={setSelectLocation}
              changeCourtList={CourtsListRefetch}
              sportsData={allSports}
              updateSport={handleCalSelectMonthTab}
              selectedSport={selectedSport}
            />
            <CustomTabs
              selectView={selectView}
              setSelectView={setSelectView}
              tabValue={tabValue}
              setTabValue={setTabValue}
              tabTextArray={tab}
            />
          </Box>
          <CoachMonthCalendar
            coachList={coachList}
            selectLocation={selectLocation}
            selectedMonth={selectedMonth}
          />
        </Box>
      )}
      {/* ------- Modal for graph only in mobile view ------ */}
      <Dialog
        open={showModel}
        onClose={() => setShowModel(false)}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          ".MuiDialog-paper": {
            maxWidth: "auto",
            paddingInline: { xs: "10px", sm: "1rem" },
            width: { xs: "auto !important" },
          },
          ".MuiDialogContent-root": {
            padding: { xs: "12px 10px", sm: "20px 24px" },
          },
        }}
      >
        <DialogContent>
          <Box position={"relative"}>
            <AiOutlineClose
              style={{
                position: "absolute",
                right: 0,
                color: "#22356DB2",
                cursor: "pointer",
              }}
              onClick={() => setShowModel(false)}
            />
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <CourtPlayerGraph
              viewSelection={selectView}
              tabValue={tabValue}
              dates={dates}
              weekGraph={weeksGraph}
            />
          </Box>
          {!StaffPermission.edit_permissions && <StaffModal />}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const selectStyle = {
  "& fieldset": { border: "none" },
  "& .MuiSelect-select": { paddingRight: "6px !important" },
  minHeight: "3px !important",
  height: "33px !important",
  padding: "7px 14px 7px 0px !important",
  borderRadius: "7px",
  border: "none !important",
  fontFamily: "var(--font-regular) !important",
  color: "#22356D !important",
};

export default CalenderPage;
