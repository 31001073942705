import { DialogProps } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import React, { useContext, useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { GEtPaymentLogs, GEtPaymentLogsByPlayer } from "src/api/Payments";
import moment from "moment";
import MainContext from "src/context/MainContext";
import { useTranslation } from "react-i18next";
import CustomSVG from "../../components/Calendar/CustomSVG";
import { addThousandSeparator } from "src/constants/SportSEparator";

const PaymentPopup = ({
  open,
  setOpen,
  bookingData,
  index,
  player,
  playerId,
  selectedLocation,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  bookingData: any;
  index: any;
  playerId?: number;
  player?: any;
  selectedLocation:any;
}) => {
  // popup state
  const {t} = useTranslation();
  const [showsureScreen, setShowSureScreen] = useState(false);
  const { event } = useContext(MainContext);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("body");
  const [paymentLogs, setPaymentLogs] = useState<any>();
  // const {data,isSuccess,isError,refetch} = useGetLogsById(bookingData?.Payment[index].id);

  //popup function
  const handleClose = () => {
    setShowSureScreen(false);
  };

  useEffect(() => {
    if (!!bookingData) {
      GetLogs(bookingData?.Payment[index].id);
    } else if (!!playerId) {
      GetLogByPlayer(playerId);
    }
  }, [bookingData, playerId, index]);

  const GetLogs = async (payment_id: number) => {
    setPaymentLogs([]);
    const response = await GEtPaymentLogs(payment_id);
    setPaymentLogs(response?.logs);
  };

  const GetLogByPlayer = async (player_id: number) => {
    try {
      const response = await GEtPaymentLogsByPlayer(player_id);
      setPaymentLogs(response?.payments);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <Dialog
      scroll={scroll}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        ".MuiDialog-paper": {
          maxWidth: "698px !important",
          width: { xs: "auto", sm: "650px" },
          paddingInline: { xs: "0", sm: "1rem" },
        },
      }}
    >
      <DialogContent>
        {!!bookingData && (
          <Box>
            <Box
              sx={{
                marginTop: { xs: "", sm: "23px" },
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <FiPlus
                onClick={() => setOpen(false)}
                style={{
                  rotate: "45deg",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Typography
              sx={{ fontFamily: "var(--font-medium)", marginBottom: "10px" }}
            >
              {t('payments.Booking details')}
            </Typography>
            <Box sx={{display:'grid',gridTemplateColumns:'repeat(2,1fr)',">p": {fontFamily: "var(--font-regular)",color: "#22356DB2"}}}>
            <Typography>ID</Typography>
            <Typography sx={{textAlign:'end'}}>{bookingData?.id}</Typography>
            </Box>
            <Box sx={{display:'grid',gridTemplateColumns:'repeat(2,1fr)',">p": {fontFamily: "var(--font-regular)",color: "#22356DB2"}}}>
            <Typography >{t('bookings.Booking Date')}</Typography>
            <Typography sx={{textAlign:'end'}}>{bookingData?.date}</Typography>
            </Box>
            <Box sx={{display:'grid',gridTemplateColumns:'repeat(2,1fr)',">p": {fontFamily: "var(--font-regular)",color: "#22356DB2"}}}>
            <Typography >{t('customers.User')}</Typography>
            <Typography sx={{textAlign:'end'}}>{bookingData?.Payment[index]?.fullName}</Typography>
            </Box>
            <Box sx={{display:'grid',gridTemplateColumns:'repeat(2,1fr)',">p": {fontFamily: "var(--font-regular)",color: "#22356DB2"},}}>
            <Typography >{t('payments.Booking Type')}</Typography>
            <Typography sx={{textAlign:'end'}}>{bookingData?.service_type}</Typography>
            </Box>
            <Box
              display={"grid"}
              gridTemplateColumns={"repeat(2, 1fr)"}
              sx={{
                gap: { xs: "3rem", sm: "16px" },
               ">p": {fontFamily: "var(--font-regular)",color: "#22356DB2"}
              }}
            >
                <Typography>{t('customers.Payment Method')}</Typography>           
                <Typography sx={{textAlign:'end'}}>{bookingData?.Payment[index]?.payment_method}</Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontFamily: "var(--font-medium)", marginBlock: "20px" }}
              >
                {t('payments.Payment details')}
              </Typography>
              <Box
                display={"grid"}
                gridTemplateColumns={"repeat(2, 1fr)"}
                gap={2}
                sx={{
                  fontFamily: "var(--font-regular)",
                  justifyContent: "space-between",
                }}
              >
                
                <Box sx={{ color: "#22356DB2", ">p": { lineHeight: "30px" } }}>
                  <p>{t('common.Price')}</p>
                  <p>{t('payments.Discounts')}</p>
                  <p>{t('payments.Refunds')}</p>
                  <p style={{ fontFamily: "var(--font-medium)" }}>
                    {t('common.Total Price')}
                  </p>
                </Box>
                <Box
                  sx={{
                    color: "#22356DB2",
                    ">p": { lineHeight: "30px" },
                    textAlign: "right",
                  }}
                >
                  <p>
                    {selectedLocation?.currency} {addThousandSeparator(bookingData?.Payment[index]?.price,selectedLocation?.currency)}
                  </p>
                  <p>
                    {bookingData?.Payment[index]?.coupons &&
                      `${bookingData?.Payment[index]?.coupons?.name} | `}{" "} {selectedLocation?.currency}  {addThousandSeparator(bookingData?.Payment[index]?.discount,selectedLocation?.currency)}
                  </p>
                  <p>{selectedLocation?.currency} {addThousandSeparator(bookingData?.Payment[index]?.refund_price,selectedLocation?.currency)}</p>
                  <p style={{ fontFamily: "var(--font-medium)" }}>{selectedLocation?.currency}{" "} 
                    {addThousandSeparator(bookingData?.Payment[index]?.price -
                      (bookingData?.Payment[index]?.discount+bookingData?.Payment[index]?.refund_price),selectedLocation?.currency)}
                  </p>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                margintop: "5px",
              }}
            >
              {bookingData?.Payment[index]?.status === "Pending payment" ? (
                <button
                  style={{
                    background: "#BF606033",
                    borderRadius: "7px",
                    padding: "6px 19px",
                    color: "rgba(179, 89, 68, 0.9)",
                  }}
                >
                  {t('payments.Pending')}
                </button>
              ) : bookingData?.Payment[index]?.status === "Paid" ? (
                <button
                  style={{
                    background: "#60BF7033",
                    borderRadius: "7px",
                    padding: "6px 19px",
                    color: "#59B368",
                  }}
                >
                  {t('common.Paid')}
                </button>
              ) : bookingData?.Payment[index]?.status.includes("Refund") ? (
                <button
                  style={{
                    background: "#60BF7033",
                    borderRadius: "7px",
                    padding: "6px 19px",
                    color: "#59B368",
                  }}
                >
                  {t('common.Refund')}
                </button>
              ) : (
                bookingData?.Payment[index]?.status === "Cancelled" && (
                  <button
                    style={{
                      background: "#BF606033",
                      borderRadius: "7px",
                      padding: "6px 19px",
                      color: "rgba(179, 89, 68, 0.9)",
                    }}
                  >
                    {t('Calendar.Cancelled')}
                  </button>
                )
              )}
            </Box>
            <Box>
              <Typography
                sx={{ fontFamily: "var(--font-medium)", marginBlock: "20px" }}
              >
               {t('payments.Payment Logs')}
              </Typography>
              <Stack direction={"row"} spacing={1}>
                <Box
                  display={"flex"}
                  paddingInlineEnd={1}
                  paddingBlock={1}
                  alignItems={"center"}
                  justifyItems={"center"}
                  paddingTop={3}
                >
                 <CustomSVG />
                </Box>
                <Box>
                  {paymentLogs?.map((item: any) => {
                    const role = item?.user?.role?.name
                      .replace("_", " ")
                      .toLowerCase();

                    return (
                      <Box className="logs">
                        <p>
                          {moment(item?.createdAt).format("HH:mm DD/MM/YYYY")}
                        </p>
                        <p>{item?.log}</p>
                        <p style={{ textTransform: "capitalize" }}>
                          By {role}{" "}
                          <span style={{ textTransform: "none" }}>
                            {item?.user?.full_name}
                          </span>
                        </p>
                      </Box>
                    );
                  })}
                  {/* <Box className="logs">
                <p>15:18 10/10/2023</p>
                <p>Booking paid</p>
                <p>By customer user@email.com </p>
              </Box>
              <Box className="logs">
                <p>15:18 10/10/2023</p>
                <p>Booking Canceled</p>
                <p>By staff Made | player called to cancel </p>
              </Box> */}
                </Box>
              </Stack>
            </Box>
            {/* <Box marginTop={"20px"}>
          <Button
            onClick={() => setShowSureScreen(true)}
            style={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              width: "150px",
              height: "37px",
              color: "#22356D",
              backgroundColor: "white",
              fontSize: "16px",
              padding: "10px 30px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
            }}
          >
            Delete
          </Button>
        </Box> */}
          </Box>
        )}
        {!!player && (
          <Box>
            <Box
              sx={{
                marginTop: { xs: "", sm: "23px" },
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <FiPlus
                onClick={() => setOpen(false)}
                style={{
                  rotate: "45deg",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Typography
              sx={{ fontFamily: "var(--font-medium)", marginBottom: "10px" }}
            >
              {t('payments.Booking details')}
            </Typography>
            <Box sx={{display:'grid',gridTemplateColumns:'repeat(2,1fr)',">p": {fontFamily: "var(--font-regular)",color: "#22356DB2"}}}>
            <Typography>ID</Typography>
            <Typography sx={{textAlign:'end'}}>{event?.id}</Typography>
            </Box>
            <Box sx={{display:'grid',gridTemplateColumns:'repeat(2,1fr)',">p": {fontFamily: "var(--font-regular)",color: "#22356DB2"}}}>
            <Typography >{t('bookings.Booking Date')}</Typography>
            <Typography sx={{textAlign:'end'}}>{moment(event?.date).format("DD/MM/YYYY")}</Typography>
            </Box>
            <Box sx={{display:'grid',gridTemplateColumns:'repeat(2,1fr)',">p": {fontFamily: "var(--font-regular)",color: "#22356DB2"}}}>
            <Typography >{t('customers.User')}</Typography>
            <Typography sx={{textAlign:'end'}}>{player?.customer?.first_name} {player?.customer?.last_name}</Typography>
            </Box>
            <Box sx={{display:'grid',gridTemplateColumns:'repeat(2,1fr)',">p": {fontFamily: "var(--font-regular)",color: "#22356DB2"},}}>
            <Typography >{t('payments.Booking Type')}</Typography>
            <Typography sx={{textAlign:'end'}}>{event?.service?.service_type}</Typography>
            </Box>
            <Box sx={{display:'grid',gridTemplateColumns:'repeat(2,1fr)',">p": {fontFamily: "var(--font-regular)",color: "#22356DB2"},}}>
            <Typography >{t('customers.Payment Method')}</Typography>
            <Typography sx={{textAlign:'end'}}>{player?.payment_method}</Typography>
            </Box>
          
            <Box>
              <Typography
                sx={{ fontFamily: "var(--font-medium)", marginBlock: "20px" }}
              >
                Payment details
              </Typography>
              <Box
                display={"grid"}
                gridTemplateColumns={"repeat(2, 1fr)"}
                gap={2}
                sx={{
                  fontFamily: "var(--font-regular)",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ color: "#22356DB2", ">p": { lineHeight: "30px" } }}>
                  <p>{t('common.Price')}</p>
                  <p>{t('payments.Discounts')}</p>
                  <p>{t('payments.Refunds')}</p>
                  <p style={{ fontFamily: "var(--font-medium)" }}>
                  {t('common.Total Price')}
                  </p>
                </Box>
                <Box
                  sx={{
                    color: "#22356DB2",
                    ">p": { lineHeight: "30px" },
                    textAlign: "right",
                  }}
                >
                  <p>
                  {selectedLocation?.currency} {addThousandSeparator(player?.paid_price,selectedLocation?.currency)}
                  </p>
                  <p>{selectedLocation?.currency} 0</p>
                  <p>{selectedLocation?.currency} {addThousandSeparator(player?.refund_price,selectedLocation?.currency)}</p>
                  <p style={{ fontFamily: "var(--font-medium)" }}>
                  {selectedLocation?.currency} {addThousandSeparator(player?.paid_price - (player?.discount || 0 + player?.refund_price),selectedLocation?.currency)}
                  </p>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                margintop: "5px",
              }}
            >
              {player?.status === "Paid" && (
                <button
                  style={{
                    background: "#60BF7033",
                    borderRadius: "7px",
                    padding: "6px 19px",
                    color: "#59B368",
                  }}
                >
                  {player?.status === "Paid" && !player?.refunded_status
                    ? " Paid"
                    : player?.refunded_status && "Refund"}
                </button>
              )}
            </Box>
            <Box>
              <Typography
                sx={{ fontFamily: "var(--font-medium)", marginBlock: "20px" }}
              >
                {t('payments.Payment Logs')}
              </Typography>
              <Stack direction={"row"} spacing={1}>
                <Box
                  display={"flex"}
                  paddingInlineEnd={1}
                  paddingBlock={1}
                  alignItems={"center"}
                  justifyItems={"center"}
                  paddingTop={3}
                >
                 <CustomSVG/>
                </Box>
                <Box>
                  {!!bookingData &&
                    paymentLogs?.map((item: any) => {
                      const role = item?.user?.role?.name
                        .replace("_", " ")
                        .toLowerCase();

                      return (
                        <Box className="logs">
                          <p>
                            {moment(item?.createdAt).format("HH:mm DD/MM/YYYY")}
                          </p>
                          <p>{item?.log}</p>
                          <p style={{ textTransform: "capitalize" }}>
                            By {role}{" "}
                            <span style={{ textTransform: "none" }}>
                              {item?.user?.full_name}
                            </span>
                          </p>
                        </Box>
                      );
                    })}

                  {paymentLogs?.map((item: any) => {
                    return item?.paymentLogs?.map((payl: any) => {
                      const role = payl?.user?.role?.name
                        .replace("_", " ")
                        .toLowerCase();

                      return (
                        <Box className="logs">
                          <p>
                            {moment(payl?.createdAt).format("HH:mm DD/MM/YYYY")}
                          </p>
                          <p>{payl?.log}</p>
                          <p style={{ textTransform: "capitalize" }}>
                            By {role}{" "}
                            <span style={{ textTransform: "none" }}>
                              {payl?.user?.full_name}
                            </span>
                          </p>
                        </Box>
                      );
                    });
                  })}
                </Box>
              </Stack>
            </Box>
            {/* <Box marginTop={"20px"}>
          <Button
            onClick={() => setShowSureScreen(true)}
            style={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              width: "150px",
              height: "37px",
              color: "#22356D",
              backgroundColor: "white",
              fontSize: "16px",
              padding: "10px 30px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
            }}
          >
            Delete
          </Button>
        </Box> */}
          </Box>
        )}

        <Dialog onClose={handleClose} open={showsureScreen}>
          <DialogContent>
            <Box>
              <Typography
                sx={{ fontFamily: "var(--font-medium)", marginBlock: "20px" }}
              >
                Are you sure you want to delete this booking
              </Typography>
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Button
                  onClick={() => setShowSureScreen(false)}
                  style={{
                    textTransform: "unset",
                    fontFamily: "var(--font-semibold)",
                    width: "104px",
                    height: "37px",
                    color: "#22356D",
                    backgroundColor: "white",
                    fontSize: "16px",
                    padding: "10px 30px",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
                  }}
                >
                  {t('common.Cancel')}
                </Button>
                <Button
                  onClick={() => setShowSureScreen(false)}
                  style={{
                    textTransform: "unset",
                    fontFamily: "var(--font-semibold)",
                    color: "white",
                    backgroundColor: "#22356D",
                    width: "92px",
                    height: "37px",
                    fontSize: "16px",
                  }}
                >
                  {t('common.Delete')}
                </Button>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentPopup;
