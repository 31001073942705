import {
  getClubId,
  deleteService,
  getServiceById,
  useGetBookingsEvents,
  useGetPlayersNotes,
  HideService,
  Permission,
  useGetPermissions,
} from "src/api/CalendarBooking";
import {
  Grid,
  Typography,
  Box,
  Stack,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { grid1, singleDates, inputStyle, grid2 } from "../../constants/color";
import React, { useCallback, useContext, useEffect, useState } from "react";
import RecurringBooking from "../../components/Calendar/RecurringBooking";
import CoachSelector from "../../components/Calendar/CoachSelector";
import EventSelector from "../../components/Calendar/EventSelector";
import LogsComponent from "../../components/Calendar/LogsComponent";
import InternalNote from "../../components/Calendar/InternalNote";
import CustomSelectMultiple from "../../components/CustomSelect";
import { SingleDate } from "../../components/customTimeSeletor";
import BlockedLogs from "../../components/Calendar/BlockedLogs";
import CustomEvSelect from "../../components/CustomEvSelect";
import { FloatLevelRange, LevelRange, timesArrayFull } from "../../constants/variables";
import { useGetAllCustomSettings } from "src/api/Customer";
import EventTab from "../../components/Calendar/EventTab";
import CustomerInfo from "../../components/CustomerInfo";
import { customSettingsObj } from "src/pages/Customers";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomModel from "../../components/CustomModel";
import { useGetLocation } from "src/api/CourtSettings";
import TabSlider from "../../components/TabSlider";
import MainContext from "src/context/MainContext";
import { activeColor } from "src/constants/color";
import BookedRecurring from "./BookedRecurring";
import { useTheme } from "@mui/material/styles";
import { BsInfoCircleFill } from "react-icons/bs";
import { apiCaller } from "src/api/ApiCaller";
import "./BookedRecurring.css";
import moment from "moment";
import { useTranslation } from "react-i18next";
import StaffModal from "src/Notification/StaffModal";
import { useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";

interface Location {
  id: number;
  user_id: number;
  club_id: number;
  location_name: string;
  region: string;
  currency: string;
  georeferenced: string;
  createdAt: string;
  updatedAt: string;
}

interface coach {
  id: number;
  full_name: string;
}

let userData: any = [
  {
    id: 1212,
    name: "Joaquin Reyes",
    image: "",
    paid: true,
    noShow: false,
    cancel: false,
  },
];

const style = {
  fontFamily: "var(--font-semibold)",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 20px",
  borderRadius: "7px",
};


interface bookingcontrol{
  min_level:string,
  max_level:string,
  approve_before_join:boolean,
  friendly_match:boolean
}

const BookingModel = ({
  events,
  update,
  fromWh,
  serviceList,
  columnsArray,
  openBookingModel,
  setOpen,
  clickStart,
  clickEnd,
  clickCourt,
  courtList,
  coach,
  clickCoach,
  view,
}: {
  events?: any;
  update: any;
  serviceList: any;
  columnsArray: any;
  openBookingModel: any;
  setOpen: any;
  clickStart: string;
  clickEnd: string;
  clickCourt: any;
  courtList: any;
  coach?: boolean;
  clickCoach?: coach[];
  view: string;
  fromWh: string;
}) => {
  const TabCount=['players'];
  const {t,i18n} = useTranslation();
  const [locationId, setLocationId] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const bookingTypes = coach
    ? ["Lesson", "Event", "Block"]
    : ["Booking", "Lesson", "Event", "Block"];
  const payments = ["Payments", "Logs"];
  const context = useContext(MainContext);
  const selectedDate = context?.selectedDate;
  const setSelectedDate = context?.setSelectedDate;
  const event = context?.event;
  const setEvent = context?.setEvent;
  const selectedPlayers = context?.selectedPlayers;
  const setSelectdPlayers = context?.setSelectdPlayers;
  const selectedCustomer = context.selectedCustomerid;
  const [selectedServiceId, setSelectedServiceId] = useState<
    number | undefined
  >();
  const {register,setValue,watch} = useForm<bookingcontrol>({defaultValues:{
    min_level:'1',max_level:'3'
    ,approve_before_join:false,
    friendly_match:false
  }});
  const { approve_before_join,friendly_match,max_level,min_level}  = watch();
  const [sendNotification,SetSendNotification] =useState<boolean>(false);
  const { Notification, setNotification,setCancelledPlayer ,setIsPermissionOn} = useContext(MainContext);
  const { data, isSuccess, isError, refetch } = useGetLocation();
  const [save, setSave] = useState(false);
   const StaffPermission = useGetPermissions();
  const [reason, setReason] = useState("");
  const [notes, setNotes] = useState<any>("");
  const [customEvent, setCustomEvent] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [eventList, setEventList] = useState<any>([]);
  const [payPlayers, setPayPlayers] = useState(false);
  const [selectEvent, setSelectEvent] = useState<any>({});
  const [customerList, setCustomerList] = useState(false);
  const [conflictData, setConflictData] = useState<any>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedCoach, setSelectedCoach] = useState<any>([]);
  const [selectedPayments, setPayment] = useState(payments[0]);
  const [isconflict, setIsconflict] = useState<boolean>(false);
  const [recurringData, setRecurringData] = useState<any>({});
  const [Recurrdata, setRecurrdata] = useState<any>({});
  const [selectedLocation, setSelectedLocation] = useState("");
  const [deleteType, setDeleteType] = useState<boolean>(false);
  const [additionalService, setAdditionalService] = useState("");
  const [additionalPrice, setAdditionalPrice] = useState('0');
  const [showRecurring, setShowRecurring] = useState<boolean>(false);
  const [selectedCourts, setSelectedCourts] = useState([...clickCourt]);
  const [selectedSport, setSelectedSport] = useState<number>();
  const [createService, setCreate] = useState<any>({});
  const [selectedBookingType, setBookingType] = useState(bookingTypes[0]);
  const [selectedTimeIndexEnd, setSelectedTimeIndexEnd] = useState(clickEnd);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);
  const [selectedTimeIndexStart, setSelectedTimeIndexStart] =
    useState(clickStart);
  const [AllCustomSettings, setAllCustomSettings] = useState<any[]>([]);
  const {
    data: customSettings,
    isSuccess: customSuccess,
    isError: customError,
    refetch: customReftech,
  } = useGetAllCustomSettings();
  const {
    data: eventsData,
    isSuccess: EventsSuccess,
    isError: EventsError,
    refetch: EventsRefetch,
    setsport_id,
    setviewType,
  } = useGetBookingsEvents();
  const openRegularBookingModel =
    selectedBookingType === "Booking"
      ? ["Regular", "Open Match"]
      : ["Single", "Double"];
  const [selectedOpenRegular, setOpenRegularBookingModel] = useState(
    openRegularBookingModel[0]
  );
  const selectedLocationId = context.selectedLocationId;
  const [ISsave, setISSave] = useState<boolean>(false);

  const handleCheckboxChange = (event: any, userId: number) => {
    event.stopPropagation();
    setSelectedCheckboxes((prevSelected: any) => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter((id: any) => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
  };


  useEffect(() => {
    if (isSuccess) {
      setLocations(data);
    } else {
      setLocations([]);
    }
  }, [data, isSuccess, isError]);

  useEffect(() => {
    const type = selectedBookingType.includes("Booking")
      ? "bookings"
      : selectedBookingType.includes("Event")
      ? "events"  
      : selectedBookingType.includes("Lesson")
      ? "lessons"
      : "";
    if (selectedLocationId) {
      setsport_id(selectedLocationId);
      setviewType(type);
    }
  }, [selectedLocationId, selectedBookingType, view, fromWh]);

  useEffect(() => {
    if (EventsSuccess) {
      setEventList(eventsData);
    } else {
      setEventList([]);
    }
  }, [eventsData, EventsSuccess, EventsError]);

 
  useEffect(() => {
    if (customSuccess) {
      const filtered = customSettings?.filter(
        (item: customSettingsObj) => item.columnName !== "userId"
      );
      setAllCustomSettings(filtered);
    } else {
      setAllCustomSettings([]);
    }
  }, [customSettings, customSuccess, customError]);

  // select all user by click on select all
  const handleMasterCheckboxChange = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
    setSelectedCheckboxes(
      selectAll ? [] : userData.map((user: any) => user.id)
    );
  };

  // set booking type
  const handleBookingTypeChange = (item: any) => {
    setSelectEvent(item);
    calculateEndTime({ time: "", duration: item.duration });
  };

  const calculateEndTime = (data: { time?: string; duration?: number }) => {
    let endTime: any;
    if (data.time !== "") {
      endTime = moment(data.time, "HH:mm").add(selectEvent.duration, "minutes");
    } else {
      endTime = moment(selectedTimeIndexStart, "HH:mm").add(
        data.duration,
        "minutes"
      );
    }
    const ENDTIME = endTime.format("HH:mm") ==="00:00"?"24:00":endTime.format("HH:mm");
    setSelectedTimeIndexEnd(ENDTIME);
    return endTime.format("HH:mm");
  };

  // get recurring booking data
  const handleRecurring = useCallback((data: any) => {
    setRecurringData(data);
  }, []);

  const filtered = selectedCourts?.map((element: any) => {
    return courtList?.filter((item: any) => item.id === element)[0];
  });

  useEffect(() => {
    if (!!filtered?.length) {
      const sportId = filtered[0]?.sport?.id;
      setSelectedSport(sportId);
    }
  }, [filtered]);

  
  // submit data while creating service
  const handleSubmit = () => {
    let data;
    let open_match_options;
    let total_data = {
      service_type: selectedBookingType,
      location_id: locations.filter((item: any) => {
        return item.location_name === columnsArray;
      })[0]?.id,
      recurring: showRecurring,
      date: moment(selectedDate).format("YYYY-MM-DD"),
      start_time:
        selectedTimeIndexStart == "8:00" || selectedTimeIndexStart == "8:30"
          ? `0${selectedTimeIndexStart}:00`
          : `${selectedTimeIndexStart}:00`,
      end_time:
        selectedTimeIndexEnd == "8:00" || selectedTimeIndexEnd == "8:30"
          ? `0${selectedTimeIndexEnd}:00`
          : `${selectedTimeIndexEnd}:00`,
      courts: selectedCourts,
      internal_note: notes,
      players: selectedPlayers,
      recurring_approved: false,
    };
    if (selectedBookingType === "Booking") {
       
      if(selectedOpenRegular==="Open Match"){
        open_match_options = {
            ...watch()
        }
      }

      if (customEvent && additionalService && additionalPrice) {
        data = {
          ...total_data,
          open_match_options,
          booking_details: {
            booking_type: selectedOpenRegular,
            additional_service: additionalService,
            additional_price: Number(additionalPrice),
          },
        };
      } else {
        if (selectEvent) {
          data = {
            
            ...total_data,
            open_match_options,
            booking_details: {
              booking_id: selectEvent.id,
              booking_type: selectedOpenRegular,
            },
          };
        } else {
          setNotification({
            isShow: true,
            message: "Please select a service",
            subMessage: "",
            type: "error",
          });
          return;
        }
      }
    } else if (selectedBookingType === "Lesson") {
      if (customEvent && additionalService && additionalPrice) {
        data = {
          ...total_data,
          lesson_details: {
            additional_service: additionalService,
            additional_price: Number(additionalPrice),
            coaches: selectedCoach,
          },
        };
      } else {
        if (selectEvent) {
          data = {
            ...total_data,
            lesson_details: {
              lesson_id: selectEvent?.id,
              coaches: selectedCoach,
            },
          };
        } else {
          setNotification({
            isShow: true,
            message: "Please select a service",
            subMessage: "",
            type: "error",
          });
          return;
        }
      }
    } else if (selectedBookingType === "Event") {
      if (customEvent && additionalService && additionalPrice) {
        data = {
          ...total_data,
          event_details: {
            event_type: selectedOpenRegular,
            additional_service: additionalService,
            additional_price: Number(additionalPrice),
            coaches: selectedCoach,
          },
        };
      } else {
        if (selectEvent) {
          data = {
            ...total_data,
            event_details: {
              event_id: selectEvent?.id,
              event_type: selectedOpenRegular,
              coaches: selectedCoach,
            },
          };
        } else {
          setNotification({
            isShow: true,
            message: "Please select a service",
            subMessage: "",
            type: "error",
          });
          return;
        }
      }
    } else {
      data = {
        ...total_data,
        block_reason: reason,
      };
    }
    if (Object.keys(Recurrdata).length > 0) {
      let recurredDate;
      if (!Recurrdata.finishing_date) {
        setNotification({
          isShow: true,
          message: "Please provide finishing date",
          subMessage: "",
          type: "error",
        });
        return;
      }
      if (Recurrdata.recurring === "Daily") {
        delete Recurrdata.onDaysOfWeek;
        delete Recurrdata.onDayOfMonth;
        recurredDate = {
          ...data,
          recurring_booking: {
            ...Recurrdata,
          },
        };
      } else if (Recurrdata.recurring === "Weekly") {
        delete Recurrdata.onDayOfMonth;
        recurredDate = {
          ...data,
          recurring_booking: {
            ...Recurrdata,
            onDaysOfWeek: Object.keys(Recurrdata.onDaysOfWeek).filter(
              (day) => Recurrdata.onDaysOfWeek[day]
            ),
          },
        };
      } else {
        recurredDate = {
          ...data,
          recurring_booking: {
            ...Recurrdata,
          },
        };
      }
      // console.log(recurredDate);
      createServices(recurredDate);
    } else {
      createServices(data);
    }
  };

  // making data formate to update
  const handleUpdateData = () => {
    
    let data;
    let open_match_options;
    let total_data = {
      service_type: selectedBookingType,
      location_id: locations.filter((item: any) => {
        return item.location_name === columnsArray;
      })[0]?.id,
      recurring: showRecurring,
      date: moment(selectedDate).format("YYYY-MM-DD"),
      start_time:
        selectedTimeIndexStart == "8:00" || selectedTimeIndexStart == "8:30"
          ? `0${selectedTimeIndexStart}:00`
          : `${selectedTimeIndexStart}:00`,
      end_time:
        selectedTimeIndexEnd == "8:00" || selectedTimeIndexEnd == "8:30"
          ? `0${selectedTimeIndexEnd}:00`
          : `${selectedTimeIndexEnd}:00`,
      courts: selectedCourts,
      internal_note: notes,
    };
    if (selectedBookingType === "Booking") {
      if(selectedOpenRegular==="Open Match"){
        open_match_options = {
            ...watch()
        }
      }
      if (additionalService && additionalPrice) {
        data = {
          ...total_data,
          open_match_options,
          booking_details: {
            booking_type: selectedOpenRegular,
            additional_service: additionalService,
            additional_price: Number(additionalPrice),
          },
        };
      } else {
        data = {
          ...total_data,
          open_match_options,
          booking_details: {
            booking_id: selectEvent.id,
            booking_type: selectedOpenRegular,
          },
        };
      }
    } else if (selectedBookingType === "Lesson") {
      if (additionalService && additionalPrice) {
        data = {
          ...total_data,
          lesson_details: {
            additional_service: additionalService,
            additional_price: Number(additionalPrice),
            coaches: selectedCoach,
          },
        };
      } else {
        data = {
          ...total_data,
          lesson_details: {
            lesson_id: selectEvent?.id,
            coaches: selectedCoach,
          },
        };
      }
    } else if (selectedBookingType === "Event") {
      if (additionalService && additionalPrice) {
        data = {
          ...total_data,
          event_details: {
            event_type: selectedOpenRegular,
            additional_service: additionalService,
            additional_price: Number(additionalPrice),
            coaches: selectedCoach,
          },
        };
      } else {
        data = {
          ...total_data,
          event_details: {
            event_id: selectEvent?.id,
            event_type: selectedOpenRegular,
            coaches: selectedCoach,
          },
        };
      }
    } else {
      data = {
        ...total_data,
        block_reason: reason,
      };
    }

    if (Object.keys(Recurrdata).length > 0) {
      const { onDayOfMonth, onDaysOfWeek, ...newrecurringData } = Recurrdata;
      let recurredDate;
      if (Recurrdata.recurring === "Weekly") {
        recurredDate = {
          ...data,
          recurring_booking: {
            ...newrecurringData,
            onDaysOfWeek: Object.keys(Recurrdata.onDaysOfWeek).filter(
              (day) => Recurrdata.onDaysOfWeek[day]
            ),
          },
        };
      } else if (Recurrdata.recurring === "Monthly") {
        recurredDate = {
          ...data,
          recurring_booking: {
            ...newrecurringData,
            onDayOfMonth,
          },
        };
      } else {
        recurredDate = {
          ...data,
          recurring_booking: {
            ...newrecurringData,
          },
        };
      }
      updateService(events?.service_id, recurredDate);
    } else {
      updateService(events?.service_id, data);
    }
  };

  // create service function
  const createServices = async (data: any) => {

    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      return;
    }

    setCreate(data);
    try {
        let club_id = getClubId();
      let res = await apiCaller.post(`${club_id}/calendars/services`, data);
      let resData = res?.data?.data;
      if (Object.keys(Recurrdata).length > 0) {
        setConflictData(resData);
        setIsconflict(true);
        setSave(true);
        setConflictData(resData);
      } else {
        serviceList();
        setSelectdPlayers([]);
        setNotification({
          isShow: true,
          message: "Booking created successfully",
          subMessage: "",
          type: "success",
        });
        setOpen(false);
      }
    } catch (error: any) {
      let err = error.response?.data?.message;
      setNotification({
        isShow: true,
        message: err,
        subMessage: "",
        type: "error",
      });
      setSave(false);
      setOpen(true);
    }
  };

  // update service function
  const updateService = async (id: number, data: any) => {
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      return;
    }

    try {
        let club_id = getClubId();
      let res = await apiCaller.put(
        `${club_id}/calendars/services/${id}`,
        data
      );
      setNotification({
        isShow: true,
        message: res.data.message,
        subMessage: "",
        type: "success",
      });
      serviceList();
      setSave(true);
      setOpen(false);
      setSelectdPlayers([]);
      if (fromWh == "payments" || fromWh === "bookings") {
        update();
      }
    } catch (error: any) {
      let err = error.response?.data?.message;
      setNotification({
        isShow: true,
        message: err,
        subMessage: "",
        type: "error",
      });
      setSave(false);
      setOpen(true);
    }
  };



  const HideServiceFromCale = async()=>{
    const apidata = {hide:!event?.is_hide}
    try {
      const response =  await HideService(event?.service_id,apidata );
      setOpen(false)
      setNotification({
        isShow: true,
        message: "Service hidden from calendar",
        subMessage: "",
        type: "success",
      });
     setTimeout(()=>{
      serviceList();
     },200)
    } catch (error) {
      console.log(error,'error');
    }
  }

  const handleSave =()=>{
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      return;
    }
    
    setISSave(true);
    if( Object.keys(events).length > 0){
     if(event?.is_cancelled){
      HideServiceFromCale();
     }else{
      handleUpdateData();
     }
    }else{
      handleSubmit();
    }
   
  }

  // delete event if there
  const handleDelete = async () => {
    const apidata = {
      "isCancelled": !event?.is_cancelled,
      "is_send":sendNotification
    }
    try {
      let res: any = await deleteService(events?.service_id,apidata);
      serviceList();
      setOpen(false);
      setDeleteType(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  // get event list by location id
  useEffect(() => {
    if (locations) {
      const matchedLocationObject: any = locations?.find(
        (location: any) => location.location_name === columnsArray
      );
      if (matchedLocationObject) {
        setSelectedLocation(matchedLocationObject.location_name);
        setLocationId(matchedLocationObject?.id);
        if (Object.keys(events).length == 0) {
          // getEventDetails(matchedLocationObject?.id, selectedBookingType);
        }
      }
    }
  }, [locations, columnsArray, selectedBookingType]);

  // updating the value for showing  recurring, recurring model, delete model
  useEffect(() => {
    setSave(false);
    setShowRecurring(false);
    setRecurrdata({});
    setRecurringData({});
    setDeleteType(false);
    setCustomerList(false);
    setPayPlayers(false);
    setSelectedCoach([]);
    setAdditionalPrice('0');
    setAdditionalService("");
  }, [openBookingModel]);

  // updating the start and end time
  useEffect(() => {
    if (Object.keys(events).length === 0) {
      setSelectedTimeIndexEnd(clickEnd);
      setSelectedTimeIndexStart(clickStart);
      if (view === "Courts") {
        setSelectedCourts(clickCourt);
      }
      if (!!clickCoach?.length) {
        const coachIDS = clickCoach?.map((item: coach) => item.id);
        setSelectedCoach(coachIDS);
      }
    }
  }, [clickEnd, clickStart, clickCourt, clickCoach]);

  // get details of service by id
  const getDetailsById = async (id: number, type: string) => {
    if (id) {
      try {
        let res: any = await getServiceById(id);
        let data: any = res?.data?.data?.serviceDetails;
        const { booking_id, lesson_id, event_id } = data;
        const get_id = booking_id ?? lesson_id ?? event_id;
        setReason(data?.service?.block_reason);
        if(data?.service?.booking_type==="Open Match"){
            setValue('min_level',Number(data.openMatchOptions?.min_level)?.toFixed(1));
            setValue('max_level',Number(data.openMatchOptions?.max_level)?.toFixed(1));
            setValue('approve_before_join',data.approve_before_join);
            setValue('friendly_match',data.friendly_match);
        }
        // setRecurringData(data?.service?.recurringBooking)
        // setOpenRegularBookingModel(data?.booking_type ?? data?.lesson_type ?? data?.event_type)
        if (data?.service?.recurring) {
          setShowRecurring(true);
          if (!!data?.service?.recurringBooking?.onDaysOfWeek?.length) {
            let nedata = {};
            let onDaysOfWeek =
              data?.service?.recurringBooking?.onDaysOfWeek?.reduce(
                (acc: any, day: any) => ({ ...acc, [day]: true }),
                Object.fromEntries(
                  [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ].map((day) => [day, false])
                )
              );
            nedata = { ...data?.service?.recurringBooking, onDaysOfWeek };
            setRecurringData(nedata);
          } else {
            setRecurringData(data?.service?.recurringBooking);
          }
          // setRecurringData(data)
        }
      } catch (error: any) {
        const err = error?.response?.data?.message;
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(events).length > 0) {
      const ENDTIME = moment(events.end_time).format("HH:mm")==="23:59"?"24:00":moment(events.end_time).format("HH:mm");
      setEvent(events);
      getDetailsById(events?.service_id, events?.service?.service_type);
      setSelectedTimeIndexStart(moment(events.start_time).format("HH:mm"));
      setSelectedTimeIndexEnd(ENDTIME);
      setBookingType(events?.service?.service_type);
      setShowRecurring(events?.service?.recurring);
      setSelectedServiceId(
        events?.service?.booking?.id ||
          events?.service?.lesson?.id ||
          events?.service?.event?.id
      );
      const CourtId = events?.courts?.map((item: any) => item?.id);
      setSelectedCourts(CourtId);
      const CoachId = events?.service?.coaches?.map((item: any) => item?.id);
      setSelectedCoach(CoachId);
      setOpenRegularBookingModel(
        events?.service?.event_type || events?.service?.booking_type
      );
    } else {
      setSelectEvent({});
    }
  }, [events]);

  useEffect(() => {
    const filtered = eventList?.filter(
      (item: any) => item?.id === selectedServiceId
    )[0];
    setSelectEvent(filtered);
  }, [eventList, selectedBookingType, selectedServiceId]);

  const handlepopupoff = (data: any) => {
    setIsconflict(data);
    setSelectedCourts([]);
    setSelectedCoach([]);
    setCustomEvent(false);
    setOpen(data);
    setEvent({});
    setSelectdPlayers([]);
    setCancelledPlayer([]);
    setSelectedServiceId(undefined);
  };

  useEffect(() => {}, [
    selectedCourts,
    save,
    showRecurring,
    selectedTimeIndexStart,
    selectedTimeIndexEnd,
    eventList,
    selectEvent,
    recurringData,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setISSave(false);
    }, 3000);
  }, [ISsave]);

  const isDropdown =
    selectedTimeIndexEnd.split(":")[1] === "30" ||
    selectedTimeIndexEnd.split(":")[1] === "00";


   
    const currentLanguage = i18n.language;
  return (
    // ------ booking model ------
    <Box sx={{ position: "relative" }}>
      <CustomModel
      TabCount={TabCount}
        open={openBookingModel}
        setOpen={(data: boolean) => handlepopupoff(data)}
        customerList={customerList}
        payPlayers={payPlayers}
      >
        {/* {!deleteType &&
          !isconflict &&
          !save &&
          !customerList &&
          !payPlayers &&  <Box position={'relative'} sx={{marginBottom:{xs:"30px", sm:"0"},height:'20px'}}><AiOutlineClose style={{position:'absolute', right:0, color:"#22356DB2", cursor:"pointer",zIndex:'10',top:'6'}} onClick={()=>handlepopupoff(!openBookingModel)} /></Box>} */}
        {!deleteType &&
          !isconflict &&
          !save &&
          !customerList &&
          !payPlayers && (
            <Grid
              container
              sx={{ height: "100%", fontFamily: "var(--font-regular)" }}
            >
              {event?.is_cancelled && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgb(255,255,255,0.4)",
                    zIndex: "5",
                  }}
                ></Box>
              )}

              <Grid item xs={12} marginBottom={"15px"}>
                <TabSlider
                fullWidth={currentLanguage==="ru"?true:false}
                  isDisable={
                    Object.keys(event).length === 0 &&
                    event.constructor === Object
                      ? false
                      : true
                  }
                  text={"service"}
                  selected={
                    Object.keys(events).length > 0
                      ? events?.service?.service_type
                      : selectedBookingType
                  }
                  tabOptions={bookingTypes}
                  callback={(tab: string) => {
                    setBookingType(tab);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={currentLanguage==="ru"?7:6} sx={grid1}>
                {selectedBookingType !== "Booking" &&
                  selectedBookingType !== "Block" && (
                    <CoachSelector
                      slectedService={selectEvent}
                      setSelectedSport={setSelectedSport}
                      SelectedSport={selectedSport}
                      view={view}
                      locationId={locationId}
                      selectedCoach={selectedCoach || []}
                      setSelectedCoach={setSelectedCoach}
                    />
                  )}
                {selectedBookingType !== "Block" ? (
                  <EventSelector
                    view={view}
                    setCustomEvent={setCustomEvent}
                    customEvent={customEvent}
                    selectedCoaches={selectedCoach}
                    selectedCourts={selectedCourts}
                    selectedOpenRegular={selectedOpenRegular}
                    customService={additionalService}
                    customPrice={additionalPrice}
                    addService={setAdditionalService}
                    addPrice={setAdditionalPrice}
                    text={selectedBookingType}
                    value={eventList.length > 0 ? selectEvent : ""}
                    data={eventList}
                    handleEvent={handleBookingTypeChange}
                  />
                ) : (
                  <input
                    type="text"
                    placeholder={t("Calendar.Enter Blocked Reason")}
                    value={reason}
                    onChange={(event) => setReason(event.target.value)}
                    style={{
                      border: "1px solid #EEEFEE",
                      padding: "6px 10px",
                      fontFamily: "var(--font-regular)",
                      color: "#22356D",
                      width: isMobile ? "inherit" : "300px",
                    }}
                  />
                )}
                <SingleDate
                  isDisable={true}
                  IcPosition={"right"}
                  format={"DD/MM/yyyy"}
                  type={"single"}
                  containercss={{ width: isMobile ? "inherit" : "300px" }}
                  placeholder={"Select days"}
                  selectedDay={selectedDate}
                  setSelectedDays={(data: any) => {
                    const okay = moment(
                      data,
                      "DD/MM/YYYY"
                    ).toDate();
                    setSelectedDate(okay);
                  }}
                  icon={false}
                  style={singleDates}
                />
                <input
                  type="text"
                  value={columnsArray}
                  style={{
                    ...inputStyle,
                    width: isMobile ? "inherit" : "300px",
                    textTransform: "capitalize",
                  }}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={currentLanguage==="ru"?5:6} sx={grid2}>
                {selectedBookingType !== "Lesson" &&
                selectedBookingType !== "Block" ? (
                  <TabSlider
                    isDisable={
                      (Object.keys(events)?.length > 0 &&
                        selectedBookingType === "Event") ||
                      (selectedBookingType === "Booking" &&
                        Object.keys(events)?.length > 0)
                    }
                    fullWidth={true}
                    text={"SubService"}
                    selected={selectedOpenRegular}
                    tabOptions={openRegularBookingModel}
                    callback={(tab: string) => {
                      setOpenRegularBookingModel(tab);
                      setSelectEvent({});
                    }}
                  />
                ) : (
                  <Box height={"36px"}></Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    // columnGap: "12px",
                    alignItems: "center",
                    justifyContent: 'space-between',

                  }}
                >
                  <Box width={"75px"}>
                    <CustomEvSelect
                      selectedValueIndex={
                        Object.keys(events).length > 0
                          ? moment(events.start_time).format("HH:mm")
                          : clickStart
                      }
                      placeholder={t("Calendar.Select time start")}
                      callback={(item) => {
                        setSelectedTimeIndexStart(item);
                        calculateEndTime({ time: item, duration: 0 });
                      }}
                    >
                      {timesArrayFull.map((item: any) => {
                        return (
                          <MenuItem key={item} value={item}>
                            <ListItemText primary={item} />
                          </MenuItem>
                        );
                      })}
                    </CustomEvSelect>
                  </Box>
                  <span style={{ color: "#22356D" }}>{t('common.To')}</span>
                  <Box width={"75px"}>
                  {isDropdown ? (
                    <CustomEvSelect
                      selectedValueIndex={selectedTimeIndexEnd}
                      placeholder="Select time end"
                      callback={(item) => {
                        setSelectedTimeIndexEnd(item);
                      }}
                    >
                      {timesArrayFull.map((item: any) => {
                        return (
                          <MenuItem key={item} value={item}>
                            <ListItemText primary={item} />
                          </MenuItem>
                        );
                      })}
                    </CustomEvSelect>
                  ) : (
                    <Box
                      width={"75px"}
                      sx={{
                        height: "40px",
                        border: "1px solid #EEEFEE",
                        borderRadius: "7px",
                        color: "#22356DB2",
                        paddingRight: "4px",
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center',
                        justifyContent:'space-between',
                        ">input": {
                          fontFamily: "var(--font-regular) !important",
                        },
                      }}
                    >
                      <input
                        type="text"
                        onChange={(e: any) =>
                          customEvent
                            ? setSelectedTimeIndexEnd(e.target.value)
                            : ""
                        }
                        value={selectedTimeIndexEnd}
                        placeholder={t("Calendar.Select End Time")}
                        style={{ width: "100%" }}
                      />
                    <BsChevronDown style={{fontStyle:'normal',color:'black',fontSize:'10px'}} />
                    </Box>
                  )}
                 </Box>
                </Box>
                <Box
                  sx={{ width: { xs: "inherit", sm: "100%" } }}
                >
                  <CustomSelectMultiple
                    customEvent={customEvent}
                    selectedSport={selectedSport}
                    selectedService={selectEvent}
                    view={view}
                    locationId={locationId}
                    startTime={selectedTimeIndexStart}
                    endTime={selectedTimeIndexEnd}
                    selectType={selectedBookingType}
                    selectedValueIndex={selectedCourts}
                    finalCourt={setSelectedCourts}
                    courtList={courtList}
                  />
                </Box>
              </Grid>
          {selectedBookingType==="Booking" && selectedOpenRegular ==="Open Match" && <Box sx={{width:'100%',marginTop:'10px',display:'flex',flexDirection:'column',gap:'10px',marginBottom:'4px'}}>
                <Box sx={{display:'flex',flexDirection:{xs:'column',sm:'row'},alignItems:{xs:'unset',sm:'center'},justifyContent:'space-between'}}>
                 <Typography sx={{fontFamily:"var(--font-regular)"}}>{t('common.Level Range')}</Typography>
                 <Box
                  sx={{
                    display: "flex",
                    columnGap: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width:{md:currentLanguage==="ru"?'245px':'205px',xs:'100%'}
                  }}
                >
                  <Box width={"75px"}>
                    <CustomEvSelect
                      islevel={true}
                      selectedValueIndex={min_level}
                      placeholder={t("Select")}
                      callback={(item) => {
                        setValue('min_level',item);
                      }}
                    >
                      {FloatLevelRange.map((item: any) => {
                        return (
                          <MenuItem key={item} value={item}>
                            <ListItemText primary={item} />
                          </MenuItem>
                        );
                      })} 
                    </CustomEvSelect>
                  </Box>
                  <span style={{ color: "#22356D" }}>{t('common.To')}</span>
                  <Box sx={{width:'75px'}}>
                  <CustomEvSelect
                      islevel={true}
                      selectedValueIndex={max_level}
                      placeholder="Select"
                      callback={(item) => {
                        setValue('max_level',item);
                      }}
                    >
                      {FloatLevelRange.map((item: any) => {
                        return (
                          <MenuItem key={item} value={item}>
                            <ListItemText primary={item} />
                          </MenuItem>
                        );
                      })}
                    </CustomEvSelect>
                    </Box>
                </Box>
                 </Box>
                 
                 <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:{md:currentLanguage==="ru"?'39.4%':'54.3%'}}}>
                 <Typography sx={{fontFamily:"var(--font-regular)"}}>{t('common.Approve players before they join')}</Typography>
                 <label style={{ marginTop: "4px" }} className="switch">
                    <input
                      checked={approve_before_join}
                      onChange={()=>setValue('approve_before_join',!approve_before_join)}
                      type="checkbox"
                    />
                    <span className="slider round"></span>
                  </label>
                 </Box>
                 <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:{md:currentLanguage==="ru"?'39.4%':'54.3%'}}}>
                 <Typography sx={{fontFamily:"var(--font-regular)"}}>{t('common.Friendly Match [no score]')}</Typography>
                 <label style={{ marginTop: "4px" }} className="switch">
                    <input
                     checked={friendly_match}
                     onChange={()=>setValue('friendly_match',!friendly_match)}
                      type="checkbox"
                    />
                    <span className="slider round"></span>
                  </label>
                 </Box>
            </Box>}
              <Box sx={{ width: { xs: "inherit", sm: "auto", md: "55%" } }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    margin: "7px 0",
                  }}>
                  <Typography sx={{ fontFamily: "var(--font-regular)" }}>
                    {t('Calendar.Recurring booking')}
                  </Typography>
                  <label style={{ marginLeft: "6px" }} className="container">
                    <input
                      type="checkbox"
                      checked={showRecurring}
                      onChange={() =>
                        Object.keys(events).length > 0
                          ? ""
                          : setShowRecurring((prev) => !prev)
                      }
                    />
                    <span className="mark"></span>
                  </label>
                </Box>
                {showRecurring && (
                  <RecurringBooking
                    data={recurringData}
                    handleFormSubmit={(data: any) =>
                      Object.keys(events)?.length > 0 ? setRecurrdata(data) : setRecurrdata(data)
                    }
                  />
                )}
              </Box>
              {selectedBookingType !== "Block" && (
                <Grid item xs={12} marginBottom={"10px"}>
                  <TabSlider
                    text={"View"}
                    tabOptions={payments}
                    callback={(tab: string) => setPayment(tab)}
                  />
                </Grid>
              )}

              {selectedPayments === "Payments" &&
                !isconflict &&
                selectedBookingType !== "Block" && (
                  <EventTab
                  TabCount={TabCount}
                  friendly_match={friendly_match}
                  approve_before_join={approve_before_join}
                    fromWh={fromWh}
                    update={update}
                    columnsArray={columnsArray}
                    updateList={serviceList}
                    selectEvent={selectEvent}
                    events={events}
                    selectedOpenRegular={selectedOpenRegular}
                    userData={userData}
                    setSave={setSave}
                    setCustomerList={setCustomerList}
                    setPayPlayers={setPayPlayers}
                    selectAll={selectAll}
                    selectedBookingType={selectedBookingType}
                    handleCheckboxChange={handleCheckboxChange}
                    handleMasterCheckboxChange={handleMasterCheckboxChange}
                  />
                )}

              {selectedPayments === "Logs" &&
                !isconflict &&
                selectedBookingType !== "Block" && (
                  <LogsComponent service_id={events?.service_id} />
                )}
            </Grid>
          )}
        {/* for showing player details */}
        {customerList && (
          <CustomerInfo
            customfield={AllCustomSettings}
            hidebackdrop={true}
            customerdata={selectedCustomer}
            fromCustomer={false}
            show={customerList}
            setShow={setCustomerList}
          />
        )}

        {/* for showing recurring booking */}
        {isconflict && (
          <BookedRecurring
            setSave={setSave}
            conflictData={conflictData}
            serviceData={createService}
            setIsconflict={setIsconflict}
            closeModel={() => setOpen(false)}
            serviceList={serviceList}
          />
        )}

        {/* blocked event */}
        {selectedBookingType == "Block" && !isconflict && !deleteType && (
          <BlockedLogs events={events} />
        )}
        {!deleteType &&
          !isconflict &&
          !save &&
          !customerList &&
          !payPlayers && <InternalNote notes={notes} setNotes={setNotes} />}
        {!deleteType &&
          !isconflict &&
          !save &&
          !customerList &&
          !payPlayers && (
            <Box
              display={"flex"}
              zIndex={6}
              position={"relative"}
              justifyContent={"space-between"}
              marginTop={2}
              width={"100%"}
            >
              {/* ------ action button ------ onClick={()=>setSave(true)} */}
              <button
                style={{
                  ...style,
                  color: event?.is_cancelled ? "white" : activeColor,
                  backgroundColor: event?.is_cancelled ? "red" : "white",
                }}
                onClick={() =>
                  Object.keys(events).length > 0
                    ? setDeleteType(true)
                    : setOpen(false)
                }
              >
                {event?.is_cancelled
                  ? t("Calendar.Undo Cancellation")
                  : events?.service?.service_type === "Block"
                  ? t("Calendar.Eliminate Blocked Hours")
                  : t("Calendar.Cancel Booking")}
              </button>
              <button
                style={{
                  ...style,
                  color: "white",
                  background: activeColor,
                  pointerEvents: ISsave ? "none" : "auto",
                }}
                onClick={handleSave}
              >
                {!event?.is_cancelled ? t('common.Save'): event?.is_hide ? t('Calendar.Show in calendar') : t('Calendar.Hide from calendar')}
              </button>
            </Box>
          )}

        {deleteType && !isconflict && (
          <Box padding={2}>
            {!StaffPermission?.edit_permissions ?   <Box>
                  <Typography sx={{fontFamily:"var(--font-medium)",color:"#22356D",marginBottom:'5px'}}>{t('common.You are not allowed to')} {t('common.cancel this booking')}</Typography>
                  <Stack direction={'row'} spacing={1} marginBottom={4}>
                      <img src="/images/info.svg" alt="info" />
                      <Typography sx={{color:"#22356DB2",fontFamily:"var(--font-regular)",}}>{t('common.Only staff managers are allowed to')} {t('cancel bookings.')}</Typography>
                  </Stack>
                  <button style={{ ...style, color: activeColor }}  onClick={() => setDeleteType(false)}>{t('common.Close')}</button>
              </Box>:
          

            <Box>
              <Typography
                sx={{
                  fontFamily: "var(--font-medium)",
                  color: "#22356D",
                  marginRight: "5rem",
                }}
              >
                {t(events?.is_cancelled ?"Calendar.Are you sure you want to undo cancellation of this booking ?":'Calendar.Are you sure you want to cancel this booking ?')}
              </Typography>
              <Stack
                direction={"row"}
                spacing={1}
                marginBottom={2}
                marginRight={"5rem"}
              >
                <BsInfoCircleFill style={{ color: "#22356DB2" }} />
            <Typography
                  sx={{ color: "#22356DB2", fontFamily: "var(--font-regular)" }}
                >
                  {t('Calendar.This will send a notification to all the players of this booking.')}
                </Typography>
              </Stack>
             {!events?.is_cancelled && <Box sx={{display:'flex',flexDirection:'row',gap:'10px'}}>
              <label style={{margin:'0px',top:'-8px'}} className="container">
                <input
                  checked={sendNotification}
                  onChange={()=>SetSendNotification(!sendNotification)}
                  type="checkbox"
                />
                <span className="mark"></span>
              </label>
                <Typography sx={{  fontFamily: "var(--font-regular)",
                  color: "#22356D",}}>Send emails notification to customers.</Typography>
              </Box>}
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                marginTop={2}
              >
                <button
                  style={{ ...style, color: activeColor }}
                  onClick={() => setDeleteType(false)}
                >
                  {events?.is_cancelled?t('Calendar.No, keep cancelled'):t('customers.No_undo')}
                </button>
                <button
                  style={{ ...style, color: "white", background: activeColor }}
                  onClick={handleDelete}
                >
                  {events?.is_cancelled?t('Calendar.Yes, undo cancellation'):t('customers.Yes, Cancel')}
                </button>
              </Box>
            </Box>}
          </Box>
        )}
          {!StaffPermission.edit_permissions && <StaffModal />} 
      </CustomModel>
    </Box>
  );
};

export default BookingModel;
