import React, { useContext, useEffect, useState } from "react";
import { activeColor } from "src/constants/color";
import Box from "@mui/material/Box";
import AddPlayer from "./AddPlayer";
import "../tableStyling.css";
import MainContext from "src/context/MainContext";
import { getClubId, useGetAllCustomerList } from "src/api/CalendarBooking";
import { apiCaller } from "src/api/ApiCaller";

interface MyObject {
  id: number;
  full_name: string;
  email?: string;
}

const WaitingList = ({
  selectAll,
  handleMasterCheckboxChange,
  userData,
  playerList,
  updateList,
  waitingList,
  selectedOpenRegular,
  handleCheckboxChange,
}: {
  selectAll: any;
  handleMasterCheckboxChange: any;
  userData: any;
  updateList: any;
  playerList: any;
  waitingList: any;
  selectedOpenRegular: string;
  handleCheckboxChange: any;
}) => {
  const context = useContext(MainContext);
  const event = context?.event;
  const [data, setData] = useState(userData);
  const [addPlayer, setAddPlayer] = useState<string>("");
  const [showList, setShowList] = useState<boolean>(false);
  const [customerList, setCustList] = useState<MyObject[]>([]);
  const {
    data: CustomerData,
    isSuccess,
    isError,
    refetch,
  } = useGetAllCustomerList();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    setAddPlayer(val);
    setShowList(true);
    let arr = customerList
      ?.map((cust: any) => {
        const { first_name, last_name, ...newcust } = cust;
        const full_name = `${first_name} ${last_name}`;
        return { full_name, ...cust };
      })
      .filter((item) => {
        let name = item?.full_name?.toLowerCase();
        return name?.includes(val?.toLowerCase());
      });
    setData(arr);
  };

  const selectedUser = async (item: any) => {
    setShowList(false);
    setAddPlayer(item.full_name);
    let data = {
      customerId: item.id,
      watingPlayer: true,
    };
    try {
      let club_id = getClubId();
      let res: any = await apiCaller.post(
        `${club_id}/players/waiting-lists/${event?.service_id}/${item.id}`
      );
      setTimeout(() => {
        waitingList(event?.service_id);
      }, 1000);
      setAddPlayer("");
    } catch (error: any) {
      let err = error.response?.data?.message;
      console.log(err);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setCustList(CustomerData);
      setData(CustomerData);
    }
  }, [CustomerData, isSuccess, isError]);

  const movePlayers = async (id: number) => {
    try {
      let club_id = getClubId();
      let res: any = await apiCaller.patch(
        `${club_id}/calendars/services/players/${id}?type=waiting`
      );
      waitingList(event?.service_id);
      playerList(event?.service_id);
    } catch (error: any) {
      const err = error.response?.data?.message;
      console.log(err);
    }
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setShowList(false);
    });
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ pointerEvents: event?.is_cancelled ? "none" : "auto" }}
        position={"relative"}
        zIndex={99}
        alignItems={"center"}
      >
        <AddPlayer
          maxHeight={"200px"}
          addPlayer={addPlayer}
          handleChange={handleChange}
          showList={showList}
          setShowList={setShowList}
          data={data}
          isPlayer={true}
          selectedUser={selectedUser}
        />
      </Box>
      {/* ----- player list ----- */}
      {userData.length > 0 ? (
        <Box
          maxHeight={userData.length > 3 ? "288px" : "auto"}
          id="table-person"
        >
          <Box className="waitingList">
            {userData.map((user: any, index: number) => (
              <Box
                key={user.id}
                display={"flex"}
                gap={1}
                sx={{ "&:hover": { background: "#889AB64D !important" } }}
                alignItems={"center"}
                padding={"8px 10px"}
              >
                <Box>
                  <Box
                    style={{
                      borderRadius: "7px",
                      width: "26px",
                      height: "26px",
                      textAlign: "center",
                      padding: "0px 10px",
                      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {index + 1}
                  </Box>
                </Box>
                <Box style={{ width: "-webkit-fill-available" }}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    color={activeColor}
                    sx={{ ">img": { display: { xs: "none", sm: "block" } } }}
                  >
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      <img
                        src={
                          user?.customer?.profile_url
                            ? user?.customer?.profile_url
                            : "/images/Reserved.svg"
                        }
                        alt="user"
                        width="45px"
                        height="45px"
                        style={{ borderRadius: "50%" }}
                      />
                      <Box
                        sx={{
                          fontFamily: "var(--font-regular)",
                          ">p": { display: { xs: "none", sm: "block" } },
                        }}
                      >
                        <p>
                          {user?.customer?.first_name}{" "}
                          {user?.customer?.last_name}
                        </p>
                        <p>{user?.customer?.phone_number}</p>
                      </Box>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      <button
                        style={{
                          background: "white",
                          padding: "0px 15px",
                          border: "1px solid #EEEFEE",
                          borderRadius: "5px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                        }}
                        onClick={() => movePlayers(user.id)}
                      >
                        Move to Players
                      </button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box height={"91px"} id="table-person">
          <Box
            sx={{
              height: "77px",
              background: "#889AB61A",
              color: "#B4C1D5",
              padding: "8px 0px 8px 10px",
            }}
          >
            {" "}
            No Players
          </Box>
        </Box>
      )}
    </>
  );
};

export default WaitingList;
