import { Box, FormControl, MenuItem, Select } from '@mui/material'
import React from 'react'
import { dopdowncustomcss } from 'src/constants/dropdownCSS'
import { RxCross2 } from "react-icons/rx";
import { hours, Numberhours } from 'src/constants/variables'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from 'react-i18next';

const PercentArr = [50,75,100]
const RefundConditionRow = ({data,index,setValue,type,delete_conditions}:{data:any,index:number,setValue:any,type?:string,delete_conditions:any}) => {
  const {t} = useTranslation();
    const handleRefundPercentage=(e:any)=> {
       setValue(`${type}[${index}].refund_percentage`,e.target.value)
    }

    const handlePaymentType=(e:any)=> {
        setValue(`${type}[${index}].method_id`,e.target.value)
     }

     const handlerefundHour=(e:any)=> {
        setValue(`${type}[${index}].cancellation_period_sec`,e.target.value)
     }

     const removeField = () => {
        const currentFields = data || [];
        const updatedFields = currentFields.filter((_:any, i:number) => i !== index);
        const deletedFields = currentFields.filter((_:any, i:number) => i === index);
        if(delete_conditions?.length>0){
        setValue('delete_conditions',[...delete_conditions,deletedFields[0].id===null?undefined:deletedFields[0].id])
        }else{
          setValue('delete_conditions',[deletedFields[0].id===null?undefined:deletedFields[0].id])
        }
        setValue(`${type}`, updatedFields);
      };
    

    
    const percent = data[index].refund_percentage;
    const methodType = data[index].method_id;
    const refundHour = data[index].cancellation_period_sec;
  return (
    <Box sx={{display:'flex',flexDirection:{xs:'column',md:'row'},alignItems:{xs:'start',md:'center'},gap:'10px',fontSize:'14px',color:'#22356D',fontFamily:'var(--font-regular)'}}>
        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'10px',fontSize:'14px',color:'#22356D',fontFamily:'var(--font-regular)'}}>
              <FormControl sx={{width:{xs:"auto", md:"auto"}}}>
                <Select
                  value={percent?percent:t('common.Select')}
                  onChange={(e:any)=>handleRefundPercentage(e)}
                  IconComponent={KeyboardArrowDownIcon}
                  placeholder="Customer type"
                  id="customdropdown"
                  renderValue={(value) => {
                    if (value) {
                      return `${value} %`;
                    }
                    return <em>{t('common.Select')}</em>;
                  }}
                  sx={{
                    width: {xs:"100%", md:"120px"},
                    height: "35px",
                    border: "1px solid #EEEEFE",
                    borderRadius: "7px",
                    fontSize: "14px",
                    opacity: "1",
                    fontFamily: "var(--font-regular)",
                    color: "#22356D",
                  }}
                  MenuProps={{ sx: dopdowncustomcss }}
                >
                  {PercentArr?.map((location: any) => (
                    <MenuItem
                      key={location}
                      value={location}
                    >
                      {location} %
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {t('common.To')}
              <FormControl sx={{width:{xs:"auto", md:"auto"}}}>
                <Select
                  value={methodType?methodType:t('common.Select')}
                  onChange={(e:any)=>handlePaymentType(e)}
                  IconComponent={KeyboardArrowDownIcon}
                  placeholder="Customer type"
                  id="customdropdown"
                  renderValue={(value) => {
                    if (value) {
                      return `${value}`;
                    }
                    return <em>{t('common.Select')}</em>;
                  }}
                  sx={{
                    width: {xs:"100%", md:"120px"},
                    height: "35px",
                    border: "1px solid #EEEEFE",
                    borderRadius: "7px",
                    fontSize: "14px",
                    opacity: "1",
                    fontFamily: "var(--font-regular)",
                    color: "#22356D",
                  }}
                  MenuProps={{ sx: dopdowncustomcss }}
                >
                  {['Club Wallet']?.map((location: any) => (
                    <MenuItem
                      key={location}
                      value={location}
                    >
                      {location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Box>
              <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'10px',fontSize:'14px',color:'#22356D',fontFamily:'var(--font-regular)'}}>
              {t('common.If cancel')}
        <FormControl sx={{width:{xs:"auto", md:"auto"}}}>
        <Select
            value={refundHour?refundHour:t('common.Select')}
            onChange={(e:any)=>handlerefundHour(e)}
            IconComponent={KeyboardArrowDownIcon}
            placeholder="Customer type"
            id="customdropdown"
            renderValue={(value) => {
            if (value) {
                return `${value} hours`;
            }
            return <em>{t('common.Select')}</em>;
            }}
            sx={{
            width: {xs:"100%", md:"120px"},
            height: "35px",
            border: "1px solid #EEEEFE",
            borderRadius: "7px",
            fontSize: "14px",
            opacity: "1",
            fontFamily: "var(--font-regular)",
            color: "#22356D",
            }}
            MenuProps={{ sx: dopdowncustomcss }}
        >
            {Numberhours?.map((location: any) => (
            <MenuItem
                key={location}
                value={location}
            >
                {location} hours
            </MenuItem>
            ))}
        </Select>
        </FormControl> 
        </Box>
        {t('common.Before booking starts')}
        <RxCross2 onClick={removeField}/>
    </Box>
  )
}

export default RefundConditionRow