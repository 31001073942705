import * as React from "react";
import { styled, useTheme, Theme } from "@mui/material/styles";
import MuiDrawer, { DrawerProps } from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useLocation } from "react-router-dom";
import MainContext from "src/context/MainContext";
import { useTranslation } from "react-i18next";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { activeColor } from "src/constants/color";

const style = {
  fontFamily: "var(--font-semibold)",
  background: "white",
  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  padding: "7px 20px",
  borderRadius: "7px",
};
const drawerWidth = 240;

const iconsList: any[] = [
  {
    Calendar: "/images/calendars.png",
  },
  {
    Payments: "/images/file.png",
  },
  // {
  //   Bookings: "/images/book.png",
  // },
  {
    Customers: "/images/user.svg",
  },
  {
    Services: "/images/tennisBall.png",
  },
  {
    Courts: "/images/court 1.svg",
  },
  {
    Settings: "/images/settings.png",
  },
  {
    POS: "/images/shoppingCart.svg",
  },
  // {
  //   Stats: "/images/statistics.png",
  // },
  {
    Logout: "/images/logout.svg",
  },
];

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  background:
    "linear-gradient(0deg, rgba(136, 154, 182, 0.15), rgba(136, 154, 182, 0.15)), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
});

const closedMixin = (theme: any) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background:
    "linear-gradient(0deg, rgba(136, 154, 182, 0.15), rgba(136, 154, 182, 0.15)), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
  overflowX: "hidden",
  boxShadow: "10px 0px 50px 0px rgba(0, 0, 0, 0.1)",
  // width: `calc(${theme.spacing(7)} + 1px)`,
  border: "None",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface DrawerHeaderProps {
  open: boolean;
}

const DrawerHeader = styled("div")<DrawerHeaderProps>(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: open ? "flex-end" : "center",
  padding: theme.spacing(8, 0, 0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<DrawerProps>(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [], // Add an empty array for the variants property
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    background:
      "linear-gradient(0deg, rgba(136, 154, 182, 0.15), rgba(136, 154, 182, 0.15)), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
  }),
}));

export default function MiniDrawer() {
  const {t} = useTranslation();
  const [isShowLogout,setIsShowLogout] = React.useState<boolean>(false);
  const { sideBar, setSideBar } = React.useContext(MainContext);
  // const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const context = React.useContext(MainContext);
  const setSelectedDate = context?.setSelectedDate;
  const setSelectedMonth = context?.setSelectedMonth;
  const setAuthenticated = context?.setAuthenticated;

  const handleDrawerOpen = () => {
    setSideBar(!sideBar);
  };
  const theme = useTheme();

  const isActive = (path: string) => {
    if (path === "calender" && location.pathname === "/") {
      return true;
    }
    return location.pathname.includes(path);
  };

  const getImageUrl = (key: string): string | undefined => {
    const icon = iconsList.find((item) => Object.keys(item)[0] === key);
    return icon ? icon[key] : undefined;
  };

  const handleNavigation = (path: string) => {
   const date = new Date();
    setSelectedMonth(date);
    setSelectedDate(date);
    navigate(path);
  };

  return (
    <Drawer variant="permanent" open={sideBar}>
      <DrawerHeader open={sideBar}>
        <IconButton>
          {sideBar ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <List>
        {[
          "Calendar",
          "Payments",
          "Customers",
          "Services",
          "Courts",
          "Settings",
          "POS",
          // "Stats",
          "Logout",
        ].map((text, index) => (
          <ListItem
            key={text}
            disablePadding
            sx={{ display: "block" }}
            onMouseEnter={() => setSideBar(true)}
            onMouseLeave={() => setSideBar(false)}
          >
            <ListItemButton
              onClick={() => text!=="Logout"? handleNavigation(text.toLowerCase()):setIsShowLogout(true)}
              sx={{
                minHeight: 28,
                justifyContent: sideBar ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: sideBar ? 1 : "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: isActive(text.toLowerCase())
                    ? "#22356D"
                    : "#9ca5be",
                  borderRadius: "7px",
                  padding: "7px",
                }}
              >
                <img
                  src={getImageUrl(text)}
                  alt={text}
                  style={{ width: "22px", height: "22px" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={t(`title.${text}`)}
                sx={{
                  ".MuiListItemText-primary": {
                    color: isActive(text.toLowerCase())
                      ? "#22356D !important"
                      : "#22356D99 !important",
                    fontFamily: isActive(text.toLowerCase())
                      ? "var(--font-medium) !important"
                      : "var(--font-regular) !important",
                  },
                  opacity: sideBar ? 1 : 0,
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Dialog onClose={() => setIsShowLogout(false)} open={isShowLogout}>
            <DialogContent>
                <Box padding={2}>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-medium)",
                        color: "#22356D",
                        marginRight: "5rem",
                      }}
                    >
                     {t('common.Are you sure you want to logout?')}
                    </Typography>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      marginTop={2}
                    >
                      <button
                        style={{ ...style, color: activeColor }}
                        onClick={() => setIsShowLogout(false)}
                      >
                        {t('common.Cancel')}
                      </button>
                      <button
                        onClick={()=>handleNavigation('logout')}
                        style={{
                          ...style,
                          color: "white",
                          background: activeColor,
                        }}
                      >
                         {t('common.Yes')}
                      </button>
                    </Box>
                  </Box>
                </Box>
                        </DialogContent>
          </Dialog>
    </Drawer>
  );
}
