import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiPlus } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const CustomCourtCarousel = ({
  setSelectedCourt,
  locationid,
  setFromAddCourt,
  fromaddCourt,
  setSelectedCourtId,
  selectedCourtId,
  courts,
}: {
  setSelectedCourt: any;
  locationid: number;
  setFromAddCourt: any;
  fromaddCourt: boolean;
  setSelectedCourtId: any;
  selectedCourtId: any;
  courts: any;
}) => {
  const {t,i18n} = useTranslation();
  const [startIndex, setStartIndex] = useState(0);
  const currentLanguage = i18n.language;

  //handle click
  const handleClick = (direction: string) => {
    if (direction === "next" && startIndex + 8 < courts.length) {
      setStartIndex(startIndex + 1);
    } else if (direction === "prev" && startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  // handle court click
  const handleCourtClick = (court: any) => {
    setFromAddCourt(false);
    setSelectedCourtId(court.id);
    const data = {
      court_id: court.id,
      court_name: court.court_name,
    };
    setSelectedCourt(data);
  };

  // Common styles for buttons
  const commonButtonStyles = {
    fontSize: "0.87rem !important",
    display: "flex",
    flexDirection: "column",
    maxWidth: "60px",
    borderRadius: "7px",
    padding: "4px 10px",
    margin: "0 2px",
  };

  return (
    <Grid
      container
      sx={{ marginTop:'20px',marginLeft: { xs: "0", sm:"0", md: "6%" } }}
      alignItems={"center"}
    >
      <Grid container sx={{ width: "auto" }}>
        <Button
          sx={{ minWidth: "fit-content" }}
          onClick={() => handleClick("prev")}
          disabled={startIndex === 0}
        >
          <ChevronLeftIcon />
        </Button>
        {[...courts.slice(startIndex, startIndex + 8)].map((court) => {
          const isSelected = court.id === selectedCourtId && !fromaddCourt;
          return (
            <Button
              key={court.id}
              onClick={() => handleCourtClick(court)}
              onTouchEnd={() => handleCourtClick(court)} // Handle touch events
              sx={{
                ...commonButtonStyles,
                color: isSelected ? "#22356D" : "inherit",
                backgroundColor: isSelected ? "#fff" : "inherit",
                border: "none",
                maxWidth:court.court_name === "All Courts" && (currentLanguage==="ru" ||currentLanguage==="es")?'90px':'60px',
                "&:hover": { border: "none" },
              }}
              variant={isSelected ? "outlined" : "text"}
            >
              <Typography
                variant="h6"
                sx={{
                  textTransform: "none",
                  fontSize: "16px !important",
                  fontFamily: "var(--font-semibold) !important",
                  lineHeight: "1.2",
                  // width:'auto'
                }}
              >
                {court.court_name === "All Courts"
                  ? t("courts.All Courts")
                  : court?.court_name}
              </Typography>
            </Button>
          );
        })}
        <Button
          onClick={() => setFromAddCourt(true)}
          onTouchEnd={() => setFromAddCourt(true)} // Handle touch events
          key="addButton"
          sx={{
            ...commonButtonStyles,
            backgroundColor: fromaddCourt ? "white" : "inherit",
          }}
          variant="text"
        >
          <Typography
            variant="body2"
            sx={{
              textTransform: "none",
              fontFamily: "var(--font-regular)",
            }}
          >
            <FiPlus />
          </Typography>
        </Button>
        <Button
          sx={{ minWidth: "fit-content" }}
          onClick={() => handleClick("next")}
          disabled={startIndex + 8 >= courts.length}
        >
          <ChevronRightIcon />
        </Button>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};

export default CustomCourtCarousel;
